import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PageType } from '../../../common/enums/page.type';
import { StoreService } from '../../../core/services/store.service';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { FeatureType } from '../../../common/enums/feature.type';
import { Side } from '../../../common/classes/side';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { PersistanceService } from '../../../core/services/persistance.service';


@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit, AfterViewInit, OnDestroy {

  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.Authentication;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  showLoader = false;
  showAuthLoader = false;
  showConfirmation = false;
  showTimer = false;
  showError = false;
  enableSubmitButton = false;
  enableResendButton = false;

  remainingSeconds = 0;

  timer = null;

  @ViewChild('firstDigitInput') firstDigitInput;
  @ViewChild('secondDigitInput') secondDigitInput;
  @ViewChild('thirdDigitInput') thirdDigitInput;
  @ViewChild('fourthDigitInput') fourthDigitInput;
  @ViewChild('fifthDigitInput') fifthDigitInput;
  @ViewChild('sixthDigitInput') sixthDigitInput;

  @Input() tanRecipient = '';

  @Input() digits = {
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
    sixth: '',
  };

  constructor(
    public storeService: StoreService,
    private appNavService: AppNavService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private persistanceService: PersistanceService,
  ) {

  }

  ngOnInit(): void {

    this.appNavService.currentPageType = this.pageType;
    this.appNavService.currentPageIndex = this.storeService?.setup?.sequence.indexOf(this.pageType) || 0;
    this.setup = this.storeService.setup;

    this.receiver = { ...this.storeService.receiver };

    this.apiService.trigger2FASMS(this.storeService.token).subscribe((response: any) => {

      const phoneNumber = response.phone;

      if (phoneNumber.length > 4) {
        this.tanRecipient = Array(phoneNumber.length - 4)
          .fill('*').join('') + phoneNumber.slice(phoneNumber.length - 4);
      }

    });
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  ngAfterViewInit() {

  }

  onInput1KeyUp(event) {

    this.enableSubmitButton = this.isFormFullyPopulated();

    if (!event.target.value && event.key === 'Backspace') {
      return;
    }

    const numericInput = Number(event.key);

    if (!isNaN(numericInput)) {
      this.digits.first = String(numericInput);
      this.secondDigitInput.nativeElement.focus();
    } else {
      this.digits.first = '';
    }
  }

  onInput2KeyUp(event) {

    this.enableSubmitButton = this.isFormFullyPopulated();

    if (!event.target.value && event.key === 'Backspace') {
      this.firstDigitInput.nativeElement.focus();

      return;
    }

    const numericInput = Number(event.key);

    if (!isNaN(numericInput)) {
      this.digits.second = String(numericInput);
      this.thirdDigitInput.nativeElement.focus();
    } else {
      this.digits.second = '';
    }
  }

  onInput3KeyUp(event) {

    this.enableSubmitButton = this.isFormFullyPopulated();

    if (!event.target.value && event.key === 'Backspace') {
      this.secondDigitInput.nativeElement.focus();

      return;
    }

    const numericInput = Number(event.key);

    if (!isNaN(numericInput)) {
      this.digits.third = String(numericInput);
      this.fourthDigitInput.nativeElement.focus();
    } else {
      this.digits.third = '';
    }
  }

  onInput4KeyUp(event) {

    this.enableSubmitButton = this.isFormFullyPopulated();

    if (!event.target.value && event.key === 'Backspace') {
      this.thirdDigitInput.nativeElement.focus();

      return;
    }

    const numericInput = Number(event.key);

    if (!isNaN(numericInput)) {
      this.digits.fourth = String(numericInput);
      this.fifthDigitInput.nativeElement.focus();
    } else {
      this.digits.fourth = '';

    }
  }

  onInput5KeyUp(event) {

    this.enableSubmitButton = this.isFormFullyPopulated();

    if (!event.target.value && event.key === 'Backspace') {
      this.fourthDigitInput.nativeElement.focus();

      return;
    }

    const numericInput = Number(event.key);

    if (!isNaN(numericInput)) {
      this.digits.fifth = String(numericInput);
      this.sixthDigitInput.nativeElement.focus();
    } else {
      this.digits.fifth = '';
    }
  }

  onInput6KeyUp(event) {

    if (!event.target.value && event.key === 'Backspace') {
      this.fifthDigitInput.nativeElement.focus();

      return;
    }

    if (event.target.value && event.key === 'Enter' && this.enableSubmitButton) {
      this.onSubmit(event);

      return;
    }

    const numericInput = Number(event.key);

    if (!isNaN(numericInput)) {
      this.digits.sixth = String(numericInput);

    } else {
      this.digits.sixth = '';
    }

    this.enableSubmitButton = this.isFormFullyPopulated();
  }

  isFormFullyPopulated(): boolean {
    return Object.keys(this.digits).reduce((acc, key) => (
      acc && !!this.digits[key]
    ), true as boolean);
  }

  onSubmit(event) {

    const code = Object.keys(this.digits).reduce((acc, key) => acc + this.digits[key], '');

    this.showAuthLoader = true;
    this.storeService.setAuthenticationCode(null);
    this.apiService.checkAuthentication(
      this.storeService.token,
      code,
    ).subscribe(
      () => {
        this.showConfirmation = false;
        this.showError = false;
        this.showAuthLoader = false;
        this.apiService.getAll(this.storeService.token, { code: code }).subscribe(() => {
          if (code) {
            this.storeService.setAuthenticationCode(code);
            this.appNavService.goToNext(this.storeService.setup.sequence, this.pageType, { code: code });
          }
        },
        );
      },
      () => {
        this.showConfirmation = false;
        this.showError = true;
        this.showAuthLoader = false;
      });
  }

  onResendTan(event) {

    if (this.timer) {
      this.showTimer = true;
      this.showConfirmation = false;

      return;
    }

    this.showLoader = true;
    this.showError = false;


    this.storeService.setAuthenticationCode(null);
    this.apiService.trigger2FASMS(this.storeService.token).subscribe(() => {
      this.showLoader = false;
      this.showConfirmation = true;
    });

    this.remainingSeconds = 30;

    this.timer = setInterval(() => {

      if (this.remainingSeconds <= 0) {
        clearInterval(this.timer);
        this.timer = null;
        this.showTimer = false;

        return;
      }

      this.remainingSeconds = this.remainingSeconds - 1;

    }, 1000);
  }
}
