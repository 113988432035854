<div id="validationError" *ngIf="validationError">
  <div class="alert alert-danger alert-dismissible fade show" role="alert">
    {{ validationError | translate }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeValidationError()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="c-app-content">
  <app-app-loader *ngIf="showLoader"></app-app-loader>

  <app-topbar class="custom-topbar"
    *ngIf="storeService?.setup?.showTopbar && storeService?.setup?.features[pageType][featureType.TopBar]"
    lineOne="{{lineOne}}" lineTwo="{{storeService.contractNumber ? storeService.contractNumber : null}}"></app-topbar>

  <div class="c-main">
    <div class="c-content">
      <div class="c-img-container">
        <div class="c-img">
          <img *ngIf="!isEmailChanged" src="/assets/img/standard/success.svg" width="40">
          <img *ngIf="isEmailChanged" src="/assets/img/standard/mail.svg" width="40">
        </div>
      </div>
      <div class="c-main-title" *ngIf="!isEmailChanged">
        {{ 'confirmation.thanks' | translate }}<br>
        {{ 'confirmation.success' | translate }}
      </div>
      <div class="c-main-title" *ngIf="isEmailChanged">
        {{ 'confirmation.almost' | translate }}<br>
        <div *ngIf="storeService?.confirmationRequested">
          {{ 'confirmation.emailSent' | translate }}
        </div>
      </div>

      <!--    <div class="c-main-subtitle">-->
      <!--      <ng-container *ngIf="!isEmailChanged">-->
      <!--        Sie haben auch einen Festnetz-Vertrag bei uns?-->
      <!--      </ng-container>-->
      <!--    </div>-->
      <!--    <button *ngIf="!isEmailChanged"-->
      <!--            (click)="onGoToAnsweringClick()"-->
      <!--            class="btn btn-link c-btn-open"-->
      <!--            style="margin-top: -7px;font-size: 14px;font-weight: 600;">-->
      <!--      Kontaktdaten ebenfalls ändern-->
      <!--    </button>-->
    </div>

    <div
      *ngIf="storeService?.setup?.includeEmployee && storeService?.setup?.features[pageType][featureType.ShowEmployee]"
      class="employee-card">
      <div class="avatar-container">
        <div *ngIf="person.imageUrl" class="avatar custom-avatar">
          <img [src]="person.imageUrl">
        </div>
        <div *ngIf="!person.imageUrl" [appIcon]="'avatar_placeholder'" [size]="30"
          class="avatar c-avatar-placeholder custom-avatar">
        </div>
      </div>
      <div class="employee-card-content">
        <div class="employee-card-title">{{person.name.firstName}} {{person.name.lastName}}</div>
        <div class="employee-card-subtitle">{{person.company}}</div>
        <div class="employee-card-contact">
          <span *ngIf="person.phone">{{ 'labelTexts.phone' | translate }}: {{person.phone}}</span>
          <span *ngIf="person.email">{{ 'labelTexts.email' | translate }}: {{person.email}}</span>
          <span *ngIf="person.website"><a href="{{person.website}}" target="_blank">{{person.website}}</a></span>
        </div>
      </div>
      <div class="employee-card-download-contact" *ngIf="showvCard">
        <a (click)="downloadContact()">{{ 'publicProfile.buttons.vcard' | translate }}</a>
      </div>
    </div>

  </div>

  <div *ngIf="reoConsent">
    <div *ngIf="(afterDeliveryNotice && !isTelekomFlow) || !isReoConsentGiven" class="c-footer">
      <div class="c-checkbox-section">
        Nie wieder Papierberge:
        <div class="c-item" style="margin-bottom: 22px;margin-top: 9px;">
          <div class="c-icon">
            <p-checkbox *ngIf="!setup.features[pageType][featureType.CheckboxInlinePreferenceText]"
              (change)="reoConsent.value=!reoConsent.value" [checked]="reoConsent.value" animation="pulse"
              color="primary" enableFocus="true" shape="curve" class="custom-checkbox"
              style="margin: 0px 0px 0px 11px !important;">
              <svg p-svg style="transform: scale(0.7);" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" aria-hidden="true">
                <g fill="currentColor">
                  <g>
                    <path
                      d="M22.4 5.05c-.3-.3-.75-.3-1.05 0L9 17.4l-5.9-5.9c-.3-.3-.75-.3-1.05 0s-.3.75 0 1.05L9 19.5 22.4 6.1c.3-.3.3-.75 0-1.05z"
                      fill-rule="evenodd"></path>
                  </g>
                </g>
              </svg>
            </p-checkbox>
          </div>
          <div class="c-entry">
            <div class="c-description">
              <div *ngIf="reoConsent.iconSvg" class="c-consent-icon" [innerHTML]="reoConsent.iconSvg | safe"></div>
              <div class="c-title">{{reoConsent.title}}</div>
              <div *ngIf="setup.features[pageType][featureType.CheckboxInlinePreferenceText]"
                class="c-inline-checkbox-container">
                <p-checkbox (change)="updateValue($event)" [checked]="reoConsent.value" animation="pulse"
                  color="primary" enableFocus="true" shape="curve" class="c-inline-checkbox custom-checkbox"
                  style="margin: 0px 0px 0px 11px !important;">
                  <svg p-svg style="transform: scale(0.7);" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" aria-hidden="true">
                    <g fill="currentColor">
                      <g>
                        <path
                          d="M22.4 5.05c-.3-.3-.75-.3-1.05 0L9 17.4l-5.9-5.9c-.3-.3-.75-.3-1.05 0s-.3.75 0 1.05L9 19.5 22.4 6.1c.3-.3.3-.75 0-1.05z"
                          fill-rule="evenodd"></path>
                      </g>
                    </g>
                  </svg>
                </p-checkbox>
              </div>
              <div class="c-subtitle custom-subtitle" [innerHTML]="reoConsent.html"></div>
            </div>
          </div>
        </div>
      </div>
      <button (click)="onSendClick()" [disabled]="!reoConsent.value"
        class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary custom-send-button" type="button">
        <ng-container>Senden</ng-container>
      </button>
    </div>
  </div>

  <app-info *ngIf="setup?.features[pageType][featureType.AppInfo]" background></app-info>
