<div class="c-app-content">
  <app-topbar class="custom-topbar" *ngIf="storeService?.setup?.showTopbar"></app-topbar>
  <app-sender-message-element *ngIf="storeService?.setup?.includeEmployee" [pageType]="pageType"
    style="padding-top: 26px;"></app-sender-message-element>

  <div class="c-overlay">
    <div class="c-pin-popup">
      <div class="c-logo-container">
        <div class="c-logo">
          <img src="/assets/img/telekom/logo.svg" width="78">
        </div>
      </div>
      <div class="c-title">
        Kontaktdaten aktualisieren
      </div>
      <div class="c-subtitle">
        Geben Sie den Code ein, den Sie per SMS bereits erhalten haben.
      </div>
      <div class="c-pin-inputs">

        <div class=" form-group c-form-group">
          <div style="display: flex; align-items: center;">
            <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)" (blur)="onBlur($event)"
              (ngModelChange)="onInputChange([n1.value])" [(ngModel)]="n1.value" [disabled]="false"
              class="form-control c-form-control pin-input {{error ? 'input-with-error' : ''}}" placeholder=""
              type="number">
          </div>
          <div style="display: flex; align-items: center;">
            <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)" (blur)="onBlur($event)"
              (ngModelChange)="onInputChange([n2.value])" [(ngModel)]="n2.value" [disabled]="false"
              class="form-control c-form-control pin-input {{error ? 'input-with-error' : ''}}" placeholder=""
              type="number">
          </div>
          <div style="display: flex; align-items: center;">
            <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)" (blur)="onBlur($event)"
              (ngModelChange)="onInputChange([n3.value])" [(ngModel)]="n3.value" [disabled]="false"
              class="form-control c-form-control pin-input {{error ? 'input-with-error' : ''}}" placeholder=""
              type="number">
          </div>
          <div style="display: flex; align-items: center;">
            <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)" (blur)="onBlur($event)"
              (ngModelChange)="onInputChange([n4.value])" [(ngModel)]="n4.value" [disabled]="false"
              class="form-control c-form-control pin-input {{error ? 'input-with-error' : ''}}" placeholder=""
              type="number">
          </div>
        </div>

      </div>
      <button (click)="onSendClick()" class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary" type="button">
        Überprüfen
      </button>
      <div class="c-footer">
        Ein Service von Complero im Auftrag der <br>Telekom Deutschland GmbH
      </div>
    </div>
  </div>


  <app-app-loader *ngIf="showLoader"></app-app-loader>


  <div *ngIf="isAlright" class="c-alright">

  </div>
</div>