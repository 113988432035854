import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { ErrorComponent } from './error/error.component';


@NgModule({
  declarations: [ErrorComponent],
  exports: [
    ErrorComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class ErrorModule { }
