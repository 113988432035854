import { Side } from '../../common/classes/side';
import { CategoryType } from '../../common/enums/category.type';
import { InfoItemType } from '../../common/enums/info-item.type';
import { InfoItemsFactory } from '../factories/info-items.factory';
import { SideInterface } from '../../common/interfaces/side.interface';
import { CategoryInterface } from '../../common/interfaces/category.interface';
import { InfoItem } from '../../common/classes/Info-item';
import { SetupInterface } from '../../common/interfaces/setup.interface';
import { PageType } from '../../common/enums/page.type';
import { itemTypeToFieldType } from '../../common/functions/item-type-to-field-type.function';
import { FullAddress } from '../../common/classes/full-address';


export class SideAdapter {

  public static processData(serverData: SideInterface, setup: SetupInterface): Side {

    const workInfoItems = this.makeCountryDefault(this.processEntries(serverData.work, CategoryType.WORK, setup), 'DE');
    const homeInfoItems = this.makeCountryDefault(this.processEntries(serverData.home, CategoryType.PERSONAL, setup), 'DE');

    const sharedInfoItems = this.processEntries(serverData.home, CategoryType.SHARED, setup);

    return new Side({
      id: serverData.id,
      person: serverData.person,
      handler_id: serverData.handler_id,
      message: serverData.message,
      workInfoItems: workInfoItems,
      homeInfoItems: homeInfoItems,
      sharedInfoItems: sharedInfoItems,
      tags: serverData.person.tags,
    });
  }

  private static processEntries(category: CategoryInterface, categoryType: CategoryType, setup: SetupInterface): InfoItem[] {

    return Object.keys(category).reduce((result, key) => {

      const itemType = this.makeItemType(key);

      const params: any = {};

      if (key === 'mobileNumbers') {
        params.isMobile = true;
      }

      const readOnly = setup.readOnly[PageType.Answering][itemTypeToFieldType(itemType, categoryType)];
      const required = setup.required[PageType.Answering][itemTypeToFieldType(itemType, categoryType)];

      const items = category[key]
        .map((serverItem, idx) => {
          // in case when multiple items of the same type requested
          // at least one of them should be required
          // the rest items can be optional
          // ex.: private_emails -> [required, optional, optional]
          const itemRequired = idx === 0 ? required : false;

          return InfoItemsFactory.makeInfoItem(false, readOnly, itemRequired, itemType, categoryType, serverItem, params);
        });


      return [
        ...result,
        ...items,
      ];
    }, []);
  }


  private static makeItemType(key) {
    switch (key) {
      case 'addresses':
        return InfoItemType.ADDRESS;
      case 'fullAddresses':
        return InfoItemType.FULL_ADDRESS;
      case 'events':
        return InfoItemType.EVENT;
      case 'emails':
        return InfoItemType.EMAIL;
      case 'phoneNumbers':
        return InfoItemType.PHONE;
      case 'mobileNumbers':
        return InfoItemType.PHONE;
      case 'faxes':
        return InfoItemType.FAX;
      case 'urls':
        return InfoItemType.URL;

    }
  }

  private static makeCountryDefault(items: InfoItem[], country: string): InfoItem[] {
    return items.map((item) => {
      if (item.type === InfoItemType.FULL_ADDRESS || item.type === InfoItemType.ADDRESS) {
        if ((item as FullAddress).country === '') {
          (item as FullAddress).country = country;
        }
      }

      return item;
    });
  }
}

