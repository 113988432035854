<div *ngFor="let page of setup.sequence;let b = first; let i = index" [class.c-muted]="i>currentPageIndex"
  class="c-step">
  <div *ngIf="!b" class="c-line"></div>
  <div (click)="onStepClick(i)" class="c-step-link">
    <!--<span class="badge badge-primary c-badge">{{i + 1}}</span>-->
    <span class="c-label">
      <ng-container *ngIf="page===pageType.Answering">{{'step.contact'|translate}}</ng-container>
      <ng-container *ngIf="page===pageType.Sharing">{{'step.done'|translate}}</ng-container>
      <ng-container *ngIf="page===pageType.Preference">{{'step.subscribe'|translate}}</ng-container>
    </span>
  </div>


</div>