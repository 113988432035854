<div class="c-category">
  <div class="c-category-header custom-light-panel">
    <div class="c-category-title">{{'account.download.title'| translate}}</div>
  </div>
  <div class="c-category-content">
    <div class="c-container">
      <button (click)="onDownloadFileClick()"
        class="btn btn-primary c-btn-vcard g-btn-bold g-btn-primary-outline custom-btn-primary-outline">
        <span [appIcon]="'csv'" [size]="20" style="margin: 0 10px 0"></span>
        {{'account.download.btn'| translate}}
      </button>
    </div>
  </div>
</div>