import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import { LoadingOutline } from '@ant-design/icons-angular/icons';

import { DataService } from './services/data.service';
import { AppNavService } from './services/app-nav.service';
import { StoreService } from './services/store.service';
import { ApiService } from './services/api.service';
import { SetupResolver } from './resolvers/setup.resolver';
import { ReceiverService } from './services/receiver.service';
import { TelService } from './services/tel.service';
import { SmsAuthGuardGuard } from './guards/sms-auth-guard.guard';
import { DobAuthGuardGuard } from './guards/dob-auth-guard.guard';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { TranslationModule } from './modules/translation';


const icons: IconDefinition[] = [LoadingOutline];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslationModule.forRoot(),
    NzIconModule.forRoot(icons),
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        DataService,
        AppNavService,
        StoreService,
        ApiService,
        SetupResolver,
        ReceiverService,
        TelService,
        DobAuthGuardGuard,
        SmsAuthGuardGuard,
        NotAuthenticatedGuard,
      ],
    };
  }
}
