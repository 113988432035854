Datenschutzerklärung

1. Verantwortliche Stelle / Kontakt


Verantwortliche Stelle im Sinne der Datenschutzgesetze ist:


Complero GmbH

Im Wiesengrund 49

53539 Kelberg


Wenn Sie Fragen oder Anregungen zum Datenschutz haben,

können Sie sich gerne auch per E-Mail an uns werden, unter: Datenschutz@complero.com.


Stand: 1.12. 2018

Mit dieser Datenschutzerklärung informieren wir Sie darüber, welche personenbezogenen Daten wir im Rahmen Ihrer Nutzung
der Complero Services erheben und zu welchem Zweck die Daten verwendet werden.

Wir behalten uns vor Änderungen an der Datenschutzerklärung im Rahmen der Gesetzlichen Vorgaben vorzunehmen.

Sie können die aktuelle Version unter https://www2.complero.de/Datenschutz abrufen.

2. Gegenstand des Datenschutzes

Gegenstand des Datenschutzes sind personenbezogene Daten. Dies sind nach Art. 4 Nr. 1 DSGVO alle Informationen, die sich
auf eine identifizierte oder identifizierbare natürliche Person beziehen; dies umfasst bspw. Namen oder auch
Kennnummern.


3. Erhebung von Daten

3.1. Automatisierte Datenerhebung

Bei der Nutzung unserer Service im Web, als App oder über sonstige Quellen werden aus technischen Gründen automatisch
Daten übermittelt. Diese Daten werden getrennt von anderen Daten gespeichert, dazu gehören: Datum und Uhrzeit von
Zugriffen, Browsertyp /- Version, verwendete Software oder Betriebssystem, URL der besuchten oder zuvor besuchten
Webseiten (referal), oder die IP-Adresse. Die Speicherung der Daten erfolgt aufgrund von technischen Erfordernissen.


3.2. Zur Verfügung gestellte Daten

Die von Ihnen uns zur Verfügung gestellten Daten sowie deren Verarbeitung dienen der Erfüllung der Durchführung unserer
vertraglich zugesicherten Maßnahmen und somit für die Erfüllung des Vertrags erforderlich (Art. 6 Abs. 1 b) DSGVO).
Diese Daten werden ausschließlich dadurch erhoben, dass Sie uns diese mitteilen oder uns den Zugriff auf diese Daten
gewähren. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.


3.2. Support

Sollten Sie sich an unseren Support wenden, erheben und speichern wir von Ihnen personenbezogene Daten mit dem Zweck der
Bearbeitung deiner Anfrage. Sie sind dabei nicht verpflichtet im Rahmen der Anfrage personenbezogene Daten zu
übermitteln, Sie können uns auch anonym kontaktieren. Bitte beachten Sie doch, dass dann unter Umständen kein
vollständiger Support gewährleistet werden kann. Ab Erledigung Ihrer Anfrage werden die entsprechenden Support-Anfragen
für bis zu 120 Tagen gespeichert, dies gilt der Gewährleistung von Folge-Anfragen. Bitte beachten Sie, dass
Support-Anfragen, die aus einem unserer Services heraus verschickt werden bereits eindeutige Identifizierungsmerkmale
enthalten können, um Ihre Anfrage besser in einen technischen Kontext setzen zu können.

Die Erhebung und Verarbeitung der Daten dient dazu einen reibungslosen Betrieb zu gewährleisten und Ihre
Support-Anfragen möglichst direkt bestimmen Fehlerquellen zuordnen zu können (Art. 6 Abs. 1 b) DSGVO). Bei
nicht-technischen Anfragen verarbeiten wir personenbezogenen Daten gemäß dem berichtigten Interesse (Art. 6 Abs. 1 f)
DSGVO).


4. Cookies und Zähl-Pixel

Die Internetseiten verwenden teilweise so genannte Cookies oder Pixel. Cookies richten auf Ihrem Rechner keinen Schaden
an und enthalten keine Viren. Cookies und Zähl-Pixel dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
speichert.

Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs
automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies
ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.

Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im
Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische
Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
dieser Website eingeschränkt sein.

Wir verwenden Cookies und Zählpixel aufgrund des berechtigten Interesses an einer bedarfsgerechten Gestaltung sowie der
statistischen Auswertung unserer Services (Art. 6 Abs. 1 f) DSGVO).


5. Newsletter und Warteliste

Über unsere Newsletter bzw. Warteliste informieren wir Sie über Neuigkeiten zu unserem Unternehmen, der
Produktentwicklung sowie rund um Themen, die mit unseren Services zusammenhängen. Wenn Sie sich in unsere Warteliste
eingetragen haben, erhalten Sie automatisch einen Teil unseres Newsletters, der für Sie von Relevanz ist. Dabei können
Sie sich jederzeit von dem Newsletter oder unserer Warteliste austragen. Dazu erhält jede Nachricht die Information, wie
weitere Nachrichten abbestellt werden können. Oder schreiben Sie uns an unsubscribe@complero.com. Die Verarbeitung und
Erhebung von personenbezogenen Daten erfolgt in diesem Fall durch Ihren expliziten Wunsch diese Informationen bzgl.
Newsletter bzw. Warteliste zu erhalten (Art. 6 Abs. 1 b) DSGVO).


6. Weitergabe von Daten

Als Treuhänder für Kontaktdaten erfolgt grundsätzlich keine Weitergabe personenbezogene Daten ohne Ihren Auftrag.

Zur Verfolgung von Straftraten, der Bekämpfung von Missbrauch kann es zu einer gerichtlichen Anordnung kommen, die uns
zur Weitergabe von personenbezogenen Daten aufgrund gesetzlicher Verpflichtungen (Art. 6 Abs. 1 c) DSGVO) zwingen, dies
ist jedoch nur Fall, wenn diese Verpflichtungen dem Schutz personenbezogener Daten überwiegen (Art. 6 Abs. 1 f) DSGVO).


Für die Erbringung unserer Services, und somit der vertraglich geschuldeten Leistung, sind wir auf vertraglich
verbundene Fremdunternehmen sowie externe Dienstleister („Auftragsverarbeiter“ im Folgenden AV) angewiesen. In solchen
Fällen werden personenbezogene Daten unter strengen, vertraglich regulierten Rahmenbedingungen an diese
Auftragsverarbeiter weitergegeben. Dabei dürfen diese Auftragsverarbeiter die Daten ausschließlich zu den von uns
vergebenen Zwecken verwenden. Die Verarbeitung erfolgt ausschließlich gemäß dieser Datenschutzerklärung und geltender
Datenschutzgesetze. Rechtliche Grundlage bildet hier die Weitergabe von Daten an Auftragsverarbeiter (Art. 28 Abs. 1
DSGVO), hilfsweise auf Grundlage eines berechtigten Interessens, zur Einbeziehung von spezialisierten
Auftragsverarbeiter aufgrund technischen- sowie wirtschaftlichen Vorteilen. Unter dem Umstand, dass Ihre Rechte zum
Schutz personenbezogene Daten nicht überwiegen (Art. 6 Abs. 1 f) DSGVO).


Eine Anpassung der Unternehmensform oder Beteiligungsstruktur stellt nach den wirtschaftlichen und rechtlichen
Gegebenheiten ein berichtigtes Interesse zur etwaigen Weitergabe personenbezogener Daten dar (Art. 6 Abs. 1 f) DSGVO).

Sollten sich im Rahmen der Weiterentwicklung der Gesellschaft oder des Geschäfts zu Umstrukturierungen der Complero GmbH
kommen, weil sich beispielsweise die Rechtsform ändert, Tochtergesellschaften, Unternehmensanteile oder Bestandteile
gegründet, gekauft oder verkauft werden, verpflichten wir uns auch weiterhin im vorbeschriebenen Umfang für die
Sicherheit der Daten in Übereinstimmung mit dieser Datenschutzerklärung und geltenden Datenschutzgesetzen Sorge zu
tragen.

7. Zweckänderungen

Die Verarbeitung und Erfassung personenbezogener Daten erfolgt nur zu den beschriebenen oder durch Rechtsvorschriften
erlaubten Zwecken. Im Falle von Verarbeitung zu anderen Zwecken ist Ihre Einwilligung erforderlich.

Vor einer möglichen Weiterverarbeitung als den zum Erfassungszeitpunkt genannten Zwecken erfolgt nur nach vorheriger
Informierung über sämtliche hierfür maßgeblichen Informationen.


8. Löschung der Daten

Sollten die erhobenen personenbezogenen Daten für die genannten Zwecke nicht mehr verwendet werden oder nicht mehr
erforderlich sein, so werden diese gelöscht, soweit diese Daten nicht aufgrund von gesetzlicher Aufbewahrungsfristen
länger benötigt werden. Im Rahmen einer Sperrliste zum Verbot weiterer Verarbeitung können wir jedoch die dazu
erforderlichen Daten weiterhin speichern, wenn Ihr Interesse an einem Verarbeitungsverbot überwiegt, insofern dies nur
durch das Führen einer Sperrliste aufrechterhalten werden kann.


9. Betroffenenrechte

9.1. Auskunftsrecht

Sie haben das Recht, von uns jederzeit auf Antrag eine Auskunft über die von uns verarbeiteten, Sie betreffenden
personenbezogenen Daten im Umfang des Art. 15 DSGVO zu erhalten. Hierzu können Sie einen Antrag postalisch oder per
E-Mail an die oben angegebene Adresse stellen.


9.2. Recht zur Berichtigung unrichtiger Daten

Sie haben das Recht, von uns die unverzügliche Berichtigung der Sie betreffenden personenbezogenen Daten zu verlangen,
sofern diese unrichtig sein sollten. Wenden Sie sich hierfür bitte an die oben angegebenen Kontaktadressen.


9.3. Recht auf Löschung

Sie haben das Recht, unter den in Art. 17 DSGVO beschriebenen Voraussetzungen von uns die Löschung der Sie betreffenden
personenbezogenen Daten zu verlangen. Diese Voraussetzungen sehen insbesondere ein Löschungsrecht vor, wenn die
personenbezogenen Daten für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
notwendig sind sowie in Fällen der unrechtmäßigen Verarbeitung, des Vorliegens eines Widerspruchs oder des Bestehens
einer Löschpflicht nach Unionsrecht oder dem Recht des Mitgliedstaates, dem wir unterliegen. Um Ihr vorstehendes Recht
geltend zu machen, wenden Sie sich bitte an die oben angegebenen Kontaktadressen.


9.4. Recht auf Einschränkung der Verarbeitung

Sie haben das Recht, von uns die Einschränkung der Verarbeitung nach Maßgabe des Art. 18 DSGVO zu verlangen. Dieses
Recht besteht insbesondere, wenn die Richtigkeit der personenbezogenen Daten zwischen dem Nutzer und uns umstritten ist,
für die Dauer, welche die Überprüfung der Richtigkeit erfordert sowie im Fall, dass der Nutzer bei einem bestehenden
Recht auf Löschung anstelle der Löschung eine eingeschränkte Verarbeitung verlangt; ferner für den Fall, dass die Daten
für die von uns verfolgten Zwecke nicht länger erforderlich sind, der Nutzer sie jedoch zur Geltendmachung, Ausübung
oder Verteidigung von Rechtsansprüchen benötigt sowie wenn die erfolgreiche Ausübung eines Widerspruchs zwischen uns und
dem Nutzer noch umstritten ist. Um Ihr vorstehendes Recht geltend zu machen, wenden Sie sich bitte an die oben
angegebenen Kontaktadressen.


9.5. Recht auf Datenübertragbarkeit

Sie haben das Recht, von uns die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
strukturierten, gängigen, maschinenlesbaren Format nach Maßgabe des Art. 20 DSGVO zu erhalten. Um Ihr vorstehendes Recht
geltend zu machen, wenden Sie sich bitte an die oben angegebenen Kontaktadressen.


9.6. Widerspruchsrecht

Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie
betreffenden personenbezogener Daten, die u.a. aufgrund von Art. 6 Abs. 1 e) oder f) DSGVO erfolgt, Widerspruch nach
Art. 21 DSGVO einzulegen. Wir werden die Verarbeitung Ihrer personenbezogenen Daten einstellen, es sei denn, wir können
zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen,
oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.


9.7. Beschwerderecht

Sie haben das Recht, sich bei Beschwerden an die zuständige Aufsichtsbehörde zu wenden. Die zuständige Aufsichtsbehörde
ist: Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit Rheinland-Pfalz, Hintere Bleiche 34, 55116
Mainz, https://www.datenschutz.rlp.de Telefon: +49 (0) 6131 208-2449 Telefax: +49 (0) 6131 208-2497
poststelle(at)datenschutz.rlp.de


10. Vertragspartner

10.1. Der Versand der Newsletter erfolgt mittels des Versanddienstleisters „MailChimp“ (bzw. Mailgun zum Versenden von
Systemnachrichten), einer Newsletterversandplattform des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave
NE #5000, Atlanta, GA 30308, USA. Die Datenschutzbestimmungen des Versanddienstleisters können Sie hier einsehen:
https://mailchimp.com/legal/privacy/. The Rocket Science Group LLC d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen
zertifiziert und bietet hierdurch eine Garantie, das europäisches Datenschutzniveau einzuhalten
(https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active). Der Versanddienstleister wird auf
Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 f) DSGVO und eines Auftragsverarbeitungsvertrages gem. Art.
28 Abs. 3 S. 1 DSGVO eingesetzt. Der Versanddienstleister kann die Daten der Empfänger in pseudonymer Form, d.h. ohne
Zuordnung zu einem Nutzer, zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen
Optimierung des Versandes und der Darstellung der Newsletter oder für statistische Zwecke verwenden. Der
Versanddienstleister nutzt die Daten unserer Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder um die
Daten an Dritte weiterzugeben.


10.2. Wir hosten unsere Onlineangebote bei deutschen Hostinganbietern mit denen wir einen Auftragsverarbeitungsvertrag
geschlossen haben. Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden
Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste,
Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes
einsetzen. Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf
Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes
gem. Art. 6 Abs. 1 f) DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).


10.3. Google Tag Manager fasst die meisten Tracking-Pixel und Skripte so zusammen, damit die Nutzer unsere Webseiten
schneller laden können. Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine Oberfläche verwalten
können (und so z.B. Google Analytics sowie andere Google-Marketing-Dienste in unser Onlineangebot einbinden). Der Tag
Manager selbst (welches die Tags implementiert) verarbeitet keine personenbezogenen Daten der Nutzer. Im Hinblick auf
die Verarbeitung der personenbezogenen Daten der Nutzer wird auf die folgenden Angaben zu den Google-Diensten verwiesen.
Nutzungsrichtlinien: https://www.google.com/intl/de/tagmanager/use-policy.html.


10.4. Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 f) DSGVO) Google Analytics, einen
Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, („Google“) ein. Google
verwendet Cookies. Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer
werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.

Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
Datenschutzrecht einzuhalten (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active). Google
wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer
auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der
Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei
können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.

Dabei setzen wir Google Analytics nur mit aktivierter IP-Anonymisierung ein, d.h. die IP-Adressen der Nutzer werden
gekürzt. Die IP-Adresse des Nutzers wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle
IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die von dem Browser des Nutzers
übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der
Cookies durch eine entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die
Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die
Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin
herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.

Wir nutzen das Onlinemarketingverfahren Google „AdWords“, um Anzeigen im Google-Werbe-Netzwerk zu platzieren (z.B., in
Suchergebnissen, in Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt werden, die ein mutmaßliches Interesse an
den Anzeigen haben. Dies erlaubt uns Anzeigen für und innerhalb unseres Onlineangebotes gezielter anzuzeigen, um Nutzern
nur Anzeigen zu präsentieren, die potentiell deren Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte
angezeigt werden, für die er sich auf anderen Onlineangeboten interessiert hat, spricht man hierbei vom „Remarketing“.
Zu diesen Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist,
unmittelbar durch Google ein Code von Google ausgeführt und es werden sog. (Re)marketing-Tags (unsichtbare Grafiken oder
Code, auch als „Web Beacons“ bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein
individuelles Cookie, d.h. eine kleine Datei abgespeichert (statt Cookies können auch vergleichbare Technologien
verwendet werden). In dieser Datei wird vermerkt, welche Webseiten der Nutzer aufgesucht, für welche Inhalte er sich
interessiert und welche Angebote der Nutzer geklickt hat, ferner technische Informationen zum Browser und
Betriebssystem, verweisende Webseiten, Besuchszeiten sowie weitere Angaben zur Nutzung des Onlineangebotes.

Wir nutzen Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave.,
San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchen, wird eine
Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem Youtube-Server mitgeteilt, welche unserer Seiten Sie
besucht haben.

Wenn Sie in Ihrem YouTube-Account eingeloggt sind ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen
Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.

Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter:
https://www.google.de/intl/de/policies/privacy

Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der
Datenschutzerklärung von Google (https://policies.google.com/technologies/ads) sowie in den Einstellungen für die
Darstellung von Werbeeinblendungen durch Google (https://adssettings.google.com/authenticated).

Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.


10.5. Innerhalb unseres Onlineangebotes wird aufgrund unserer berechtigten Interessen an Analyse, Optimierung und
wirtschaftlichem Betrieb unseres Onlineangebotes und zu diesen Zwecken das sog. „Facebook-Pixel“ des sozialen Netzwerkes
Facebook, welches von der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA, bzw. falls Sie in der EU ansässig
sind, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird („Facebook“),
eingesetzt.

Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
Datenschutzrecht einzuhalten (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active). Mit Hilfe
des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe für die
Darstellung von Anzeigen (sog. „Facebook-Ads“) zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die
durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem
Onlineangebot gezeigt haben oder die bestimmten Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die
anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Facebook übermitteln (sog. „Custom Audiences“).
Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der
Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der
Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, in dem wir sehen ob Nutzer nach dem
Klick auf eine Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden (sog. „Conversion“).

Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks Datenverwendungsrichtlinie. Dementsprechend
generelle Hinweise zur Darstellung von Facebook-Ads, in der Datenverwendungsrichtlinie von Facebook:
https://www.facebook.com/policy.php. Spezielle Informationen und Details zum Facebook-Pixel und seiner Funktionsweise
erhalten Sie im Hilfebereich von Facebook: https://www.facebook.com/business/help/651294705016616. Sie können der
Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur Darstellung von Facebook-Ads widersprechen. Um
einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook angezeigt werden, können Sie die von Facebook
eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen nutzungsbasierter Werbung befolgen:
https://www.facebook.com/settings?tab=ads. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle
Geräte, wie Desktopcomputer oder mobile Geräte übernommen.

Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, ferner über die
Deaktivierungsseite der Netzwerkwerbeinitiative (http://optout.networkadvertising.org/) und zusätzlich die
US-amerikanische Webseite (http://www.aboutads.info/choices) oder die europäische Webseite
(http://www.youronlinechoices.com/uk/your-ad-choices/) widersprechen.


10.6. Wir nutzen die Services von instapage.com. Instapage, Inc (https://instapage.com/). Dies ist eine Plattform zur
Erstellung von Micro-Sites und Online-Formularen. Wenn Sie uns über eines dieser Onlineformulare auf diesen Seiten oder
per E-Mail kontaktieren, speichern wir die von Ihnen gemachten Angaben, Ihre IP-Adresse, den Zeitpunkt des Absendens und
die Information ob sie das Formular auf einem Computer oder einem mobilen Gerät geöffnet haben. So können wir Ihre
Anfrage beantworten und mögliche Anschlussfragen stellen und gegebenenfalls das von uns auf Instapage zur Verfügung
gestellte Formular bzw. die enthaltenen Informationen verbessern. Siehe auch https://instapage.com/privacy-policy.