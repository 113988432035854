<div class="c-category">
  <div class="c-category-header custom-light-panel">
    <div class="c-category-title">{{'account.manage.title'| translate}}</div>
  </div>
  <div class="c-category-content">
    <div class="c-container">

      <div class="c-info">{{'account.manage.info'| translate}}</div>

      <div class="c-select-container">
        <div>{{'account.manage.selectLabel'| translate}}</div>
        <nz-select #selectRef (ngModelChange)="selectRef.nzOpen=false" [(ngModel)]="settingsService.selectedAccounts"
          [nzSize]="'large'" nzMode="multiple" nzPlaceHolder="{{'account.manage.selectPlaceholder'| translate}}">
          <nz-option *ngFor="let option of settingsService.accounts" [nzHide]="!isNotSelected(option)"
            [nzLabel]="option" [nzValue]="option"></nz-option>
        </nz-select>
      </div>

      <div [class.c-not-selected]="!settingsService.selectedAccounts.length">
        <div (click)="onDeleteClick()" class="c-section-toggle">
          <div class="c-toggle-title">{{'account.manage.navigation.toDelete.title'| translate}}</div>
          <div class="c-toggle-subtitle">{{'account.manage.navigation.toDelete.subtitle'| translate}}</div>
          <div [appIcon]="'arrow_right'" [size]="24" class="c-toggle-icon"></div>

        </div>
        <div (click)="onDownloadClick()" class="c-section-toggle">
          <div class="c-toggle-title">{{'account.manage.navigation.toDownload.title'| translate}}</div>
          <div class="c-toggle-subtitle">{{'account.manage.navigation.toDownload.subtitle'| translate}}</div>
          <div [appIcon]="'arrow_right'" [size]="24" class="c-toggle-icon"></div>

        </div>
      </div>
    </div>
  </div>
</div>