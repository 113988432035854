import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { StoreService } from '../../core/services/store.service';
import { LegalContentInterface } from '../../common/interfaces/legal-consent.interface';


@Component({
  selector: 'app-legal-element',
  templateUrl: './legal-element.component.html',
  styleUrls: ['./legal-element.component.scss'],
})
export class LegalElementComponent implements OnInit, OnDestroy {

  language: string = this.translateService.getDefaultLang();
  legal: LegalContentInterface;
  /* ----------------------------------------
   * Life-Cycles
   * -------------------------------------- */
  private onDestroySubject = new Subject();
  onDestroy$ = this.onDestroySubject.asObservable();

  constructor(
    public storeService: StoreService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.getLegal();
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$))
      .subscribe((params: LangChangeEvent) => {
        this.language = params.lang;
        this.getLegal();
      });
  }

  ngOnDestroy() {
    this.onDestroySubject.next(true);
    this.onDestroySubject.complete();
  }

  private getLegal() {
    // try the German version of the custom legal text as a fallback for now
    // TODO: Reevaluate whether using German as fallback language is desired
    this.legal = this.storeService.customLegal ?
      (this.storeService.customLegal[this.language] || this.storeService.customLegal.de) : null;
  }

}
