import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-top-header',
  templateUrl: 'top-header.component.html',
  styleUrls: ['top-header.component.scss'],
})
export class TopHeaderComponent {

  @Input() showBack?: boolean;
  @Output() back = new EventEmitter<true>();

  constructor() { }


  onBackClick() {
    this.back.emit(true);
  }

}
