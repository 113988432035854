import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { AuthenticationComponent } from './authentication/authentication.component';


@NgModule({
  declarations: [AuthenticationComponent],
  exports: [
    AuthenticationComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class AuthenticationModule { }
