import { Component, HostBinding } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { StoreService } from '../../core/services/store.service';
import { LoaderType } from '../../common/enums/loader.type';


@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
  animations: [
    trigger('fadeOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300),
      ]),
      transition(':leave', [
        animate(600, style({ opacity: '0' })),
      ]),
    ]),
  ],
})
export class AppLoaderComponent {

  loaderType = LoaderType;

  @HostBinding('@fadeOut') get() {
    return;
  }


  constructor(public storeService: StoreService) {

  }

}
