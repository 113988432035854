import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { Side } from '../../common/classes/side';

import { StoreService } from './store.service';


@Injectable()
export class DataService {

  constructor(
    private http: HttpClient,
    private storeService: StoreService,
  ) {
  }

  public getPhotoAsBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'blob' }).toPromise()
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data.toString());
          };
        }, (error) => {
          resolve('');
        });
    });


  }

  saveReceiverData(receiver: Side, token: string) {
    return of({});
    // return this.http.put(`${this.apiUrl}/requests/${token}/contact`, ReceiverAdapter.processPayload(receiver));
  }


}
