import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PageType } from '../../../common/enums/page.type';
import { StoreService } from '../../../core/services/store.service';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { FeatureType } from '../../../common/enums/feature.type';
import { Side } from '../../../common/classes/side';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { PersistanceService } from '../../../core/services/persistance.service';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, AfterViewInit, OnDestroy {

  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.Authentication;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  showLoader = false;
  showConfirmation = false;
  showTimer = false;
  showError = false;
  enableSubmitButton = false;
  enableResendButton = false;
  expiredLink = false;

  remainingSeconds = 0;

  timer = null;

  @ViewChild('firstDigitInput') firstDigitInput;
  @ViewChild('secondDigitInput') secondDigitInput;
  @ViewChild('thirdDigitInput') thirdDigitInput;
  @ViewChild('fourthDigitInput') fourthDigitInput;
  @ViewChild('fifthDigitInput') fifthDigitInput;
  @ViewChild('sixthDigitInput') sixthDigitInput;

  @Input() tanRecipient = '';

  @Input() digits = {
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
    sixth: '',
  };

  constructor(
    public storeService: StoreService,
    private appNavService: AppNavService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private persistanceService: PersistanceService,
  ) {
    this.expiredLink = Boolean(this.activatedRoute.snapshot.paramMap.get('expired'));
  }

  ngOnInit(): void {
    this.appNavService.currentPageType = this.pageType;
    this.appNavService.currentPageIndex = this.storeService?.setup?.sequence.indexOf(this.pageType) || 0;
    this.setup = this.storeService.setup;
    this.receiver = { ...this.storeService.receiver };

    if (this.storeService.token && !this.expiredLink) {
      this.apiService.trigger2FASMS(this.storeService.token).subscribe((response: any) => {
        const phoneNumber = response.phone;
        if (phoneNumber.length > 4) {
          this.tanRecipient = Array(phoneNumber.length - 4)
            .fill('*').join('') + phoneNumber.slice(phoneNumber.length - 4);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  ngAfterViewInit() {

  }

  onLearnMoreButtonClick() {
    window.open('https://www.complero.com', '_blank');
  }
}
