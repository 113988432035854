
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import libphonenumber from 'google-libphonenumber';
import * as countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import german from 'i18n-iso-countries/langs/de.json';
import { TranslateService } from '@ngx-translate/core';

import { InfoItemType, InfoItemTypeCheck } from '../../common/enums/info-item.type';
import { StoreService } from '../../core/services/store.service';
import { InfoItem } from '../../common/classes/Info-item';
import { FullAddress } from '../../common/classes/full-address';
import { FeatureType } from '../../common/enums/feature.type';
import { PageType } from '../../common/enums/page.type';
import { arrayIncludesSubset } from '../../common/functions/array-includes';
import { TelService } from '../../core/services/tel.service';


interface Option {
  value: string;
  label: string
}

interface StreetHouse {
  street: string;
  house: string;
}

class Errors {
  street: string;
  house: string;
  postcode: string;
  city: string;
  addition: string;
}

@Component({
  selector: 'app-info-input',
  templateUrl: './info-input.component.html',
  styleUrls: ['./info-input.component.scss'],
})
export class InfoInputComponent implements OnInit {

  @Input() item: InfoItem | any;
  @Input() pageType: PageType;
  @Input() error: undefined | string;
  @Input() highlightMandatoryFields?: boolean;
  @Input() isFirst: boolean;

  @Output() isFilledOut = new EventEmitter();
  @Output() isClearedOut = new EventEmitter();

  @ViewChild('dayInput') dayInput;
  @ViewChild('monthInput') monthInput;
  @ViewChild('yearInput') yearInput;

  dateComponents = {
    day: '',
    month: '',
    year: '',
  };

  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  infoItemType = InfoItemType;

  filteredCountryOptions: Option[] = [];
  countryOptions: Option[] = [];
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  show = true;

  public extra3LabelTranslationKey = 'labelTexts.extra3.landline';
  public extra3PlaceholderTranslationKey = 'receiver.placeholders.extra3.landline';

  errors = new Errors();

  constructor(
    public storeService: StoreService,
    private translate: TranslateService,
    private telService: TelService,
  ) {

    countries.registerLocale(english);
    countries.registerLocale(german);

    const allCountriesByCode = countries.getNames(/*translate.currentLang ||*/ 'de', { select: 'official' });
    const processedCountries: Option[] = [];

    // This should be moved away from the constructur, since it is being called 4 times on each app load
    for (const key of Object.keys(allCountriesByCode)) {
      if (key !== 'DE' && key !== 'AT') {
        processedCountries.push({
          value: key,
          label: allCountriesByCode[key],
        });
      }
    }

    this.countryOptions = processedCountries;
    this.countryOptions.unshift({
      value: 'DE',
      label: allCountriesByCode.DE,
    }, {
      value: 'AT',
      label: allCountriesByCode.AT,
    });
  }

  ngOnInit() {
    if (this.item.type === InfoItemType.EVENT) {
      if (this.item.dd != '' && this.item.mm != '' && this.item.yyyy != '') {
        this.dateComponents.day = this.item.dd;
        this.dateComponents.month = this.item.mm;
        this.dateComponents.year = this.item.yyyy;
      }
    }

    if (this.telService.isTel()) {
      if (arrayIncludesSubset(this.storeService.receiver.tags, 'mobilfunk')) {
        this.extra3LabelTranslationKey = 'labelTexts.extra3.mobile';
        this.extra3PlaceholderTranslationKey = 'receiver.placeholders.extra3.mobile';
      }
    }
  }

  makePlaceholder(item) {

  }

  prepareStreet(street: string, house: string): StreetHouse {
    const sh: StreetHouse = { street: street, house: house };

    if (street.includes(',')) {
      const value = street.split(', ');
      sh.street = value[0];
      sh.house = value[1];

      return sh;
    }

    if (street.includes(' ') && this.isEmpty(house)) {
      const splittedStreet = street.split(' ');
      let subStr = '';
      let subHouse = '';
      let finishedStreet = false;
      splittedStreet.forEach((s) => {
        if (!finishedStreet) {
          if (!/\d/.test(s)) {
            subStr = `${subStr  } ${s}`;
          } else {
            finishedStreet = true;
            subHouse = `${subHouse  } ${s}`;
          }
        } else {
          subHouse = `${subHouse  } ${s}`;
        }
      });
      sh.street = subStr.substring(1);
      sh.house = subHouse.substring(1);

      return sh;
    }

    if (!this.isEmpty(house)) {
      if (street.includes(house)) {
        sh.street = street.replace(` ${house}`, '');
      }
    }

    return sh;
  }

  isRequired() {
    return this.item.isRequired && this.isFirst;
  }


  onInputChange(fieldsValues: any[]) {
    const hasFilledOutInput = fieldsValues.some((value) => !!value && String(value).trim() !== '');
    this.isFilledOut.emit(hasFilledOutInput);

    if (this.item.type === InfoItemType.ADDRESS || this.item.type === InfoItemType.FULL_ADDRESS) {
      if ((this.item as FullAddress).postcode) {
        // this.validateInput({target: {value: (this.item as FullAddress).postcode}});
      }

      this.filteredCountryOptions = this.countryOptions
        .filter((option) => option.label.toLowerCase().indexOf(fieldsValues[0].toLowerCase()) !== -1);
    }
  }

  prepareAddress(event) {
    const values = this.prepareStreet(this.item.street, this.item.house);
    this.item.street = values.street.trimEnd();
    this.item.house = values.house.trimEnd();

    this.validateInput(event);
  }

  validateInput(event) {
    // Clear current state
    this.item.hasError = false;
    this.error = undefined;

    if (this.item.type === InfoItemType.ADDRESS || this.item.type === InfoItemType.FULL_ADDRESS) {
      this.isClearedOut.emit(this.item.city === '' && this.item.street === '');
    } else {
      this.isClearedOut.emit(!event.target.value || event.target.value === null || event.target.value === '');
    }

    if (this.isRequired() && (this.item.type !== InfoItemType.ADDRESS && this.item.type !== InfoItemType.FULL_ADDRESS)) {
      if (!event.target.value || event.target.value === null || event.target.value === '') {
        this.translate.get('validation.required').subscribe((translation) => {
          this.error = translation;
          this.item.hasError = true;
        });
      }
    }

    if (this.item.type === InfoItemType.EMAIL && !!event.target.value) {
      this.item.hasError = this.validateEmail(event);
    }

    if (InfoItemTypeCheck.isPhone(this.item.type) && event.target.value) {
      this.item.hasError = this.validatePhone(event);
    }

    if (this.item.type === InfoItemType.FAX && event.target.value) {
      this.item.hasError = this.validatePhone(event);
    }

    if (this.item.type === InfoItemType.ADDRESS || this.item.type === InfoItemType.FULL_ADDRESS && event.target.value) {
      this.item.hasError = this.validateAddress(this.item, event);
    }
  }

  isEmpty(value: any): boolean {
    return (value === '' || value === null || !value || value === 0);
  }

  validateAddress(aitem: InfoItem, event: any): boolean {
    if ((aitem as FullAddress).country !== 'DE') {
      this.error = undefined;

      return false;
    }

    // if one field is filled, the other fields required
    if (
      !this.isEmpty(this.item.street) ||
      !this.isEmpty(this.item.postcode) ||
      !this.isEmpty(this.item.city)) {
      this.translate.get('validation.required').subscribe((translation) => {
        this.isEmpty(this.item.postcode) ? this.errors.postcode = translation : this.errors.postcode = undefined;
        this.isEmpty(this.item.street) ? this.errors.street = translation : this.errors.street = undefined;
        this.isEmpty(this.item.city) ? this.errors.city = translation : this.errors.city = undefined;
      });
    } else {
      this.errors.postcode = undefined;
      this.errors.street = undefined;
      this.errors.city = undefined;
    }

    if (event.target.id === 'postalcode-input') {
      if (this.isValidGermanPostalCode(event.target.value)) {
        this.errors.postcode = undefined;
      } else {
        this.translate.get('validation.postalCodeValidation').subscribe((translation) => {
          this.errors.postcode = translation;
        });

        return true;
      }
    }

    if (event.target.id === 'house-input') {
      if (this.isValidHouseNumber(event.target.value)) {
        this.errors.house = undefined;
      } else {
        this.translate.get('validation.houseValidation').subscribe((translation) => {
          this.errors.house = translation;
        });

        return true;
      }
    }

    if (
      this.errors.postcode !== undefined ||
      this.errors.city !== undefined ||
      this.errors.street !== undefined) {
      return true;
    }

    return false;
  }

  validateEmail(event): boolean {
    if (event.target.value.length === 0) {
      return true;
    }

    if (this.isValidEmail(event.target.value)) {
      this.error = undefined;

      return false;
    }
    this.translate.get('validation.emailValidation').subscribe((translation) => {
      this.error = translation;
    });

    return true;

  }

  validatePhone(event: any): boolean {
    if (event.target.value.length === 0) {
      return true;
    }

    try {
      this.item.value = this.sanitizePhoneNumber(event.target.value);
    } catch (e) {
      this.translate.get('validation.phoneValidation').subscribe((translation) => {
        this.error = translation;
      });

      return true;
    }

    let phoneNumber = this.item.value;
    if (!phoneNumber || phoneNumber.length === 0) {
      return false;
    }

    try {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

      const processedNumber = this.replaceLeadingZero(phoneNumber);

      const parsedPhoneNumber = phoneUtil.parse(processedNumber);
      const parsedPhoneNumberDE = phoneUtil.parse(processedNumber, 'DE');

      if (!phoneUtil.isValidNumber(parsedPhoneNumber)) {
        this.translate.get('validation.phoneValidation').subscribe((translation) => {
          this.error = translation;
        });

        return true;
      }

      if (phoneNumber.includes('+49')) {
        if (!phoneUtil.isValidNumberForRegion(parsedPhoneNumberDE, 'DE')) {
          this.translate.get('validation.phoneValidation').subscribe((translation) => {
            this.error = translation;
          });

          return true;
        }
      }

      if (this.item.isMobile) {
        if (phoneUtil.getNumberType(parsedPhoneNumber) !== libphonenumber.PhoneNumberType.MOBILE) {
          this.translate.get('validation.mobileValidation').subscribe((translation) => {
            this.error = translation;
          });

          return true;
        }
      }

      /*
      Commenting this since it is causing some errors:
      When the input first load, if the mobile is not detected as mobile, the input is created as landline (error 1)
      If the user changes the value and now it is a mobile, this will validate it as a fixed line and will fail (error 2)

      if (!this.item.isMobile && this.item.type !== InfoItemType.FAX) {
        if (phoneUtil.getNumberType(parsedPhoneNumber) !== libphonenumber.PhoneNumberType.FIXED_LINE) {
          this.translate.get('validation.landPhoneValidation').subscribe((translation) => {
            this.error = translation;
          });
          return true;
        }
      }
      */

      if (this.item.type === InfoItemType.FAX) {
        if (phoneUtil.getNumberType(parsedPhoneNumber) !== libphonenumber.PhoneNumberType.FAX) {
          this.translate.get('validation.faxValidation').subscribe((translation) => {
            this.error = translation;
          });

          return true;
        }
      }

      this.error = undefined;

      return false;

    } catch (error) {
      this.translate.get('validation.phoneValidation').subscribe((translation) => {
        this.error = translation;
      });

      return true;
    }
  }

  onEnter($event: KeyboardEvent) {
    const inputs = document.getElementsByTagName('input');
    const array = Array.from(inputs);
    const index = array.indexOf($event.target as HTMLInputElement);
    if (index !== array.length - 1) {
      array[index + 1].focus();
    }
  }

  isValidEmail(email: string): boolean {
    // FIXME
    // This regex was stolen from @angular email validator by the reason, that forms are not using Angular Forms native way
    // MUST be fixed
    const emailRegex =
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    return emailRegex.test(String(email).toLowerCase());
  }

  isValidHouseNumber(house: string): boolean {
    const houseRegex = /^[a-zA-Z0-9 \/.-]*$/;
    if (!houseRegex.test(String(house))) {
      return false;
    }
    if (house.length > 35) {
      return false;
    }

    return true;
  }

  isValidGermanPostalCode(postalCode: string): boolean {
    if (!postalCode || postalCode.length === 0) {
      return;
    }
    if (postalCode.length !== 5) {
      return;
    }
    const postalCodeLeadRegionRegex = /\b[1-9][0-9][0-9][0-9][0-9]\b|\b[0-9][1-9][0-9][0-9][0-9]\b/gm;

    return (postalCodeLeadRegionRegex.test(postalCode));
  }

  sanitizePhoneNumber(phoneNumber: string): string {
    if (!phoneNumber || phoneNumber.length === 0) {
      return;
    }
    try {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

      return phoneUtil.format(phoneUtil.parse(this.replaceLeadingZero(phoneNumber)), libphonenumber.PhoneNumberFormat.INTERNATIONAL);
    } catch (error) {
      console.log(error);

      return phoneNumber;
    }
  }


  replaceLeadingZero(phoneNumber: string): string {
    if (phoneNumber.length <= 0 || phoneNumber == '') {
      return phoneNumber;
    }

    const germanCountryCode = '+49';
    const germanIsoCountryCode = '0049';

    if (phoneNumber[0] === '0' && phoneNumber.startsWith(germanIsoCountryCode)) {
      return phoneNumber.replace(germanIsoCountryCode, germanCountryCode);
    }

    if (phoneNumber[0] === '0' && !phoneNumber.startsWith('0049')) {
      return phoneNumber.replace('0', germanCountryCode);
    }

    return phoneNumber;
  }


  tooltipText(key: string): string {
    const translation_key = `custom.${key}`;
    let translation = this.translate.instant(translation_key);
    if (translation === undefined || translation === '' || translation === translation_key) {
      translation = this.translate.instant('labelTexts.required');
    }

    translation = translation.replace('<br>', '\u000d');

    return translation;
  }


  onDayInputKeyUp(event) {
    if (event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }

    const parsedDay = Number.parseInt(event.target.value.slice(0, 2), 10);

    if (!isNaN(parsedDay)) {
      this.dateComponents.day = event.target.value.slice(0, 2);
    } else {
      this.dateComponents.day = '';

      return;
    }

    if (this.dateComponents.day && this.dateComponents.day.length >= 2) {
      this.monthInput.nativeElement.focus();
    }

    if (event.key === 'Enter') {
      this.monthInput.nativeElement.focus();
    }
  }

  onDayInputChange(event) {
    if (event.target.value && event.target.value.length >= 2) {
      this.dateComponents.day = event.target.value.slice(0, 2);
    }
    this.item.dd = this.dateComponents.day;
    this.validateBirthday();
  }

  onDayInputBlur(event) {
    const parsedDay = Number.parseInt(event.target.value, 10);

    if (parsedDay < 10) {
      this.dateComponents.day = `0${parsedDay}`;
    }
  }

  onMonthInputKeyUp(event) {
    if (event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }

    const parsedMonth = Number.parseInt(event.target.value.slice(0, 2), 10);

    if (!isNaN(parsedMonth)) {
      this.dateComponents.month = event.target.value.slice(0, 2);
    } else {
      this.dateComponents.month = '';

      return;
    }

    if (this.dateComponents.month && this.dateComponents.month.length >= 2) {
      this.yearInput.nativeElement.focus();
    }

    if (event.key === 'Enter') {
      this.yearInput.nativeElement.focus();
    }
  }

  onMonthInputChange(event) {
    if (event.target.value && event.target.value.length >= 2) {
      this.dateComponents.month = event.target.value.slice(0, 2);
    }
    this.item.mm = this.dateComponents.month;
    this.validateBirthday();
  }

  onMonthInputBlur(event) {
    const parsedMonth = Number.parseInt(event.target.value, 10);

    if (parsedMonth < 10) {
      this.dateComponents.month = `0${parsedMonth}`;
    }
  }

  onYearInputKeyUp(event) {
    if (event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }

    const parsedYear = Number.parseInt(event.target.value.slice(0, 2), 10);

    if (!isNaN(parsedYear)) {
      this.dateComponents.year = event.target.value.slice(0, 4);
    } else {
      this.dateComponents.year = '';

      return;
    }
  }

  onYearInputChange(event) {
    if (event.target.value && event.target.value.length >= 2) {
      this.dateComponents.year = event.target.value.slice(0, 4);
    }
    this.item.yyyy = this.dateComponents.year;
    this.validateBirthday();
  }

  validateBirthday() {
    const birthdayError = this.translate.instant('validation.Birthday');
    if (!this.dateComponents.day && !this.dateComponents.month && !this.dateComponents.year) {
      this.error = '';
      this.item.hasError = false;
      this.isClearedOut.emit(true);

      return;
    }

    if (!this.dateComponents.day || !this.dateComponents.month || !this.dateComponents.year) {
      this.error = birthdayError;
      this.item.hasError = true;
      this.isClearedOut.emit(false);

      return;
    }

    const birthday = new Date(`${this.dateComponents.year  }-${this.dateComponents.month}-${this.dateComponents.day}`);
    if (birthday.toString() === 'Invalid Date') {
      this.error = birthdayError;
      this.item.hasError = true;
      this.isClearedOut.emit(false);

      return;
    }

    let day = birthday.getDate().toString();
    if (day.length === 1) {
      day = `0${day}`;
    }

    let dayCompare = this.dateComponents.day;
    if (dayCompare.length === 1) {
      dayCompare = `0${dayCompare}`;
    }

    let month = (birthday.getMonth() + 1).toString();
    if (month.length === 1) {
      month = `0${month}`;
    }

    let monthCompare = this.dateComponents.month;
    if (monthCompare.length === 1) {
      monthCompare = `0${monthCompare}`;
    }

    let year = birthday.getFullYear().toString();

    if (day != dayCompare || month != monthCompare || year != this.dateComponents.year) {
      this.error = birthdayError;
      this.item.hasError = true;
      this.isClearedOut.emit(false);
      console.log('invalid date:', day, month, year);
      console.log('invalid date: components', dayCompare, monthCompare, this.dateComponents.year);

      return;
    }

    const today = new Date();
    if (birthday > today) {
      this.error = birthdayError;
      this.item.hasError = true;
      this.isClearedOut.emit(false);

      return;
    }

    this.error = '';
    this.item.hasError = false;
    this.isClearedOut.emit(false);

    return;
  }
}
