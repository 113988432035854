import { ValidationErrors } from '@angular/forms';

import { Observable, startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IExternalValidationError } from '@c-form';

import { FieldControl } from '../directives/field-control.directive';
import { ERROR_MESSAGES } from '../components/form-field/error-messages.const';


export function validationErrorsToMessage(
  control: FieldControl,
  getFallbackErrors: () => IExternalValidationError[],
): Observable<string> {
  return control.statusChanges
    .pipe(
      startWith(null),
      map(() => control.ngControl.errors),
      filter((errors: ValidationErrors | null) => !!errors),
      map((errors: ValidationErrors | null) => {
        return Object.keys(errors || {})[0];
      }),
      map((error: string) => {
        let translationKey = ERROR_MESSAGES[error];

        if (!translationKey) {
          const fallbackErrors = getFallbackErrors();
          const fError = fallbackErrors
            .find((externalError: IExternalValidationError) => externalError.code === error);

          if (fError) {
            translationKey = fError.message;
          }
        }

        return translationKey ? translationKey : 'Unknown error';
      }),
    );
}
