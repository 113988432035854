<ng-container [formGroup]="form">
  <ng-container formArrayName="first_name">
    <form-field-wrapper>
      <form-field>
        <field-label>{{'labelTexts.firstName' | translate}}</field-label>

        <input fieldInput formControlName="0" name="first_name" class="form-control c-input">
      </form-field>
    </form-field-wrapper>
  </ng-container>

  <ng-container formArrayName="last_name">
    <form-field-wrapper>
      <form-field>
        <field-label>{{'labelTexts.lastName' | translate}}</field-label>

        <input fieldInput formControlName="0" name="last_name" class="form-control c-input">
      </form-field>
    </form-field-wrapper>
  </ng-container>
</ng-container>
