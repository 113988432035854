import {
  Component,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { BaseCodeInputComponent } from '../base/base-code-input.component';
import { ICodeInputConfig } from '../base';


@Component({
  selector: 'app-birthday-verification',
  templateUrl: './birthday-verification.component.html',
  styleUrls: [
    '../base/base-code-input.component.scss',
    './birthday-verification.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthdayVerificationComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    TranslateModule,
  ],
})
export class BirthdayVerificationComponent extends BaseCodeInputComponent
  implements OnChanges, ControlValueAccessor {

  public items: ICodeInputConfig[] = [
    {
      labelLangKey: 'birthday.day',
      inputLangKey: 'birthday.dayFormat',
      maxLength: 2,
      name: 'b-day',
    },
    {
      labelLangKey: 'birthday.month',
      inputLangKey: 'birthday.monthFormat',
      maxLength: 2,
      name: 'b-month',
    },
    {
      labelLangKey: 'birthday.year',
      inputLangKey: 'birthday.yearFormat',
      maxLength: 4,
      name: 'b-year',
    },
  ];

  private _model: unknown[] = [];
  // eslint-disable-next-line
  private _onChange: (v) => {};
  // eslint-disable-next-line
  private _onTouched: () => {};

  public get model() {
    return this._model;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.inps?.currentValue !== changes.inps?.previousValue) {
      const emptyArr = new Array(this.items.length);
      emptyArr.fill(null);

      this._model = emptyArr;
    }
  }

  public writeValue(value: unknown[]): void {
    if (Array.isArray(value)) {
      this._model = [...value];
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public changed(): void {
    this._onChange(this.model);
  }

}
