export enum RouteString {

  TERMS = 'terms',
  NOT_FOUND = 'not-found',
  ERROR = 'error',
  /* ----------------------------------------
   * Require token, setup object, listings in sequence
   * -------------------------------------- */
  ANSWERING = 'answering',
  NEW_ANSWERING = 'new-answering',
  PINCODE = 'pincode',
  DELIVERY_NOTICE = 'delivery-notice',
  CONFIRMATION = 'confirmation',
  DONE = 'done',
  SHARING = 'sharing',
  PREFERENCE = 'preference',
  SMS_AUTH = 'sms-authentication',
  DOB_AUTH = 'dob-authentication',
  POSTCODE_AUTH = 'postcode-authentication',
  /* ----------------------------------------
   * Require token and setup object
   * -------------------------------------- */
  SETTINGS = 'settings',
  DELETE = 'delete',
  DOWNLOAD = 'download',
}
