export const environment = {
  name: 'qa1',
  production: false,
  shortlinkUrl: 'https://qa1.complero.info/s/',
  hostname: 'https://dashboard.qa1.complero.info',
  textsFolder: '/assets/texts/',
  sentryDSM: 'https://b2cb47cb045f46a48aa0fb59dfdf9925@glitchtip.infra.complero.info/1',
  version: 'QA1.24.01.1',
  sentryPopup: false,
  matomoSiteID: 2,
  shortRequestTimeout: 7000,
  longRequestTimeout: 10000,
};
