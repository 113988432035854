<div id="validationError" *ngIf="validationError">
  <div class="alert alert-danger alert-dismissible fade show" role="alert">
    {{ validationError | translate }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeValidationError()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="c-app-content">
  <app-app-loader *ngIf="showLoader"></app-app-loader>

  <app-topbar class="custom-topbar" *ngIf="storeService?.setup?.showTopbar"
    [lineOne]="'topbar.deliveryNote' | translate"
    [lineTwo]="lineTwo"></app-topbar>

  <div class="c-main">
    <div *ngIf="!isEditingAddressMode" class="c-address-block">
      <div class="c-left">
        <div class="c-address-title">
          Bisher hinterlegte Anschrift
        </div>
        <div *ngIf="foundAddress">
          {{(address.street + ' ' + address.house).trim()}}<br>
          {{(address.postcode + ' ' + address.city).trim()}}<br>
          {{this.countryName}}
        </div>

      </div>
      <div class="c-right">
        <div (click)="onEditClick()" [appIcon]="'edit'" class="c-edit-icon c-avatar-edit-btn"></div>
      </div>
    </div>

    <app-info-input *ngIf="isEditingAddressMode" [pageType]="pageType" [item]="editingAddress" class="c-info-item"
      style="margin-right: 20px"></app-info-input>

    <div class="c-separator"></div>

    <div class="c-details">
      <div style="font-weight: 500;margin-bottom: 16px;">Ist Ihre Adresse schwer zu finden?</div>
      <div style="font-size: 14px">Bitte hinterlassen Sie uns einen Zustellhinweis damit wir Briefe an Sie zukünftig
        problemlos zustellen können.</div>
    </div>

    <div class="c-textarea-container">
      <label for="textarea-1" style="margin-bottom: 5px !important;"><span [appIcon]="'place'" [size]="25"
          class="c-icon-cont"></span>Zustellhinweis</label>
      <textarea [(ngModel)]="deliveryNote" class="form-control custom-input" id="textarea-1"
        style="height: auto !important; min-height: auto !important;" rows="2" maxlength="{{textareaMaxLength}}"
        placeholder="max. 30 Zeichen, z.B. Hinterhaus"></textarea>
      <div class="c-textarea-counter">{{deliveryNote.length + '&nbsp;/&nbsp;' + textareaMaxLength}}</div>
    </div>

  </div>
  <div class="c-footer">
    <button (click)="onSendClick()" class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary custom-send-button"
      type="button">
      <ng-container>Speichern</ng-container>
    </button>

  </div>
</div>
