<div class="c-app-content">
  <app-app-loader *ngIf="showLoader"></app-app-loader>
  <div class="c-container">

    <div *ngIf="!expiredLink">
      <div class="c-error-dialog">
        <b>{{"error.title" | translate}}</b>
        <p class="c-description">{{"error.description" | translate}}</p>
        <br>
        <p>
          Adressaktualisierungen können Sie direkt über unser Portal vornehmen:<br>
          <a href="https://www.complero.com/meine-kundendaten/">www.complero.com/meine-kundendaten</a>
          <br>
          <br>
          Für Rückfragen wenden Sie sich gerne an uns:<br>
          <a href="https://www.complero.com/kontakt/">www.complero.com/kontakt</a>
          <br>
          Ihr Complero-Team
        </p>
      </div>
      <br>
      <br>
      <p>{{"error.buttonHelpText" | translate}}</p>
      <div class="c-button-container">
        <button class="btn btn-primary custom-btn-primary" href="https://www.complero.com"
          (click)="onLearnMoreButtonClick()">
          {{"error.buttonTitle" | translate}}
        </button>
      </div>
    </div>

    <div *ngIf="expiredLink">
      <div class="logo">
        <img src="{{storeService?.sender?.person?.imageUrl}}">
      </div>
      <div class="c-error-dialog">
        <b>{{"expired.title" | translate}}</b>
        <p class="c-description">{{"expired.description" | translate}}</p>
      </div>
      <br>
      <br>
      <p>{{"expired.buttonHelpText" | translate}}</p>
      <div class="c-button-container">
        <button class="btn btn-primary custom-btn-primary" href="https://www.complero.com"
          (click)="onLearnMoreButtonClick()">
          {{"expired.buttonTitle" | translate}}
        </button>
      </div>
    </div>

    <div class="c-text-container">
      <p>
        <span>Complero © 2022 Complero GmbH</span><br>
        <span>Im Wiesengrund 49</span><br>
        <span>53539 Kelberg</span><br>
        <br>
        <span><a href="tel:02692-2689886">02692-2689886</a></span><br>
        <span><a href="mailto:service@complero.com">service@complero.com</a></span>
      </p>
    </div>
  </div>
</div>
