import { InfoItemType } from '../enums/info-item.type';

import { InfoItem } from './Info-item';


export class Group {

  readonly itemType: InfoItemType;
  items: InfoItem[];


  constructor(data: Group) {
    Object.assign(this, data);
  }


}

