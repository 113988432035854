<div id="preferences" class="c-category">
  <div (click)="isActive=!setup.features[pageType][featureType.PreferenceToggle]||!isActive"
    class="c-category-header custom-light-panel">
    <div [appIcon]="'preferences'" [size]="24" class="c-category-icon custom-category-icons"></div>
    <div class="c-category-title custom-category-title">{{'preference.title'| translate}}</div>
    <ng-container *ngIf="setup.features[pageType][featureType.PreferenceToggle]">
      <div [appIcon]="'arrow_down'" [size]="24" [hidden]="isActive" class="c-category-arrow custom-category-icons">
      </div>
      <div [appIcon]="'arrow_up'" [size]="24" [hidden]="!isActive" class="c-category-arrow custom-category-icons"></div>
    </ng-container>
  </div>
  <div [@openClose]="isActive ? 'open' : 'closed'" class="c-category-content">
    <div class="c-container custom-category-container">

      <app-legal-element
        *ngIf="setup.features[pageType][featureType.LegalHtml] && storeService.customLegal && legalVisible$ | async">
      </app-legal-element>
      <!--
      <div class="c-pref-intro" *ngIf="setup.features[pageType][featureType.PreferenceMessage]">
        {{(storeService.isScrollToPref ? 'preference.messages.scrolled' : 'preference.messages.basic') | translate}}</div>
      -->
      <ng-container *ngFor="let consent of storeService.preferences">
        <ng-container *ngIf="setup.features[pageType][featureType.ShowDefaultConsents]&&consent.type===consentType.Default||
setup.features[pageType][featureType.ShowReoConsents]&&consent.type===consentType.Reo">
          <div [ngClass]="{'c-colored': setup.features[pageType][featureType.PreferenceColored]}"
            class="c-item c-item-no-bottom">
            <div class="c-icon"></div>
            <div class="c-entry">
              <div class="c-description">
                <div *ngIf="consent.iconSvg" class="c-consent-icon" [innerHTML]="consent.iconSvg | safe"></div>
                <div class="c-title">{{consent.title}}</div>
              </div>
            </div>
          </div>
          <div [ngClass]="{'c-colored': setup.features[pageType][featureType.PreferenceColored]}"
            class="c-item c-item-no-top">
            <div class="c-icon">
              <p-checkbox *ngIf="!setup.features[pageType][featureType.CheckboxInlinePreferenceText]"
                (change)="consent.value=!consent.value" [checked]="consent.value" shape="curve" animation="pulse"
                color="primary" enableFocus="true" class="custom-checkbox">
                <svg p-svg style="transform: scale(0.7);" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" aria-hidden="true">
                  <g fill="currentColor">
                    <g>
                      <path
                        d="M22.4 5.05c-.3-.3-.75-.3-1.05 0L9 17.4l-5.9-5.9c-.3-.3-.75-.3-1.05 0s-.3.75 0 1.05L9 19.5 22.4 6.1c.3-.3.3-.75 0-1.05z"
                        fill-rule="evenodd"></path>
                    </g>
                  </g>
                </svg>
              </p-checkbox>
            </div>
            <div class="c-entry">
              <div class="c-description">
                <div *ngIf="setup.features[pageType][featureType.CheckboxInlinePreferenceText]"
                  class="c-inline-checkbox-container">
                  <p-checkbox (change)="consent.value=!consent.value" [checked]="consent.value" shape="curve"
                    animation="pulse" color="primary" enableFocus="true" class="c-inline-checkbox custom-checkbox">
                    <svg p-svg style="transform: scale(0.7);" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                      viewBox="0 0 24 24" aria-hidden="true">
                      <g fill="currentColor">
                        <g>
                          <path
                            d="M22.4 5.05c-.3-.3-.75-.3-1.05 0L9 17.4l-5.9-5.9c-.3-.3-.75-.3-1.05 0s-.3.75 0 1.05L9 19.5 22.4 6.1c.3-.3.3-.75 0-1.05z"
                            fill-rule="evenodd"></path>
                        </g>
                      </g>
                    </svg>
                  </p-checkbox>
                </div>
                <div (load)="onConsentLoaded(consent)" class="c-subtitle custom-subtitle"
                  [innerHTML]="consent.html | safe"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="c-legal-content" *ngIf="(legalFinePrint$ | async) as finePrint">
        <div [innerHTML]="finePrint" class="c-legal-inner-content c-html"></div>
      </div>
    </div>
  </div>
</div>
