import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { PincodeComponent } from './pincode/pincode.component';


@NgModule({
  declarations: [PincodeComponent],
  imports: [
    SharedModule,
  ],
})
export class PincodeModule { }
