import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';

import { IFormField } from '@c-fields/common';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormFieldWrapperComponent } from '../../../form-field';


@Component({
  selector: 'app-form-item',
  templateUrl: './form-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormItemComponent implements AfterViewInit, OnDestroy {

  @Input()
  public field: IFormField;

  @Input()
  public form: FormGroup;

  @Input()
  public index: number;

  @ViewChild(FormFieldWrapperComponent, { read: ElementRef })
  private _formField: ElementRef;

  private _focusMonitor: FocusMonitor = inject(FocusMonitor);
  private _destroy$ = new Subject<void>();

  public ngAfterViewInit(): void {
    this._listenValueChanges();
    this._monitorFormFieldBlur();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._focusMonitor.stopMonitoring(this._formField);
  }

  private _listenValueChanges(): void {
    this.field.formArray.at(this.index)
      .valueChanges
      .subscribe(() => {
        this.field.addEmptyFormControlIfNeeded();
      });
  }

  private _monitorFormFieldBlur(): void {
    if (this._formField) {
      this._focusMonitor.monitor(this._formField, true)
        .pipe(
          takeUntil(this._destroy$),
        )
        .subscribe((origin: FocusOrigin) => {
          if (!origin) {
            this.field.removeEmptyFormControls();
            this.field.addEmptyFormControlIfNeeded();
          }
        });
    }
  }

}
