<div class="c-list">
  <app-info-item *ngFor="let item of displayedItems" [displayCategoryLabel]="true" [item]="item" [pageType]="pageType"
    [showMenu]="false"></app-info-item>
  <div [@openClose]="isOpen ? 'open' : 'closed'" class="c-collapsible">
    <app-info-item *ngFor="let item of collapsedItems" [displayCategoryLabel]="true" [item]="item"
      [pageType]="pageType"></app-info-item>
  </div>
  <div *ngIf="collapsedItems.length" [@showHide]="isOpen ? 'show' : 'hide'" class="c-mask"></div>
</div>
<button (click)="onViewDetailsClick()" *ngIf="collapsedItems.length" class="btn btn-link c-no-shadow">
  <span [hidden]="isOpen">{{'sender.buttons.showDetails' | translate}}</span>
  <span [hidden]="!isOpen">{{'sender.buttons.hideDetails' | translate}}</span>
</button>