<div class="c-app-content">
  <div class="c-main">
    <div class="c-content">
      <div class="c-img-container">
        <div class="c-img">
          <img src="/assets/img/standard/success.svg" width="34">
        </div>
      </div>
      <div class="c-main-title">
        {{ 'confirmation.thanks' | translate }}<br>
        {{ 'confirmation.success' | translate }}
      </div>
      <div class="c-main-subtitle"></div>
    </div>
  </div>
</div>
