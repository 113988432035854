import {
  Directive,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';

import {
  isBackspaceKey,
  isDeleteKey,
  isEnterKey,
  isNumericKey,
  isModifierKeyPressed,
  isTabKeyPressed,
} from '@common/helpers/keyboard';

import { ICodeInputConfig } from './code-input-config.interface';
import { isEmptyValue } from './is-empty-value';


@Directive()
export abstract class BaseCodeInputComponent {

  @Input()
  public items: ICodeInputConfig[] = [];

  @ViewChildren('input', { read: ElementRef })
  private _inputs: QueryList<ElementRef>;

  public onInput(e: any, index: number): void {
    const target = e.target;
    const value = e.data || target.value;

    if (isEmptyValue(value)) {
      return;
    }

    if (this._getInputByIndex(index).value.length >= this.items[index].maxLength) {
      this._focusElement(index + 1);
    }
  }

  // eslint-disable-next-line
  public onKeydown(e: any, index: number): void {
    const target = e.target;
    const value = e.data || target.value;
    const isEmpty = isEmptyValue(value);

    const backSpacePressed = isBackspaceKey(e);
    const deletePressed = isDeleteKey(e);
    const enterPressed = isEnterKey(e);

    if (!backSpacePressed && !deletePressed && !enterPressed) {
      if (!isNumericKey(e) && !isModifierKeyPressed(e) && !isTabKeyPressed(e)) {
        let validValue = true;

        if (this.items[index].regex) {
          validValue = e.key.match(this.items[index].regex);
        } else if (this.items[index].regex !== null) {
          validValue = e.key.match(/^[0-9]+$/);
        }

        if (!validValue) {
          e.preventDefault();
          e.stopPropagation();

          return;
        }
      }

      return;
    }

    if (backSpacePressed && isEmpty) {
      e.preventDefault();
      this._focusElement(index - 1);
    }

    if (enterPressed) {
      this._focusElement(index + 1);
    }
  }

  public focusFirst(): void {
    const elem = this._inputs.get(0).nativeElement;
    elem.focus();
    elem.select();
  }

  private _focusElement(index: number): void {
    if (index >= 0 && index < this._inputs.length) {
      const elem = this._inputs.get(index).nativeElement;
      elem.focus();
      elem.select();
    }
  }

  private _getInputByIndex(index: number): HTMLInputElement {
    return this._inputs.get(index).nativeElement;
  }

}
