import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { StoreService } from '../services/store.service';


@Injectable({
  providedIn: 'root',
})
export class NotAuthenticatedGuard  {
  constructor(private storeService: StoreService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.storeService.code;
  }

}
