import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from '../../../core/services/data.service';
import { StoreService } from '../../../core/services/store.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { PageType } from '../../../common/enums/page.type';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {

  /* ----------------------------------------
   * Life-cycles
   * -------------------------------------- */
  // private onDestroySubject = new Subject();
  // onDestroy$ = this.onDestroySubject.asObservable();

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private storeService: StoreService,
    private appNavService: AppNavService,
  ) {

  }

  ngOnInit() {

    const scrollParam = this.activatedRoute.snapshot.queryParams.scroll;

    if (scrollParam === 'pref') {
      if (this.storeService.setup.sequence.includes(PageType.Preference)) {
        this.storeService.isScrollToPref = true;
        this.appNavService.goToPreferencePage();
      }
    }

    if (
      this.storeService.sender &&
      this.storeService.sender.person &&
      (this.storeService.sender.person.name.firstName || this.storeService.sender.person.name.lastName)) {

      const titleElements = Array.from(document.getElementsByTagName('title'));

      for (const titleElement of titleElements) {
        titleElement.innerText = `${`${this.storeService.sender.person.company}`.trim()} - Complero`;
      }
    }
  }

}
