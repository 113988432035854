<ng-container *ngIf="setup">
  <div class="c-avatar-component custom-avatar-component">
    <div (click)="onEditAvatarClick()"
      *ngIf="setup.fields[pageType][fieldType.Avatar] && !isAvatarEditMode&&(person.imageUrl||storeService.newAvatarBase64)&&!displayOnly"
      [appIcon]="'edit'" class="ml-2 c-edit-icon c-avatar-edit-btn">
    </div>

    <div *ngIf="setup.fields[pageType][fieldType.Avatar]" class="c-avatar custom-avatar">
      <img
        *ngIf="(((storeService.newAvatarBase64&&storeService.newAvatarBase64!=='')||(person.imageUrl&&person.imageUrl!==''))&&!isAvatarEditMode)||avatarPreview"
        [src]="isAvatarEditMode ? avatarPreview : (storeService.newAvatarBase64 || person.imageUrl)">
      <div (click)="onEditAvatarClick()"
        *ngIf="!storeService.newAvatarBase64&&!(person.imageUrl&&person.imageUrl!=='')&&!isAvatarEditMode"
        class="c-avatar-upload-info">{{'receiver.avatar.hint' | translate}}
      </div>
      <div *ngIf="!avatarPreview&&isAvatarEditMode" [appIcon]="'photo_placeholder'" [size]="36"
        class="c-avatar-placeholder"></div>
    </div>

    <div class="c-name" [style.width]="!setup.fields[pageType][fieldType.Avatar] ? '100% !important' : undefined">
      <div *ngIf="!highlightMandatoryFields && (!isNameEditMode||displayOnly)" class="c-name-display">
        <div class="c-name-value">
          <div>
            <div *ngIf="setup.features[pageType][featureType.InlinePersonInfo]"
              class="c-person-inline custom-person-inline">
              <div class="c-img-container">
                <ng-container *ngIf="setup.company !== 'default' else default">
                  <img src="/assets/img/{{setup.company}}/user.svg" width="16" style="margin-top: -1px">
                </ng-container>
                <ng-template #default>
                  <svg width="16px" height="17px" viewBox="0 0 16 17">
                    <g transform="translate(-20.000000, -235.000000)">
                      <g transform="translate(20.000000, 232.000000)">
                        <path d="M3.55555556,7.44444444 C3.55555556,9.89511108 5.54933336,11.8888889 8,11.8888889 C10.4506667,11.8888889 12.4444444,9.89511108 12.4444444,7.44444444 C12.4444444,4.9937778 10.4506667,3 8,3 C5.54933336,3 3.55555556,4.9937778 3.55555556,7.44444444 Z M5.33333333,7.44444444 C5.33333333,5.97422219 6.52977778,4.77777778 8,4.77777778 C9.47022222,4.77777778 10.6666667,5.97422219 10.6666667,7.44444444 C10.6666667,8.9146667 9.47022222,10.1111111 8,10.1111111 C6.52977778,10.1111111 5.33333333,8.9146667 5.33333333,7.44444444 Z M16,19 C16,15.5697778 13.208,12.7777778 9.77777778,12.7777778 L6.22222222,12.7777778 C2.7911112,12.7777778 0,15.5697778 0,19 L0,19.8888889 L1.77777778,19.8888889 L1.77777778,19 C1.77777778,16.5493333 3.77155558,14.5555556 6.22222222,14.5555556 L9.77777778,14.5555556 C12.2284444,14.5555556 14.2222222,16.5493333 14.2222222,19 L14.2222222,19.8888889 L16,19.8888889 L16,19 Z"></path>
                      </g>
                    </g>
                  </svg>
                </ng-template>
              </div>
              <span *ngIf="setup.fields[pageType][fieldType.FirstName]" data-cy="first-name-label">
                {{ person.name.firstName }}&nbsp;
              </span>
              <span *ngIf="setup.fields[pageType][fieldType.LastName]" data-cy="last-name-label">
                {{ person.name.lastName }}
              </span>
            </div>
            <ng-container *ngIf=" !setup.features[pageType][featureType.InlinePersonInfo]">
              <ng-container *ngIf="setup.fields[pageType][fieldType.Salutation]
            && person.salutation
            && person.salutation!==''
            && person.salutation.toLowerCase()!=='not specified'
            && person.salutation.toLowerCase()!=='keine angabe'
            ">{{person.salutation}} </ng-container>
              <ng-container *ngIf="setup.fields[pageType][fieldType.Title]
            &&person.title!==''">{{person.title}} </ng-container>
              <ng-container *ngIf="setup.fields[pageType][fieldType.FirstName]">{{person.name.firstName }}
              </ng-container>
              <ng-container *ngIf="setup.fields[pageType][fieldType.LastName]">{{person.name.lastName }} </ng-container>
              <ng-container *ngIf="setup.fields[pageType][fieldType.NameSuffix]">{{person.nameSuffix }} </ng-container>

              <div *ngIf="setup.fields[pageType][fieldType.Position]" style="font-size: 15px;">{{person.position}}</div>
              <div *ngIf="setup.fields[pageType][fieldType.Company]" style="font-size: 15px;">{{person.company}}</div>
              <div *ngIf="setup.fields[pageType][fieldType.Website]" style="font-size: 15px;">{{person.website}}</div>
            </ng-container>
          </div>

        </div>
        <div
          *ngIf="!displayOnly && (!setup.readOnly[pageType][fieldType.Title] || !setup.readOnly[pageType][fieldType.Salutation] || !setup.readOnly[pageType][fieldType.FirstName] || !setup.readOnly[pageType][fieldType.LastName])"
          (click)="isNameEditMode=!isNameEditMode" [appIcon]="'edit'" class="ml-2 c-edit-icon" id="openPersonal" data-cy="edit-personal"></div>
        <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}" nzTooltipPlacement="left" nz-tooltip
          *ngIf="!displayOnly && (setup.readOnly[pageType][fieldType.Title] && setup.readOnly[pageType][fieldType.Salutation] && setup.readOnly[pageType][fieldType.FirstName] && setup.readOnly[pageType][fieldType.LastName])"
          [appIcon]="'info'" [size]="20" class="ml-2 c-edit-icon"></div>
      </div>

      <div *ngIf="highlightMandatoryFields || (isNameEditMode&&!displayOnly)" class="c-name-input">

        <div *ngIf="setup.fields[pageType][fieldType.Salutation]" class="form-group c-form-group">
          <label class="c-label custom-label">
            {{'labelTexts.salutation-alt'|translate}}
            <span *ngIf="setup.required[pageType][fieldType.Salutation]" class="required-star">*</span>
          </label>
          <div *ngIf="salutationTranslations" class="c-autocomplete d-flex align-items-center">
            <nz-select autocomplete="off" name="salutation" nzShowSearch [(ngModel)]="person.salutation"
              [disabled]="setup.readOnly[pageType][fieldType.Salutation] ? true : null"
              class="form-control c-form-control custom-input c-select"
              nzPlaceHolder="{{'receiver.placeholders.salutation-alt'|translate}}">
              <nz-option *ngFor="let option of salutationTranslations" [nzValue]="option"
                [nzLabel]="option | translate"></nz-option>
            </nz-select>
            <div [nzTooltipTitle]="'labelTexts.readOnly' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="setup.readOnly[pageType][fieldType.Salutation]" [appIcon]="'info'" class="c-info-icon">
            </div>
            <div [nzTooltipTitle]="'labelTexts.required' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="!setup.readOnly[pageType][fieldType.Salutation] && setup.required[pageType][fieldType.Salutation]"
                 [appIcon]="'info'" class="ml-2 c-edit-icon">
            </div>
          </div>
        </div>

        <div *ngIf="setup.fields[pageType][fieldType.Title]" class="form-group c-form-group">
          <label class="c-label custom-label">
            {{'labelTexts.title'|translate}}
            <span *ngIf="setup.required[pageType][fieldType.Title]" class="required-star">*</span>
          </label>
          <div *ngIf="titleTranslations" class="c-autocomplete d-flex align-items-center">
            <nz-select autocomplete="off" name="title" nzShowSearch [(ngModel)]="person.title"
              [disabled]="setup.readOnly[pageType][fieldType.Title] ? true : null"
              class="form-control c-form-control custom-input c-select"
              nzPlaceHolder="{{'receiver.placeholders.title'|translate}}">
              <nz-option *ngFor="let option of titleTranslations" [nzValue]="option" [nzLabel]="option | translate">
              </nz-option>
            </nz-select>
            <div [nzTooltipTitle]="'labelTexts.readOnly' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="setup.readOnly[pageType][fieldType.Title]" [appIcon]="'info'" class="c-info-icon">
            </div>
            <div [nzTooltipTitle]="'labelTexts.required' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="!setup.readOnly[pageType][fieldType.Title] && setup.required[pageType][fieldType.Title]"
                 [appIcon]="'info'" class="ml-2 c-edit-icon">
            </div>
          </div>
        </div>

        <div *ngIf="setup.fields[pageType][fieldType.FirstName]" class="form-group c-form-group">
          <label class="mandatory-field c-label custom-label" data-cy="first-name-field-label">
            {{'labelTexts.firstName'|translate}}
            <span *ngIf="setup.required[pageType][fieldType.FirstName]" class="required-star">*</span>
          </label>
          <div class="d-flex align-items-center">
            <input [(ngModel)]="person.name.firstName" id="firstName"
              [disabled]="setup.readOnly[pageType][fieldType.FirstName] ? true : null"
              class="form-control custom-input {{highlightMandatoryFields && !person.name.firstName ? 'input-with-error' : ''}}"
              data-cy="first-name-field"
              placeholder="{{'receiver.placeholders.firstName' | translate}}" type="text">
            <div [nzTooltipTitle]="'labelTexts.readOnly' | translate" nzTooltipPlacement="left" nz-tooltip
                 data-cy="first-name-field-readonly"
                 *ngIf="setup.readOnly[pageType][fieldType.FirstName]" [appIcon]="'info'" class="c-info-icon">
            </div>
            <div [nzTooltipTitle]="'labelTexts.required' | translate" nzTooltipPlacement="left" nz-tooltip
                 data-cy="first-name-field-required"
                 *ngIf="!setup.readOnly[pageType][fieldType.FirstName] && setup.required[pageType][fieldType.FirstName]"
                 [appIcon]="'info'" class="ml-2 c-edit-icon">
            </div>
          </div>
          <p class="error-label" *ngIf="highlightMandatoryFields && !person.name.firstName">
            {{'validation.notEmptyValidation'|translate}}
          </p>
        </div>
        <div *ngIf="setup.fields[pageType][fieldType.LastName]" class="form-group c-form-group">
          <label class="mandatory-field c-label custom-label">
            {{'labelTexts.lastName'|translate}}
            <span *ngIf="setup.required[pageType][fieldType.LastName]" class="required-star">*</span>
          </label>
          <div class="d-flex align-items-center">
            <input [(ngModel)]="person.name.lastName"
              [disabled]="setup.readOnly[pageType][fieldType.LastName] ? true : null"
              class="form-control custom-input {{highlightMandatoryFields && !person.name.lastName ? 'input-with-error' : ''}}"
              placeholder="{{'receiver.placeholders.lastName' | translate}}" type="text" id="lastName">
            <div [nzTooltipTitle]="'labelTexts.readOnly' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="setup.readOnly[pageType][fieldType.LastName]" [appIcon]="'info'" class="c-info-icon">
            </div>
            <div [nzTooltipTitle]="'labelTexts.required' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="!setup.readOnly[pageType][fieldType.LastName] && setup.required[pageType][fieldType.LastName]"
                 [appIcon]="'info'" class="ml-2 c-edit-icon">
            </div>
          </div>
          <p class="error-label" *ngIf="highlightMandatoryFields && !person.name.lastName">
            {{'validation.notEmptyValidation'|translate}}
          </p>
        </div>
        <div *ngIf="setup.fields[pageType][fieldType.NameSuffix]" class="form-group c-form-group">
          <label class="c-label custom-label">
            {{'labelTexts.nameSuffix'|translate}}
            <span *ngIf="setup.required[pageType][fieldType.NameSuffix]" class="required-star">*</span>
          </label>
          <div class="d-flex align-items-center">
            <input [(ngModel)]="person.nameSuffix"
              [disabled]="setup.readOnly[pageType][fieldType.NameSuffix] ? true : null"
              class="form-control custom-input" placeholder="{{'receiver.placeholders.nameSuffix' | translate}}"
              type="text">
            <div [nzTooltipTitle]="'labelTexts.readOnly' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="setup.readOnly[pageType][fieldType.NameSuffix]" [appIcon]="'info'" class="c-info-icon">
            </div>
            <div [nzTooltipTitle]="'labelTexts.required' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="!setup.readOnly[pageType][fieldType.NameSuffix] && setup.required[pageType][fieldType.NameSuffix]"
                 [appIcon]="'info'" class="ml-2 c-edit-icon">
            </div>
          </div>
        </div>
        <div *ngIf="setup.fields[pageType][fieldType.Position]" class="form-group c-form-group">
          <label class="c-label custom-label">
            {{'labelTexts.position-alt'|translate}}
            <span *ngIf="setup.required[pageType][fieldType.Position]" class="required-star">*</span>
          </label>
          <div class="d-flex align-items-center">
            <input [(ngModel)]="person.position" [disabled]="setup.readOnly[pageType][fieldType.Position] ? true : null"
                   class="form-control custom-input" placeholder="{{'receiver.placeholders.position-alt' | translate}}"
                   type="text">
            <div [nzTooltipTitle]="'labelTexts.readOnly' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="setup.readOnly[pageType][fieldType.Position]" [appIcon]="'info'" class="c-info-icon">
            </div>
            <div [nzTooltipTitle]="'labelTexts.required' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="!setup.readOnly[pageType][fieldType.Position] && setup.required[pageType][fieldType.Position]"
                 [appIcon]="'info'" class="ml-2 c-edit-icon">
            </div>
          </div>
        </div>
        <div *ngIf="setup.fields[pageType][fieldType.Company]" class="form-group c-form-group">
          <label class="c-label custom-label">
            {{'labelTexts.company-alt'|translate}}
            <span *ngIf="setup.required[pageType][fieldType.Company]" class="required-star">*</span>
          </label>
          <div class="d-flex align-items-center">
            <input [(ngModel)]="person.company" [disabled]="setup.readOnly[pageType][fieldType.Company] ? true : null"
                   class="form-control custom-input" placeholder="{{'receiver.placeholders.company-alt' | translate}}"
                   type="text">
            <div [nzTooltipTitle]="'labelTexts.readOnly' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="setup.readOnly[pageType][fieldType.Company]" [appIcon]="'info'" class="c-info-icon">
            </div>
            <div [nzTooltipTitle]="'labelTexts.required' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="!setup.readOnly[pageType][fieldType.Company] && setup.required[pageType][fieldType.Company]"
                 [appIcon]="'info'" class="ml-2 c-edit-icon">
            </div>
          </div>
        </div>
        <div *ngIf="setup.fields[pageType][fieldType.Website]" class="form-group c-form-group">
          <label class="c-label custom-label">
            {{'labelTexts.website-alt'|translate}}
            <span *ngIf="setup.required[pageType][fieldType.Website]" class="required-star">*</span>
          </label>
          <div class="d-flex align-items-center">
            <input [(ngModel)]="person.website" [disabled]="setup.readOnly[pageType][fieldType.Website] ? true : null"
              class="form-control custom-input" placeholder="{{'receiver.placeholders.website-alt' | translate}}"
              type="text">
            <div [nzTooltipTitle]="'labelTexts.readOnly' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="setup.readOnly[pageType][fieldType.Website]" [appIcon]="'info'" class="c-info-icon">
            </div>
            <div [nzTooltipTitle]="'labelTexts.required' | translate" nzTooltipPlacement="left" nz-tooltip
                 *ngIf="!setup.readOnly[pageType][fieldType.Website] && setup.required[pageType][fieldType.Website]"
                 [appIcon]="'info'" class="ml-2 c-edit-icon">
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="label" class="c-label custom-label">{{label}}</div>

    </div>
  </div>
  <app-avatar-edit (base64)="avatarPreview=$event;storeService.newAvatarBase64=$event;"
    (done)="onAvatarEditingIsDone($event)" *ngIf="isAvatarEditMode"></app-avatar-edit>

</ng-container>
