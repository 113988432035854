import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { StoreService } from '../services/store.service';
import { AppNavService } from '../services/app-nav.service';


@Injectable({
  providedIn: 'root',
})
export class SmsAuthGuardGuard  {

  constructor(private storeService: StoreService, private appNavService: AppNavService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      this.storeService.setup$.pipe(take(1)).subscribe((setup) => {

        if (setup.flow !== 'flow_2fa_a_compact') {
          resolve(true);

          return;
        }

        if (!!this.storeService.code) {
          resolve(true);
        } else {
          this.appNavService.goToFirstPageInSequence();
          resolve(false);
        }
      });
    });
  }

}
