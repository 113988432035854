import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ImageCroppedEvent } from 'ngx-image-cropper';


@Component({
  selector: 'app-avatar-edit',
  templateUrl: './avatar-edit.component.html',
  styleUrls: ['./avatar-edit.component.scss'],
  animations: [
    trigger('openClose', [
      state('in', style({ height: '*' })),
      transition(':enter', [
        style({ height: '0px' }),
        animate('0.5s'),
      ]),
      transition(':leave', [
        animate('0.3s', style({ height: '0px' })),
      ]),
    ]),
  ],
})
export class AvatarEditComponent implements OnInit {

  @Output() base64 = new EventEmitter();
  @Output() done = new EventEmitter();

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor() { }

  @HostBinding('@openClose') get() {
    return;
  }

  ngOnInit() {
  }

  onOkClick() {
    this.done.emit(this.croppedImage || false);
  }

  onCancelClick() {
    this.done.emit(null);
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (!event.target.files.length) {
      this.croppedImage = null;
    }

  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.base64.emit(event.base64);
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }


}
