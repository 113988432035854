export const asString = (value) => {
  if (value) {
    if (typeof value !== 'string') {
      value = `${value}`;
    }

    return value
      .replace(/\n/g, '\n')
      .replace(/,/g, ',')
      .replace(/;/g, ';');
  }

  return '';
};


export const nl = () => {
  return '\r\n';
};


export const propertyToVCardString = (property) => {
  /** @type {?} */
  let str = '';
  if (property.language) {
    str += `;LANGUAGE=${asString(property.language)}`;
  }
  if (property.value) {
    str += `;VALUE=${asString(property.value)}`;
  }
  if (property.altid) {
    str += `;ALTID=${asString(property.altid)}`;
  }
  if (property.pid) {
    str += `;PID=${property.pid.map((s) => asString(s)).join(',')}`;
  }
  if (property.type) {
    if (Array.isArray(property.type)) {
      if (property.type.length === 1) {
        str += `;TYPE=${property.type[0]}`;
      } else {
        str += `;TYPE="${property.type.map((s) => s).join(',')}"`;
      }
    } else {
      str += `;TYPE=${property.type}`;
    }
  }
  if (property.mediatype) {
    str += `;MEDIATYPE=${asString(property.mediatype)}`;
  }
  if (property.pref) {
    str += `;PREF=${property.pref}`;
  }
  if (property.calscale) {
    str += `;CALSCALE=${asString(property.calscale)}`;
  }
  if (property.sortas) {
    str += `;SORT-AS="${property.sortas.map((s) => asString(s)).join(',')}"`;
  }
  if (property.geo) {
    str += `;GEO=${asString(property.geo)}`;
  }
  if (property.timezone) {
    str += `;TZ=${asString(property.timezone)}`;
  }

  return str;
};

export const isPropertyWithParameters = (object) => {
  /** @type {?} */
  const test = (/** @type {?} */ (object));
  if (test === null || test.param === null || test.value === null) {
    return false;
  }

  return (test.param.language !== undefined ||
    test.param.value !== undefined ||
    test.param.pref !== undefined ||
    test.param.altid !== undefined ||
    test.param.pid !== undefined ||
    test.param.type !== undefined ||
    test.param.mediatype !== undefined ||
    test.param.calscale !== undefined ||
    test.param.sortas !== undefined ||
    test.param.geo !== undefined ||
    test.param.timezone !== undefined);
};


export const isPropertyWithParametersAddressValue = (object) => {
  /** @type {?} */
  const test = (/** @type {?} */ (object));
  if (test === null || test.param === null || test.value === null) {
    return false;
  }

  return (test.param.language !== undefined ||
    test.param.value !== undefined ||
    test.param.pref !== undefined ||
    test.param.altid !== undefined ||
    test.param.pid !== undefined ||
    test.param.type !== undefined ||
    test.param.mediatype !== undefined ||
    test.param.calscale !== undefined ||
    test.param.sortas !== undefined ||
    test.param.geo !== undefined ||
    test.param.timezone !== undefined);
};

export function getFormattedAddress(address): string {
  let result = '';

  if (address.label) {
    result += `;LABEL="${asString(address.label)}"`;
  }

  result += `:${asString(address.postOfficeBox)};`;
  result += `${asString(address.extendedAddress)};`;
  result += `${asString(address.street)};`;
  result += `${asString(address.locality)};`;
  result += `${asString(address.region)};`;
  result += `${asString(address.postalCode)};`;
  result += `${asString(address.countryName)}`;

  return result;
}

export const YYYYMMDD = (date: Date) => {
  if (!date) {
    return '';
  }

  const dateNumber = date.getDate();
  const dateString = dateNumber < 10 ? (`0${dateNumber}`) : dateNumber;
  const monthNumber = date.getMonth() + 1;
  const monthString = monthNumber < 10 ? (`0${monthNumber}`) : monthNumber;

  return `${date.getFullYear()}-${monthString}-${dateString}`;
};

export const getMajorVersion = (version) => {
  /** @type {?} */
  const majorVersionString = version ? version.slice(0, 1) : '4';
  if (!isNaN(+majorVersionString)) {
    return Number.parseInt(majorVersionString);
  }

  return 4;
};

export const hasProp = (obj, property) => {
  return Object.prototype.hasOwnProperty.call(obj, property);
};
