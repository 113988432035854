import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { CFormModule } from '../../../libs/c-form';
import { PersonSectionModule } from '../../../libs/person-section';
import { AnsweringFormLayoutModule } from '../../../libs/answering-form-layout';
import { TopBarComponent } from '../../../libs/top-bar/top-bar.component';

import { AnsweringComponent } from './components/answering';


@NgModule({
  declarations: [AnsweringComponent],
  exports: [
    AnsweringComponent,
  ],
  imports: [
    CFormModule,

    SharedModule,
    AnsweringFormLayoutModule,
    PersonSectionModule,
    TopBarComponent,
  ],
})
export class NewAnsweringModule { }
