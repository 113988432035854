<div class="c-app-content">
  <ng-container *ngIf="storeService?.setup?.showTopbar">
    <app-top-bar class="custom-topbar"></app-top-bar>
  </ng-container>

  <app-sender-message-element *ngIf="storeService?.setup?.includeEmployee"
                              [pageType]="pageType">
  </app-sender-message-element>
  <div *ngIf="!storeService?.setup?.includeEmployee" style="height: 40px"></div>

  <form cForm>
    <ng-container *ngIf="generalFields">
      <app-person-section [form]="generalFormGroup">
      </app-person-section>
    </ng-container>

    <ng-container *ngIf="privateFields">
      <app-form-section [form]="privateFormGroup"
                         [fields]="privateFields"
                         [name]="'receiver.categories.home' | translate"
                         icon="personal"
                         [collapsable]="collapsableSections">
      </app-form-section>
    </ng-container>

    <ng-container *ngIf="workFields">
      <app-form-section [form]="workFormGroup"
                         [fields]="workFields"
                         [name]="'receiver.categories.work' | translate"
                         icon="work"
                         [collapsable]="collapsableSections">
      </app-form-section>
    </ng-container>

    <app-preferences-element
      [pageType]="pageType">
    </app-preferences-element>

    <div class="c-bottom-container">
      <button (click)="validate()" class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary custom-send-button"
              data-cy="answering-submit"
              type="submit">
        {{'receiver.buttons.send' | translate}}
      </button>
    </div>

    <app-info background></app-info>
  </form>
</div>
