import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-person-section-edit',
  templateUrl: './person-section-edit.component.html',
  styleUrls: ['./person-section-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonSectionEditComponent {

  @Input()
  public form: FormGroup;

  public get firstNameArray(): FormArray {
    return this.form.get('first_name') as FormArray;
  }

  public get lastNameArray(): FormArray {
    return this.form.get('last_name') as FormArray;
  }

}
