import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { DoneComponent } from './done/done.component';


@NgModule({
  declarations: [DoneComponent],
  imports: [
    SharedModule,
  ],
})
export class DoneModule { }
