import { NgModule } from '@angular/core';
import { NgIf } from '@angular/common';

import { IconDirective } from '@common/directives';
import { TranslateModule } from '@ngx-translate/core';

import { CFormModule } from '@c-form';

import { FormFieldModule } from '../form-field/form-field.module';

import { PersonSectionPreviewComponent } from './components/person-section-preview';
import { PersonSectionComponent } from './components/person-section';
import { PersonSectionEditComponent } from './components/person-section-edit';


@NgModule({
  imports: [
    IconDirective,
    NgIf,
    CFormModule,
    FormFieldModule,
    TranslateModule,
  ],
  declarations: [
    PersonSectionPreviewComponent,
    PersonSectionEditComponent,
    PersonSectionComponent,
  ],
  exports: [
    PersonSectionComponent,
  ],
})
export class PersonSectionModule {}
