import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';


import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { iconsLibrary } from '@common/consts';
import { timeoutCallback } from '@common/rxjs';

import { catchError, mergeMap, share, take } from 'rxjs/operators';
import { of, forkJoin, Observable, finalize } from 'rxjs';

import { MainAdapter } from '../adapters/main.adapter';
import { SideAdapter } from '../adapters/side.adapter';
import { Consent } from '../../common/classes/consent';
import { environment } from '../../../environments/environment';
import { Side } from '../../common/classes/side';
import { CategoryType } from '../../common/enums/category.type';
import { InfoItemType, InfoItemTypeCheck } from '../../common/enums/info-item.type';
import { Email } from '../../common/classes/email';
import { Phone } from '../../common/classes/phone';
import { Url } from '../../common/classes/url';
import { InfoItem } from '../../common/classes/Info-item';
import { EventDate } from '../../common/classes/event-date';
import { FullAddress } from '../../common/classes/full-address';
import { Fax } from '../../common/classes/fax';
import { SetupAdapter } from '../adapters/setup.adapter';
import { ConsentType } from '../../common/enums/consent.type';
import { BaseStyleFactory } from '../factories/base-styles.factory';
import { LangService } from '../modules/translation';

import { LoaderService } from '../../../libs/loader';

import { TelService } from './tel.service';
import { AppNavService } from './app-nav.service';
import { StoreService } from './store.service';


@Injectable()
export class ApiService {


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storeService: StoreService,
    private http: HttpClient,
    private _appNavService: AppNavService,
    private _langService: LangService,
    private _telService: TelService,
    private _loaderService: LoaderService,
  ) {}

  getAll(token: string, additionalURLParams?: { [key: string]: string }): Observable<any> {
    let additionalURLParamString = '';

    if (additionalURLParams) {
      for (const key of Object.keys(additionalURLParams)) {
        if (additionalURLParams[key]) {
          additionalURLParamString +=
            `${additionalURLParamString.length < 1 ? '?' : '&'}${encodeURIComponent(key)}=${encodeURIComponent(additionalURLParams[key])}`;
        }
      }
    }

    const headers: HttpHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
    });

    const data$ = this.http.get(`${environment.hostname}/api/v1/r/${token}/data${additionalURLParamString}`, { headers: headers })
      .pipe(catchError((err) => of(err)));

    const meta$ = this.http.get(`${environment.hostname}/api/v1/r/${token}/metadata${additionalURLParamString}`, { headers: headers })
      .pipe(catchError((err) => of(err)));

    // ------------------------- Mock ----------------------------
    // TODO remove data mock and mocked flow fork
    // const data$ = of(telekomDataMock);
    // let meta$ = of(metadata_flow_t_email_mock);
    // if (location.href.includes('flow_t_sms')) {
    //   meta$ = of(metadata_flow_t_sms_mock);
    // } else if (location.href.includes('flow_t_email')) {
    //   meta$ = of(metadata_flow_t_email_mock);
    // } else if (location.href.includes('flow_2fa_a_compact')) {
    //   meta$ = of(metadata_flow_2fa_a_compact_mock);
    // } else if (location.href.includes('flow_a_compact')) {
    //   meta$ = of(metadata_flow_a_compact_mock);
    // } else if (location.href.includes('flow_a_shared_section')) {
    //   meta$ = of(metadata_flow_a_shared_section_mock);
    // } else if (location.href.includes('flow_b_split')) {
    //   meta$ = of(metadata_flow_b_split_mock);
    // } else if (location.href.includes('flow_dob_shared')) {
    //   meta$ = of(metadata_flow_dob_shared_mock);
    // }
    // ---------------------- End of Mock -------------------------

    this._loaderService.showLoader();
    const joinedObservable = forkJoin([
      data$,
      meta$,
    ])
      .pipe(
        share(),
        timeoutCallback(() => {
          this._loaderService.showReceiveTimeout();
        }, environment.shortRequestTimeout),
        finalize(() => {
          this._loaderService.hideLoader();
        }),
      );

    joinedObservable.subscribe((all) => {

      let data = all[0] as any;
      const meta = all[1] as any;

      // Code is incorrect
      if ((!data || data instanceof HttpErrorResponse) && window.location.href.includes('code=')) {
        this._appNavService.goToNotFound();

        return;
      }

      if (!meta || meta instanceof HttpErrorResponse) {
        if (!window.location.href.includes('error')) {
          this._appNavService.goToErrorPage();

          return;
        }
      }

      if (!data || data instanceof HttpErrorResponse) {
        if (data.status && (data.status === 401 || data.status === 403 || data.status === 410 || data.status === 429)) {
          // We assume that this is due to the 2FA-flow blocking the data endpoint without a token here
          // @ts-ignore
          const cn = data.error.contract_number;
          const company = data.error.company;
          const st = data.status;
          data = {
            shared_profile: meta.shared_profile,
            contract_number: cn,
            status: st,
            company: company,
          };
          delete meta.shared_profile;
        }
        if (data.status && data.status === 429) {
          this.storeService.setBlocked(true);
        }
      }

      /* ----------------------------------------
       * 🛠 Main data parser
       * -------------------------------------- */
      let adapted = { ...meta, ...data };
      try {
        adapted = MainAdapter.processData({ ...meta, ...data });
      } catch (e) {
        if (!window.location.href.includes('error')) {
          if (data.status !== 410) {
            this._appNavService.goToErrorPage();

            return;
          }
        }
      }

      /* ----------------------------------------
       * 🎨 Restyling
       * -------------------------------------- */
      BaseStyleFactory.applyBaseStyles(adapted.baseStyles);
      this.applyCustomStyles(adapted.customCSS);

      /* ----------------------------------------
      * Check Tocken
      * -------------------------------------- */
      if (!window.location.href.includes('error')) {
        if (data.status === 410) {
          this._appNavService.goToInvalidTokenPage();
        }
      }


      /* ----------------------------------------
       * 🗄️ Fullfill storage
       * -------------------------------------- */
      this.storeService.setToken(token);
      this.storeService.setAccount({ current: { name: data.company?.name || '' } });
      const setupFromSetupBaseAndMeta = SetupAdapter.makeSetup(meta);
      this.storeService.setSetup(setupFromSetupBaseAndMeta);
      this.storeService.setSender(
        SideAdapter.processData(adapted.sender, setupFromSetupBaseAndMeta),
      );
      this.storeService.setReceiver(
        SideAdapter.processData(adapted.receiver, setupFromSetupBaseAndMeta),
      );
      this.storeService.initFields({ ...adapted.receiver, ...setupFromSetupBaseAndMeta });
      this.storeService.setPreferences(this.makeConsents(data, this._telService.isTel()));
      this.storeService.setCustomIcons(adapted.icons);
      this.storeService.setCustomLegal(adapted.customLegal);
      this.storeService.setCustomCopy(adapted.customCopy);
      this.storeService.setContractNumber(adapted.contractNumber);
      this.storeService.setRequestConfirmation(adapted.confirmationRequested);
      /* ----------------------------------------
       * ⛴ Navigation start
       * -------------------------------------- */
      this._appNavService.setFirstPageOfSequence(this.storeService.setup.sequence[0]);

      /* ----------------------------------------
       * 💬 Translation for custom "Copy"
       * -------------------------------------- */
      if (this.storeService.customCopy) {
        Object.keys(this.storeService.customCopy).forEach((language) => {
          this._langService.setOverrides(language, this.storeService.customCopy[language]);
        });
      }
    });

    return joinedObservable;
  }

  saveAnswer(receiver: Side, additionalURLParams?: { [key: string]: string }): Observable<any> {
    // let additionalURLParamString = '';

    // if (additionalURLParams) {
    //   for (const key of Object.keys(additionalURLParams)) {
    //     if (additionalURLParams[key]) {
    //       additionalURLParamString +=
    //         `${additionalURLParamString.length < 1 ? '?' : '&'}${encodeURIComponent(key)}=${encodeURIComponent(additionalURLParams[key])}`;
    //     }
    //   }
    // }

    let homeItems = receiver.homeInfoItems.filter((item) => item.categoryType === CategoryType.PERSONAL);
    const workItems = receiver.workInfoItems.filter((item) => item.categoryType === CategoryType.WORK);
    const sharedItems = receiver.sharedInfoItems
      .filter((item) => item.categoryType === CategoryType.SHARED || item.categoryType === CategoryType.PERSONAL);

    if ((
      this.storeService.setup.flow === 'flow_a_shared_section' ||
      this.storeService.setup.flow === 'flow_dob_shared')
      && sharedItems.length > 0
    ) {

      homeItems = homeItems
        .filter((item) => !(
          item.type === InfoItemType.FULL_ADDRESS ||
          item.type === InfoItemType.ADDRESS ||
          item.type === InfoItemType.EVENT
        ));

      for (const item of sharedItems) {
        if (item.type === InfoItemType.FULL_ADDRESS || item.type === InfoItemType.ADDRESS || item.type === InfoItemType.EVENT) {
          homeItems.push(item);
        }
      }
    }

    const payload = {
      'contact': {
        'company': receiver.person.company,
        'first_name': receiver.person.name.firstName,
        'handler_id': receiver.handler_id,
        'image_url': receiver.person.imageUrl,
        'last_name': receiver.person.name.lastName,
        'native_id': receiver.id,
        'position': receiver.person.position,
        'salutation': receiver.person.salutation,
        'name_suffix': receiver.person.nameSuffix,
        'title': receiver.person.title,
        'website': receiver.person.website,
        'birthday': receiver.person.birthday,

        'contact_sections': {
          'private': this.makeSectionPayload(homeItems, receiver, true),
          'work': this.makeSectionPayload(workItems, receiver),
        },

        'advertisement_consents_given': this.storeService.preferences.filter((item) => item.value).map((item) => item.id),
      },
    };

    const answerSavingCallback = (hostname, token, payloadObject) => {
      return this.http.post(
        `${hostname}/api/v1/r/${token}/answer`,
        payloadObject,
        {
          params: additionalURLParams,
        },
      );
    };

    if (this.storeService.newAvatarBase64) {
      return this.saveAvatar(this.storeService.token, this.storeService.newAvatarBase64).pipe(mergeMap((data: any) => {
        payload.contact.image_url = data.url;

        return answerSavingCallback(environment.hostname, this.storeService.token, payload);
      }));
    }

    return answerSavingCallback(environment.hostname, this.storeService.token, payload);
  }

  public sendAnswer(data: any): Observable<any> {
    return this.http.post(
      `${environment.hostname}/api/v2/r/${this.storeService.token}/answer`,
      data,
    );

  }

  checkAuthentication(token, code) {
    return this.http.get(`${environment.hostname}/api/v1/r/${token}/data?code=${code}`);
  }

  checkVerificationCode(token, code, source?: string) {
    return this.http.get(
      `${environment.hostname}/api/v1/r/${token}/verification`,
      {
        params: {
          code,
          source,
        },
      },
    );
  }

  trackVcard(token) {
    return this.http.get(`${environment.hostname}/api/v1/r/${token}/vcard_download`).pipe(take(1));
  }

  trackDownloading(token) {
    return this.http.get(`${environment.hostname}/api/v1/r/${token}/data_export`).pipe(take(1));
  }

  trackPhoneClick(token) {
    return this.http.get(`${environment.hostname}/api/v1/r/${token}/phone_dialed`).pipe(take(1));
  }

  trackEmailClick(token) {
    return this.http.get(`${environment.hostname}/api/v1/r/${token}/email_sent`).pipe(take(1));
  }

  deleteAccount(token, reasons = '') {
    return this.http.get(`${environment.hostname}/api/v1/r/${token}/terminate/${reasons}`).pipe(take(1));
  }

  private makeConsents(data: {
    company: { advertisement_consent: { id: string, native_id?: string, icon?: string, subject: string, description: string, type: string }[] },
    contact: { advertisement_consents_given: string[] }
  }, isTelFlow: boolean) {

    if (isTelFlow && Array.isArray(data.company?.advertisement_consent)) {
      data.company.advertisement_consent.reverse();
    }

    const consents = (data.company?.advertisement_consent || []).map((item) => {
      let hardcodedIcon: string;
      if (isTelFlow) {
        if (item.type === 'ReO') {
          hardcodedIcon = iconsLibrary.tel_doc;
        } else {
          hardcodedIcon = iconsLibrary.tel_screen;
        }
      }

      return new Consent({
        id: item.native_id || item.id,
        type: item.type === 'ReO' ? ConsentType.Reo : ConsentType.Default,
        iconSvg: item.icon || hardcodedIcon || null,
        title: this.addNoBreakingWordsOnHyphen(item.subject),
        html: this.addNoBreakingWordsOnHyphen(item.description),
        value: !!(data.contact?.advertisement_consents_given || []).find((id) => id === item.native_id || id === item.id),
      });
    });

    return consents.sort((a, b) => {
      return a.type - b.type;
    });
  }

  private addNoBreakingWordsOnHyphen(str: string) {
    function revertReplacer(match) {
      return match.replace(/-\u2060/gim, '-');
    }

    str = str.replace(/-/gim, '-\u2060');

    return str.replace(/<[^<>]*>/gi, revertReplacer);
  }


  private makeSectionPayload(infoItems: InfoItem[], side: Side, isPersonal = false) {
    const object: any = {
      'addresses': infoItems.filter((item) => (item.type === InfoItemType.ADDRESS || item.type === InfoItemType.FULL_ADDRESS))
        .map((item: FullAddress) => ({
          'city': String(item.city || ''),
          'country': String(item.country || ''),
          'street': String(item.street || ''),
          'zip_code': String(item.postcode || ''),
          'house_number': String(item.house || ''),
          'addition': String(item.addition || ''),
          'extra_1': String(item.extra1 || ''),
          'extra_2': String(item.extra2 || ''),
          'extra_3': String(item.extra3 || ''),
        })),
      /*
      Remove full addresses field for the time being
      'full_addresses': infoItems.filter(item => item.type === InfoItemType.FULL_ADDRESS).map((item: FullAddress) => ({
        'city': String(item.city || ''),
        'country': String(item.country || ''),
        'street': String(item.street || ''),
        'zip_code': String(item.postcode || ''),
        'house_number': String(item.house || ''),
        'addition': String(item.addition || '')
      })),
       */
      'emails': infoItems.filter((item) => item.type === InfoItemType.EMAIL).map((item: Email) => String(item.email)),
      'mobile_numbers': infoItems.filter((item) => InfoItemTypeCheck.isPhone(item.type) && (item as Phone).isMobile)
        .map((item: Phone) => String(item.value)),
      'phone_numbers': infoItems.filter((item) => InfoItemTypeCheck.isPhone(item.type) && !(item as Phone).isMobile)
        .map((item: Phone) => String(item.value)),
      'fax_numbers': infoItems.filter((item) => item.type === InfoItemType.FAX)
        .map((item: Fax) => String(item.value)),
      'urls': infoItems.filter((item) => item.type === InfoItemType.URL).map((item: Url) => String(item.url)),
    };

    if (isPersonal) {
      const birthday = infoItems.find((item) => item.type === InfoItemType.EVENT) as EventDate;
      object.birthday = (birthday && birthday.mm && birthday.dd && birthday.yyyy) ? this.makeBirthdayPayload(birthday) : null;
    }

    return object;
  }

  saveAvatar(token: string, newAvatarBase64: string = null) {
    const base64 = newAvatarBase64.replace('data:image/png;base64,', '');

    return this.http.post(`${environment.hostname}/api/v1/r/${token}/avatar`, { body: base64 }).pipe(take(1));
  }

  private makeBirthdayPayload(event: EventDate): string {

    let dayNumber = String(event.dd);
    let month = String(event.mm);
    let year = String(event.yyyy);

    if (dayNumber.length === 1) {
      dayNumber = `0${dayNumber}`;
    }

    if (month.length === 1) {
      month = `0${month}`;
    }

    if (year.length === 1) {
      year = `000${year}`;
    }

    if (year.length === 2) {
      year = `00${year}`;
    }

    if (year.length === 3) {
      year = `0${year}`;
    }

    return `${year  }-${month}-${dayNumber}T00:00:00Z`;
  }

  trigger2FASMS(token) {
    return this.http.get(`${environment.hostname}/api/v1/r/${token}/trigger_sms_tan`).pipe(take(1));
  }

  private applyCustomStyles(css: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('style');
    style.innerHTML = css;
    head.appendChild(style);
  }
}


