import { Component, Input, OnInit } from '@angular/core';

import { StoreService } from '../../core/services/store.service';
import { SetupInterface } from '../../common/interfaces/setup.interface';
import { PageType } from '../../common/enums/page.type';
import { FeatureType } from '../../common/enums/feature.type';


@Component({
  selector: 'app-receiver-message-element',
  templateUrl: './receiver-message-element.component.html',
  styleUrls: ['./receiver-message-element.component.scss'],
})
export class ReceiverMessageElementComponent implements OnInit {

  @Input() pageType: PageType;
  setup: SetupInterface;
  featureType = FeatureType;

  constructor(private storeService: StoreService) {

  }

  ngOnInit(): void {
    this.setup = this.storeService.setup;
  }

}
