import { Injectable } from '@angular/core';


@Injectable()
export class PopoverService {
  content: string;
  target: EventTarget;
  tag: string;
  dataAttribute: string;
  top: number;
  beginning: number;
  ending: number;


  watchTargets(tag: string, dataAttribute: string) {
    this.tag = tag;
    this.dataAttribute = dataAttribute;
    this.setContent(null);

    setTimeout(() => {
      const els = document.querySelectorAll(`${this.tag  }[${this.dataAttribute}]`);
      els.forEach((item) => {
        item.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          this.removeAllLinkBeginningSpans();
          /* ----------------------------------------
           * Set target and top position
           * -------------------------------------- */
          const el = (event.target as HTMLLinkElement);
          this.target = el;
          this.updateTop();
          /* ----------------------------------------
           * Add link beginning and ending for getting starting point
           * -------------------------------------- */
          const linkStartSpan = document.createElement('span');
          linkStartSpan.setAttribute('class', 'data-link-beginning');
          el.prepend(linkStartSpan);
          this.beginning = Math.round(linkStartSpan.getBoundingClientRect().left);
          this.ending = Math.round(el.offsetWidth + el.getBoundingClientRect().left);
          /* ----------------------------------------
           * Get content for current popover
           * -------------------------------------- */
          const templateId = el.getAttribute(this.dataAttribute);
          const templateHtml = document.getElementById(templateId).innerHTML;
          this.setContent(templateHtml);

        });
      });

      /* ----------------------------------------
       * Watch body events changes
       * -------------------------------------- */
      document.body.addEventListener('click', () => {
        this.setContent(null);
      });
      document.body.addEventListener('scroll', () => {
        this.updateTop();
      });

    }, 200);


  }

  updateTop() {
    if (this.target) {
      this.top = document.documentElement.scrollTop + (this.target as HTMLLinkElement).getBoundingClientRect().top;
    }
  }

  setContent(content: string | null) {
    this.content = content;
  }


  removePopover() {
    this.setContent(null);
    this.target = null;
  }

  private removeAllLinkBeginningSpans() {
    const els = document.querySelectorAll('span.data-link-beginning');
    els.forEach((item) => item.parentNode.removeChild(item));
  }
}
