import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Subject } from 'rxjs';

import { InfoItem } from '../../common/classes/Info-item';
import { Side } from '../../common/classes/side';
import { StoreService } from '../../core/services/store.service';
import { PageType } from '../../common/enums/page.type';
import { InfoItemType } from '../../common/enums/info-item.type';


@Component({
  selector: 'app-sender-contacts',
  templateUrl: './sender-contacts.component.html',
  styleUrls: ['./sender-contacts.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
      })),
      state('closed', style({
        height: '0px',
      })),
      transition('open => closed', [
        animate('0.5s'),
      ]),
      transition('closed => open', [
        animate('0.3s'),
      ]),
    ]),
    trigger('showHide', [
      // ...
      state('show', style({
        opacity: '0',
        display: 'none',
      })),
      state('hide', style({
        display: 'block',
        opacity: '1',
      })),
      transition('show => hide', [
        animate('0.5s'),
      ]),
      transition('hide => show', [
        animate('0.5s'),
      ]),
    ]),
  ],
})
export class SenderContactsComponent implements OnInit, OnDestroy {

  @Input() pageType?: PageType;
  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  sender: Side;
  displayedItems: InfoItem[];
  collapsedItems: InfoItem[];
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  isOpen = false;
  /* ----------------------------------------
   * Life-Cycles
   * -------------------------------------- */
  private onDestroySubject = new Subject();
  onDestroy$ = this.onDestroySubject.asObservable();

  constructor(public storeService: StoreService) { }

  ngOnDestroy() {
    this.onDestroySubject.next(true);
    this.onDestroySubject.complete();
  }

  ngOnInit() {
    this.sender = { ...this.storeService.sender };

    const items = [
      ...this.sender.workInfoItems,
      ...this.sender.homeInfoItems,
    ].filter((item) => !item.isEmpty());

    const phones = [];
    const emails = [];
    const others = [];

    items.forEach((item) => {

      if (item.type === InfoItemType.PHONE) {
        phones.push(item);
      } else if (item.type === InfoItemType.EMAIL) {
        emails.push(item);
      } else {
        others.push(item);
      }
    });

    const sorted = [
      ...phones,
      ...emails,
      ...others,
    ];

    this.displayedItems = sorted.slice(0, 3);
    this.collapsedItems = sorted.length > 3 ? sorted.slice(3) : [];
  }

  onViewDetailsClick() {
    this.isOpen = !this.isOpen;
  }


}
