import { FormControl, Validators } from '@angular/forms';

import { CValidators } from '@common/helpers/validators';


export class FormControlBuilder {

  private _control: FormControl<unknown>;

  constructor() {
    this.reset();
  }

  public reset(): void {
    this._control = new FormControl();
  }

  public withValue(value: unknown) {
    this._control.setValue(value);
  }

  public addRequiredValidator(): void {
    this._control.addValidators(Validators.required);
  }

  public addRequiredCheckboxValidator(): void {
    this._control.addValidators(Validators.requiredTrue);
  }

  public addEmailValidator(): void {
    this._control.addValidators(Validators.email);
  }

  public addMinLengthValidator(length: number): void {
    this._control.addValidators(Validators.minLength(length));
  }

  public addMaxLengthValidator(length: number): void {
    this._control.addValidators(Validators.maxLength(length));
  }

  public addBirthdayValidator(): void {
    this._control.addValidators(CValidators.birthday);
  }

  public makeDisabled(): void {
    this._control.disable();
  }

  public build<T = unknown>(): FormControl<T> {
    const control = this._control;
    this.reset();

    return control as FormControl<T>;
  }
}
