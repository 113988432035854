// eslint-disable-next-line
export const ERROR_MESSAGES: { [key: string]: string } = {
  // internal web app
  required: 'validation.required',
  email: 'validation.email',
  birthday: 'validation.Birthday',
  houseNumber: 'validation.houseValidation',
  // Email
  1: 'validation.required',
  2: 'email determined implausible',
  3: 'validation.email',
  4: 'number of emails is over the requested limit',
  11: 'validation.required',
  12: 'email determined implausible',
  13: 'validation.email',
  // Birthday
  20: 'validation.required',
  21: 'too many birthdays',
  22: 'validation.Birthday',
  23: 'validation.required',
  24: 'invalid birthday input',
  // First Name
  30: 'validation.required',
  31: 'validation.first_name',
  32: 'too many first names',
  33: 'validation.required',
  34: 'validation.first_name',
  // Last Name
  40: 'validation.required',
  41: 'validation.last_name',
  42: 'too many last names',
  43: 'validation.required',
  44: 'validation.last_name',
  // Mobile
  5: 'validation.required',
  6: 'number of mobile numbers is over the requested limit',
  15: 'validation.required',
  // Phone
  8: 'validation.required',
  9: 'number of phone numbers is over the requested limit',
  16: 'validation.required',
};
