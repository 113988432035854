import { Component, OnInit } from '@angular/core';

import { ExportToCsv } from 'export-to-csv';

import { StoreService } from '../../../core/services/store.service';
import { InfoItemType } from '../../../common/enums/info-item.type';
import { CategoryType } from '../../../common/enums/category.type';
import { InfoItem } from '../../../common/classes/Info-item';
import { scrollToTop } from '../../../common/functions/scroll-to-top.function';
import { SettingsService } from '../settings.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';


@Component({
  selector: 'app-account-download-data',
  templateUrl: './account-download-data.component.html',
  styleUrls: ['./account-download-data.component.scss'],
})
export class AccountDownloadDataComponent implements OnInit {

  constructor(
    public settingsService: SettingsService,
    private storeService: StoreService,
    private appNavService: AppNavService,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    if (!this.settingsService.selectedAccounts.length) {
      this.appNavService.goToSettingsPage();
    }
    scrollToTop();
  }

  onDownloadFileClick() {
    /* ----------------------------------------
     * Add data from person object and custom messages
     * -------------------------------------- */
    const data = [
      {
        'Salutation': this.storeService.receiver.person.salutation || '',
        'First name': this.storeService.receiver.person.name.firstName || '',
        'Last name': this.storeService.receiver.person.name.lastName || '',
        'Name Suffix': this.storeService.receiver.person.nameSuffix || '',
        'Position': this.storeService.receiver.person.position || '',
        'Company': this.storeService.receiver.person.company || '',
        'Website': this.storeService.receiver.person.website || '',
        'Image Url': this.storeService.receiver.person.imageUrl || '',
        'Custom Message': this.storeService.receiver.message || '',
        'Birthday': (this.storeService.receiver.person.birthday || ''),
      },
    ];

    /* ----------------------------------------
     * Add data from folders
     * -------------------------------------- */
    [
      ...this.storeService.receiver.workInfoItems,
      ...this.storeService.receiver.homeInfoItems,
    ].forEach((item) => {
      if (!this.isBirthday(item)) {
        const key = this.makeTitleForItem(item);
        data[0][key] = item.listValue;
      }
    });
    /* ----------------------------------------
     * Generate the file
     * -------------------------------------- */
    const date = new Date();
    const options = {
      filename: `complero-exported-data--${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}--${date.getHours()}-${date.getMinutes()}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: this.storeService.account.current.name,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
    this.apiService.trackDownloading(this.storeService.token).subscribe(() => { });
  }


  private makeTitleForItem(item) {

    let kind = '';
    const category = item.categoryType === CategoryType.WORK ? 'Work' : 'Personal';

    switch (item.type) {
      case InfoItemType.URL:
        kind = 'Url';
        break;
      case InfoItemType.EVENT:
        kind = 'Event';
        break;
      case InfoItemType.ADDRESS:
        kind = 'Address';
        break;
      case InfoItemType.FULL_ADDRESS:
        kind = 'Full address';
        break;
      case InfoItemType.PHONE:
        kind = 'Phone';
        break;
      case InfoItemType.MOBILE:
        kind = 'Phone';
        break;
      case InfoItemType.FAX:
        kind = 'Fax';
        break;
      case InfoItemType.EMAIL:
        kind = 'Email';
        break;
      case InfoItemType.ORG:
        kind = 'Org';
        break;
    }

    return `${category  } ${kind}`;

  }

  private isBirthday(item: InfoItem) {
    return item.type === InfoItemType.EVENT && item.categoryType === CategoryType.PERSONAL;
  }


}
