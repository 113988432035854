import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { PreferenceComponent } from './preference/preference.component';


@NgModule({
  declarations: [PreferenceComponent],
  exports: [
    PreferenceComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class PreferenceModule { }
