import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { IFormField } from '@c-fields/common';


@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
      })),
      state('closed', style({
        height: '0px',
      })),
      transition('open => closed', [
        animate('0.5s'),
      ]),
      transition('closed => open', [
        animate('0.3s'),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent {

  @Input()
  public form: FormGroup;

  @Input()
  public icon: string;

  @Input()
  public name: string;

  @Input()
  public fields: IFormField[];

  @Input()
  public collapsable = false;

  public collapsed = true;

  public toggleCollapse(): void {
    if (!this.collapsable) {
      return;
    }

    this.collapsed = !this.collapsed;
  }
}
