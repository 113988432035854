import { FormArray, FormControl } from '@angular/forms';

import { IFormField } from '../interfaces/form-field.interface';

import { BaseField } from './base-field';


/**
 * SimpleField is a helper class to follow DRY principle for single textual Fields like:
 * email/first name and etc
 *
 * You don't have to inherit this class for such Fields, you can directly inherit BaseField
 * or only implement IFormField
 */
export abstract class SimpleField<T = string>
  extends BaseField<T, FormControl> implements IFormField<T> {

  public addEmptyFormControl() {
    this._formArray.push(this._createNewFormControl(this._nextIndex, ''), { emitEvent: false });
  }

  public toFormArray(): FormArray<FormControl> {
    const formArray = this._formArray;

    if (Array.isArray(this._initialValues) && this._initialValues.length > 0) {
      this._initialValues.forEach((value, index) => {
        const control = this._createNewFormControl(index, value);

        formArray.push(control);
      });
    }

    return formArray;
  }

  public addEmptyFormControlIfNeeded(): void {
    if (!this._canAddEmptyFormControl) {
      return;
    }

    const lastValue = this._formArray.value[this._formArray.length - 1];

    if (!this._isValueEmpty(lastValue)) {
      this.addEmptyFormControl();
    }
  }

  protected _isValueEmpty(value: string): boolean {
    return !value || value.trim() === '';
  }

}
