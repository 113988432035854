import { Component, OnInit } from '@angular/core';

import { AppNavService } from '../../../core/services/app-nav.service';
import { PersonInterface } from '../../../common/interfaces/person.interface';
import { PageType } from '../../../common/enums/page.type';
import { StoreService } from '../../../core/services/store.service';
import { browserGoBack } from '../../../common/functions/browser-go-back.function';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  person: PersonInterface;
  currentAccountName: string;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  pageType = PageType;


  constructor(
    private appNavService: AppNavService,
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
    this.person = this.storeService.receiver.person;
    this.currentAccountName = this.storeService.account.current.name;
  }

  onBack() {
    browserGoBack();
  }


}
