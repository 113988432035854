import { Injectable } from '@angular/core';


@Injectable()
export class SettingsService {

  accounts = [];
  selectedAccounts = [];


}
