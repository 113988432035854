import { PageType } from '../../../common/enums/page.type';
import { FeatureType } from '../../../common/enums/feature.type';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { LoaderType } from '../../../common/enums/loader.type';
import { VerificationType } from '../../../common/enums/verification-type.enum';


export const flowTSMSSetup: SetupInterface = {
  flow: 'flow_t_sms',
  company: 'telekom',
  loaderType: LoaderType.Circle,
  sequence: [
    PageType.Answering,
    PageType.Confirmation,
  ],
  fields: {
    [PageType.Answering]: {},
    [PageType.Sharing]: {},
  },
  features: {
    [PageType.Answering]: {
      [FeatureType.TopBar]: true,
      [FeatureType.Person]: true,
      [FeatureType.InlinePersonInfo]: true,
      [FeatureType.SenderMessage]: true,
      [FeatureType.AppInfo]: true,
      [FeatureType.HomeFolder]: true,
      [FeatureType.HomeFolderOpen]: true,
      [FeatureType.WorkFolder]: false,
      [FeatureType.WorkFolderOpen]: false,
      [FeatureType.PreferenceToggle]: true,
      [FeatureType.PreferenceOpen]: true,
      [FeatureType.ShowAddressTitle]: true,
      [FeatureType.AddressInputTopIcon]: true,
      [FeatureType.ShowInfoInputsByDefault]: true,
      [FeatureType.CheckboxInlinePreferenceText]: true,
      [FeatureType.ShowDefaultConsents]: true,
      [FeatureType.ShowReoConsents]: true,
      [FeatureType.ShowAddressExtra3]: true,
      [FeatureType.LegalHtml]: true,
    },
    [PageType.Sharing]: {},
    [PageType.DeliveryNotice]: {
      [FeatureType.TopBar]: true,
      [FeatureType.AddressInputTopIcon]: false,
      [FeatureType.ShowAddressExtra3]: true,
    },
    [PageType.Preference]: {
      [FeatureType.LegalHtml]: true,
      [FeatureType.Preferences]: false,
    },
    [PageType.Confirmation]: {
      [FeatureType.CheckboxInlinePreferenceText]: true,
      [FeatureType.AppInfo]: false,
      [FeatureType.ShowEmployee]: false,
      [FeatureType.TopBar]: false,
    },
    [PageType.SimpleDone]: {
      [FeatureType.ConfirmFull]: true,
      [FeatureType.ConfirmOnlyEmail]: false,
    },
    [PageType.DOBAuthentication]: {
      [FeatureType.TopBar]: false,
      [FeatureType.AppInfo]: true,
    },
  },
  limits: {
    [PageType.Answering]: {},
    [PageType.Sharing]: {},
    [PageType.Confirmation]: {},
    [PageType.SimpleDone]: {},
  },
  readOnly: {
    [PageType.Answering]: {},
    [PageType.Sharing]: {},
    [PageType.Confirmation]: {},
    [PageType.SimpleDone]: {},
  },
  required: {
    [PageType.Answering]: {},
    [PageType.Sharing]: {},
    [PageType.Confirmation]: {},
    [PageType.SimpleDone]: {},
  },
  verificationType: VerificationType.None,
  includeEmployee: true,
  showTopbar: true,
  showContractNumber: true,
};
