import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SharingModule } from '../pages/sharing/sharing.module';
import { AnsweringModule } from '../pages/answering/answering.module';
import { PreferenceModule } from '../pages/preference/preference.module';

import { RootComponent } from './root/root.component';


@NgModule({
  declarations: [RootComponent],
  imports: [
    SharedModule,
    SharingModule,
    AnsweringModule,
    PreferenceModule,
  ],
})
export class RootModule { }
