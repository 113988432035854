<div class="c-category">
  <div class="c-category-header custom-light-panel">
    <div class="c-category-title">{{'account.delete.title'| translate}}</div>
  </div>
  <div class="c-category-content">
    <div class="c-container">

      <div class="c-accounts">
        <b *ngFor="let account of settingsService.selectedAccounts; let l = last">
          {{account}}
          <ng-container *ngIf="!l">,</ng-container>
        </b>
      </div>

      <div class="c-checkboxes-title">{{'account.delete.questionForReason' | translate}}</div>

      <ng-container *ngFor="let field of fields">
        <div *ngIf="setup.fields[this.pageType][field.type]" class="c-item">
          <div class="c-icon">
            <p-checkbox [checked]="values[field.type]" (change)="values[field.type]=!values[field.type]"
              animation="pulse" color="primary" enableFocus="true" class="custom-checkbox"
              style="margin: 0 !important;"></p-checkbox>
          </div>
          <div class="c-entry">
            <div class="c-title">{{'account.delete.options.' + field.number + ''|translate}}</div>
          </div>
        </div>
      </ng-container>

    </div>

    <div class="c-warning">
      <div class="c-info">{{'account.delete.warning' | translate}}</div>
      <div class="c-item">
        <div class="c-icon">
          <p-checkbox (change)="isAgree=$event.checked" animation="pulse" color="primary" enableFocus="true"
            class="custom-checkbox" style="margin: 0 !important;"></p-checkbox>
        </div>
        <div class="c-entry">
          <div class="c-title">{{'account.delete.agreeCheckbox' | translate}}</div>
        </div>
      </div>
      <div class="c-button-container">
        <button (click)="onDeleteClick()" [disabled]="!isAgree"
          class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary" type="button">{{'account.delete.btn' |
          translate}}
        </button>
      </div>

    </div>


  </div>
</div>