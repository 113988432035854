<div [class.c-masked]="!croppedImage" class="c-cropper">
  <div *ngIf="!croppedImage" [appIcon]="'photo_placeholder'" [size]="90" class="c-avatar-placeholder"></div>
  <image-cropper (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()" [aspectRatio]="1" [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true" [onlyScaleDown]="true" [resizeToWidth]="1000" class="c-cropper-component" format="png">
  </image-cropper>
</div>
<div class="c-sidebar">

  <input (change)="fileChangeEvent($event)" class="btn btn-default c-input" id="file" name="file" type="file" />
  <label class="btn btn-success custom-btn-success" for="file">
    <span [appIcon]="'add_photo'" [size]="45" class="c-upload-icon"></span>
    {{'receiver.avatar.choosePhoto' | translate}}</label>

  <div class="c-main-buttons">
    <button (click)="onOkClick()" class="btn btn-primary mb-1 custom-btn-primary">{{'receiver.avatar.ok' |
      translate}}</button>
    <button (click)="onCancelClick()" class="btn btn-secondary custom-btn-secondary">{{'receiver.avatar.cancel' |
      translate}}</button>
  </div>
</div>