import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { AnsweringComponent } from './answering/answering.component';


@NgModule({
  declarations: [AnsweringComponent],
  exports: [
    AnsweringComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class AnsweringModule { }
