import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { VerificationType } from '../../common/enums/verification-type.enum';
import { PersistanceService } from '../services/persistance.service';
import { StoreService } from '../services/store.service';
import { AppNavService } from '../services/app-nav.service';


@Injectable({
  providedIn: 'root',
})
export class DobAuthGuardGuard implements Resolve<any> {
  constructor(
    private storeService: StoreService,
    private appNavService: AppNavService,
    private persistanceService: PersistanceService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.storeService.setup$
      .pipe(
        take(1),
        map((setup) => {
          // If the current flow requires two factor authorization and no code is present as an URL param we redirect to the 2FA page
          const authRequired = setup?.verificationType === VerificationType.Birthday
            || setup?.verificationType === VerificationType.ZipCode;

          if (authRequired && !route.queryParams.code) {
            const pageType = this.appNavService.getPageTypeByURL(route.routeConfig.path);
            if (pageType) {
              this.persistanceService.setItem('requestedPage', pageType);
            }

            if (setup?.verificationType === VerificationType.Birthday) {
              this.appNavService.goToBirthday(route.queryParams);
            } else if (setup?.verificationType === VerificationType.ZipCode) {
              this.appNavService.goToPostcode(route.queryParams);
            }

            return false;
          }

          return true;
        }),
      );
  }

}
