<div [appIcon]="item.iconName" [size]="20" class="c-icon custom-icon"></div>
<div class="c-entry" (click)="(pageType===pageTypeDict.Answering && !item.isReadOnly) ? onEditClick(item) : null">
  <div class="c-list-value">
    <ng-container *ngIf="item.type!==infoItemType.PHONE&&item.type!==infoItemType.EMAIL">{{item.listValue}}
    </ng-container>
    <!--phone-->
    <div *ngIf="item.type===infoItemType.PHONE&&pageType===pageTypeDict.Sharing" (click)="onPhoneClick()">
      <a [href]="'tel:'+ item.listValue">{{item.listValue}}</a>
    </div>
    <div *ngIf="item.type===infoItemType.PHONE&&pageType!==pageTypeDict.Sharing">{{item.listValue}}</div>
    <!--email-->
    <div *ngIf="item.type===infoItemType.EMAIL&&pageType===pageTypeDict.Sharing" (click)="onEmailClick()">
      <a [href]="'mailto:'+ item.listValue">{{item.listValue}}</a>
    </div>
    <div *ngIf="item.type===infoItemType.EMAIL&&pageType!==pageTypeDict.Sharing">{{item.listValue}}</div>


    <div *ngIf="item.type===infoItemType.EMAIL" class="c-label">
      {{'labelTexts.email' | translate}}
      <span *ngIf="displayCategoryLabel" class="c-category-label">
        <!--({{(isWork ? 'labelTexts.work' : 'labelTexts.personal')|translate}})-->
      </span>
    </div>
    <div *ngIf="item.type===infoItemType.PHONE" class="c-label">
      {{(item.isMobile ? 'labelTexts.mobile' : 'labelTexts.phone') | translate}}
      <span *ngIf="displayCategoryLabel" class="c-category-label">
        <!--({{(isWork ? 'labelTexts.work' : 'labelTexts.personal')|translate}})-->
      </span>
    </div>
    <div *ngIf="item.type===infoItemType.FAX" class="c-label">
      {{'labelTexts.fax' | translate}}
      <span *ngIf="displayCategoryLabel" class="c-category-label">
        <!--({{(isWork ? 'labelTexts.work' : 'labelTexts.personal')|translate}})-->
      </span>
    </div>
    <div *ngIf="item.type===infoItemType.EVENT" class="c-label">{{'labelTexts.birthday' | translate}}</div>
    <div *ngIf="item.type===infoItemType.ADDRESS" class="c-label">
      {{'labelTexts.address' | translate}}
      <span *ngIf="displayCategoryLabel" class="c-category-label">
        <!--({{(isWork ? 'labelTexts.work' : 'labelTexts.personal')|translate}})-->
      </span>
    </div>
    <div *ngIf="item.type===infoItemType.FULL_ADDRESS" class="c-label">
      {{'labelTexts.address' | translate}}
      <span *ngIf="displayCategoryLabel" class="c-category-label">
        <!--({{(isWork ? '' : 'labelTexts.personal')|translate}})-->
      </span>
    </div>
    <div *ngIf="item.type===infoItemType.URL" class="c-label">
      {{'labelTexts.link' | translate}}
      <span *ngIf="displayCategoryLabel" class="c-category-label">
        <!--({{(isWork ? '' : 'labelTexts.personal')|translate}})-->
      </span>
    </div>
    <div *ngIf="item.type===infoItemType.ORG" class="c-label">{{'labelTexts.org' | translate}}</div>
  </div>
  <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}" nzTooltipPlacement="left" nz-tooltip
    *ngIf="item.isReadOnly ? true : null" [appIcon]="'info'" class="c-info-icon">
  </div>
  <div #dropdownRef="ngbDropdown" [hidden]="!showMenu" class="c-menu" ngbDropdown="NgbDropdown"
    placement="bottom-right">

    <button *ngIf="!item.isReadOnly" [appIcon]="'more'" [size]="24" class="btn c-btn-more" id="more"
      (click)="$event.stopPropagation(); dropdownRef.toggle();"></button>
    <div aria-labelledby="more" ngbDropdownMenu class="c-menu-drop">
      <button (click)="onEditClick(item)" class="dropdown-item c-option custom-dropdown-item">
        <span [appIcon]="'edit'" [size]="20" class="c-dropdown-icon"></span>
        {{'receiver.itemMenu.edit' | translate}}
      </button>
      <button (click)="onRemoveClick()" class="dropdown-item c-option custom-dropdown-item">
        <span [appIcon]="'delete'" [size]="20" class="c-dropdown-icon"></span>
        {{'receiver.itemMenu.remove' | translate}}
      </button>
    </div>
  </div>
</div>


<!--<div class="item-text">-->
<!--<div class="label" *ngIf="displayCategoryLabel">-->
<!--<span *ngIf="subType">{{subType | translate}} </span>-->
<!--<span>{{contactType | translate}}</span>-->
<!--</div>-->
<!--<div class="value">{{value}}</div>-->
<!--</div>-->