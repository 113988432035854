import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { PageType } from '../../../common/enums/page.type';
import { StoreService } from '../../../core/services/store.service';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { FeatureType } from '../../../common/enums/feature.type';
import { Side } from '../../../common/classes/side';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { PersistanceService } from '../../../core/services/persistance.service';
import { ICodeInputConfig } from '../../../../libs/code-input/base';


@Component({
  templateUrl: './postcode-auth.component.html',
  styleUrls: ['./postcode-auth.component.scss'],
})
export class PostcodeAuthComponent implements OnInit, AfterViewInit, OnDestroy {

  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.DOBAuthentication;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  loaded = false;
  showLoader = true;
  showInputError = false;
  showVerificationError = false;
  showTooManyRequestError = false;
  enableSubmitButton = false;

  public postcodeModel: [number?, number?, number?, number?, number?] = [];

  phoneNumber = '';
  link = '';
  anchor = '';
  tooManyRequestLink = '';

  @Input() tanRecipient = '';

  constructor(
    public storeService: StoreService,
    private appNavService: AppNavService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private translate: TranslateService,
    private persistanceService: PersistanceService,
  ) { }

  ngOnInit(): void {
    this.appNavService.currentPageType = this.pageType;
    this.appNavService.currentPageIndex = this.storeService?.setup?.sequence.indexOf(this.pageType) || 0;
    this.setup = this.storeService.setup;

    this.phoneNumber = this.translate.instant('sender.phoneNumber');
    this.link = this.translate.instant('sender.link');
    this.anchor = this.translate.instant('sender.anchor');
    this.tooManyRequestLink = this.translate.instant('birthday.tooManyRequestLink');
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showLoader = false;
      this.loaded = true;
    }, 3000);
  }

  changed() {
    this.enableSubmitButton = this.postcodeModel.length === 5
      && this.postcodeModel.every((c) => !!c);
  }

  onSubmit() {
    this.showLoader = true;

    this.storeService.setAuthenticationCode(null);
    const code = this.postcodeModel.join('');
    this.hideErrors();

    this.apiService.checkVerificationCode(
      this.storeService.token,
      code,
      this.activatedRoute.snapshot.queryParams.source,
    ).subscribe(
      () => {
        this.showVerificationError = false;
        this.apiService.getAll(this.storeService.token, { code: code }).subscribe(() => {
          this.storeService.setAuthenticationCode(code);

          const requestedPage = Number(this.persistanceService.getItem('requestedPage'));
          if (requestedPage) {
            this.appNavService.goToPageType(
              requestedPage,
              {
                code: code,
                source: this.activatedRoute.snapshot.queryParams.source,
              },
            );
          } else {
            this.appNavService.goToNext(
              this.storeService.setup.sequence,
              this.pageType,
              {
                code: code,
                source: this.activatedRoute.snapshot.queryParams.source,
              },
            );
          }

          this.showLoader = false;
        },
        );
      },
      (response) => {
        if (!response || response instanceof HttpErrorResponse) {
          if (response.status && response.status === 429) {
            this.showError('tooManyRequestsError');
          } else {
            this.showError('inputError');
          }
        } else {
          this.showError('verificationError');
        }

        this.showLoader = false;
      },
    );
  }

  showError(error: 'tooManyRequestsError' | 'inputError' | 'verificationError') {
    this.hideErrors();
    this.storeService.setBlocked(true);

    switch (error) {
      case 'tooManyRequestsError': {
        this.showTooManyRequestError = true;
      } break;

      case 'inputError': {
        this.showInputError = true;
      } break;

      case 'verificationError': {
        this.showVerificationError = true;
      } break;
    }


  }

  hideErrors(): void {
    this.storeService.setBlocked(false);
    this.showTooManyRequestError = false;
    this.showInputError = false;
    this.showVerificationError = false;
  }

}
