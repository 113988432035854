import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IFormField } from '@c-fields/common';

import { filter, map } from 'rxjs/operators';

import { FormDirective } from '@c-form';

import { ERROR_MESSAGES } from '../../../form-field/components/form-field/error-messages.const';


@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: [
    './form-group.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent {

  @Input()
  public field: IFormField;

  @Input()
  public form: FormGroup;

  private _form: FormDirective = inject(FormDirective);

  // eslint-disable-next-line
  public sectionError$ = this._form.errors$
    .pipe(
      filter((errors) => {
        return !!errors.find((error) => {
          return error.path === `${this.field.section}.${this.field.type}`;
        });
      }),
      map((e) => e[0]),
      map((e) => {
        return ERROR_MESSAGES[e.code] || e.message;
      }),
    );
}
