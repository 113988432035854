<div class="c-person-inline custom-person-inline">
  <div class="c-img-container">
    <img src="/assets/img/allianz/user.svg" width="16" style="margin-top: -1px">
  </div>
  <span *ngIf="firstName" data-cy="first-name-label">
    {{ firstName }}&nbsp;
  </span>
  <span *ngIf="lastName" data-cy="last-name-label">
    {{ lastName }}
  </span>
</div>
