import { Component, OnDestroy, OnInit } from '@angular/core';

import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeviceDetectorService } from 'ngx-device-detector';

import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { PageType } from '../../../common/enums/page.type';
import { PersonInterface } from '../../../common/interfaces/person.interface';
import { FeatureType } from '../../../common/enums/feature.type';
import { StoreService } from '../../../core/services/store.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { VCardFactory } from '../../../vcard/vcard.factory';
import { Side } from '../../../common/classes/side';
import { VCardService } from '../../../vcard/vcard.service';
import { DataService } from '../../../core/services/data.service';
import { scrollToTop } from '../../../common/functions/scroll-to-top.function';
import { ApiService } from '../../../core/services/api.service';
import { PersistanceService } from '../../../core/services/persistance.service';


@Component({
  selector: 'app-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss'],
})
export class SharingComponent implements OnInit, OnDestroy {

  setup: SetupInterface;
  pageType = PageType.Sharing;
  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  sender: Side;
  person: PersonInterface;
  customMessage: string;
  senderVCardString: string;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  showLoader = false;
  /* ----------------------------------------
   * Life-Cycles
   * -------------------------------------- */
  private onDestroySubject = new Subject();
  onDestroy$ = this.onDestroySubject.asObservable();

  constructor(
    private storeService: StoreService,
    private appNavService: AppNavService,
    private deviceService: DeviceDetectorService,
    private dataService: DataService,
    private apiService: ApiService,
    private persistanceService: PersistanceService,
  ) {

  }

  ngOnInit(): void {
    this.appNavService.currentPageType = this.pageType;
    this.appNavService.currentPageIndex = this.storeService.setup.sequence.indexOf(this.pageType);
    scrollToTop();
    this.setup = this.storeService.setup;
    this.sender = { ...this.storeService.sender };
    this.person = this.sender.person;
    this.customMessage = this.sender.message;
    if (this.setup.features[this.pageType][FeatureType.SharingVcard]) {
      this.makeSenderVcard();
    }

    if (this.sender.person.imageUrl && this.sender.person.imageUrl.trim() !== '') {
      this.dataService.getPhotoAsBase64(this.sender.person.imageUrl)
        .then((imageBase64: string) => this.storeService.setSenderVcardPhoto(imageBase64));
    } else {
      this.storeService.setSenderVcardPhoto('');
    }

  }

  ngOnDestroy() {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
    this.onDestroySubject.next(true);
    this.onDestroySubject.complete();
  }

  onReplyClick() {
    this.appNavService.goToNext(this.setup.sequence, this.pageType);
  }

  onDownloadSenderVCardClick() {
    const filename = VCardService.makeFileName(this.sender.person.name.firstName, this.sender.person.name.lastName);
    const deviceInfo = this.deviceService.getDeviceInfo();
    const isMobileSafari = deviceInfo.os === 'iOS' && deviceInfo.browser === 'Safari';
    VCardService.download(this.senderVCardString, filename, isMobileSafari);
    this.apiService.trackVcard(this.storeService.token).subscribe(() => { });
  }

  private makeSenderVcard() {

    this.senderVCardString = null;
    combineLatest([
      this.storeService.sender$,
      this.storeService.senderPhoto$,
    ]).pipe(takeUntil(this.onDestroy$))
      .subscribe(([sender, photo]) => {
        const items = [
          ...this.sender.homeInfoItems,
          ...this.sender.workInfoItems,
        ];
        const factory = new VCardFactory(items, {
          firstName: sender.person.name.firstName,
          lastName: sender.person.name.lastName,
        }, photo, this.person.nameSuffix);
        factory.prepareCardString();
        this.senderVCardString = factory.cardString;
      });
  }

}
