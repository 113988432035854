import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { Side } from '../../../common/classes/side';
import { PageType } from '../../../common/enums/page.type';
import { FeatureType } from '../../../common/enums/feature.type';
import { StoreService } from '../../../core/services/store.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { PersistanceService } from '../../../core/services/persistance.service';


@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss'],
})
export class PincodeComponent implements OnInit, OnDestroy {


  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.Pincode;
  n1 = { value: null };
  n2 = { value: null };
  n3 = { value: null };
  n4 = { value: null };
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  showLoader = false;
  isAlright: boolean;

  error3timesWrongPin = 'Sie haben Ihre PIN dreimal falsch eingegeben. Leider funktioniert die Adressänderung nun auf diesem Wege nicht mehr. Bitte teilen Sie uns die neue Adresse über den Telekom Kundenservice unter der Rufnummer 0800 33 01000 mit. Vielen Dank.';
  errorWrongPin = 'Sie haben Ihre PIN falsch eingegeben. Bitte überprüfen Sie, ob Sie die PIN, die wir Ihnen per SMS geschickt haben, richtig eingegeben haben.';
  error = this.errorWrongPin;

  constructor(
    public storeService: StoreService,
    private appNavService: AppNavService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private persistanceService: PersistanceService,
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  onSendClick() {
    this.showLoader = true;
    const source = this.activatedRoute.snapshot.queryParams.source;
    const code = this.activatedRoute.snapshot.queryParams.code;
    // this.saveReceiver().subscribe(_ => {
    this.apiService.getAll(this.storeService.token, { 'source': source, 'code': code }).subscribe();


    setTimeout(() => {
      this.showLoader = false;
    }, 2000);

    this.appNavService.goToNext(this.storeService.setup.sequence, this.pageType, this.activatedRoute.snapshot.queryParams);
    // });
  }

  onEnter(e) {

  }

  validateInput(e) {

  }

  onBlur(e) {

  }

  onInputChange(e) {

  }


}
