import { PageType } from '../../../common/enums/page.type';
import { FieldType } from '../../../common/enums/field.type';
import { FeatureType } from '../../../common/enums/feature.type';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { VerificationType } from '../../../common/enums/verification-type.enum';


export const flowBSplitSetup: SetupInterface = {
  flow: 'flow_b_split',
  company: 'standard',
  sequence: [
    PageType.Answering,
    PageType.Preference,
    PageType.Sharing,
  ],
  fields: {
    [PageType.Answering]: {
      [FieldType.Salutation]: true,
      [FieldType.FirstName]: true,
      [FieldType.LastName]: true,
      [FieldType.Position]: true,
      [FieldType.Company]: true,
      [FieldType.Website]: true,
      [FieldType.HomeAddress]: true,
      [FieldType.HomeEmail]: true,
      [FieldType.HomePhone]: true,
      [FieldType.WorkAddress]: true,
      [FieldType.WorkEmail]: true,
      [FieldType.WorkPhone]: true,

    },
    [PageType.Preference]: {
    },
    [PageType.Deleting]: {
      [FieldType.DeleteOption1]: true,
      [FieldType.DeleteOption2]: true,
      [FieldType.DeleteOption3]: true,
      [FieldType.DeleteOption4]: true,
    },
  },
  features: {
    [PageType.Answering]: {
      [FeatureType.SenderMessage]: true,
      [FeatureType.SenderMessageCasual]: false,
      [FeatureType.Person]: true,
      [FeatureType.ReceiverMessage]: false,
      [FeatureType.ReceiverMessageCasual]: false,
      [FeatureType.WorkFolder]: true,
      [FeatureType.WorkFolderOpen]: true,
      [FeatureType.HomeFolder]: false,
      [FeatureType.HomeFolderOpen]: false,
      [FeatureType.LegalHtml]: false,
      [FeatureType.Preferences]: false,
      [FeatureType.PreferenceMessage]: false,
      [FeatureType.PreferenceToggle]: false,
      [FeatureType.PreferenceOpen]: false,
      [FeatureType.PreferenceColored]: false,
      [FeatureType.NextTitleForSendButton]: true,
      [FeatureType.Steps]: true,
      [FeatureType.ShowDefaultConsents]: true,
    },
    [PageType.Sharing]: {
      [FeatureType.SharingMessage]: true,
      [FeatureType.SharingMessageCasual]: false,
      [FeatureType.SharingContactList]: true,
      [FeatureType.SharingVcardLater]: false,
      [FeatureType.SharingVcard]: true,
      [FeatureType.Steps]: true,
    },
    [PageType.Preference]: {
      [FeatureType.Preferences]: true,
      [FeatureType.PreferenceMessage]: true,
      [FeatureType.LegalHtml]: true,
      [FeatureType.NextTitleForSendButton]: false,
      [FeatureType.PreferenceToggle]: false,
      [FeatureType.PreferenceOpen]: true,
      [FeatureType.PreferenceColored]: true,
      [FeatureType.Steps]: true,
      [FeatureType.ShowDefaultConsents]: true,
    },
    [PageType.DOBAuthentication]: {
      [FeatureType.TopBar]: false,
    },
  },
  limits: {
    [PageType.Answering]: {},
  },
  readOnly: {
    [PageType.Answering]: {},
  },
  required: {
    [PageType.Answering]: {},
  },
  verificationType: VerificationType.None,
  includeEmployee: true,
  showTopbar: true,
  showContractNumber: false,
};
