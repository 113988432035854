import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

import { IFieldConfig } from '../interfaces/field-config.interface';
import { Section } from '../enums/section.enum';
import { Field } from '../enums/field.enum';
import { FormControlBuilder } from '../builders/form-control.builder';
import { IFormField } from '../interfaces/form-field.interface';
import { IFieldsData } from '../interfaces/fields-data.interface';
import { IFieldInput } from '../interfaces/field-input.interface';


/**
 * As mentioned in IFormField inteface – every Field in Web App must implement interface
 * BaseField is a helper class
 * which already has implemented some of the methods delcared in inteface
 *
 * Any Field in a system can be implemented without inheritance from BaseField,
 * but this class can help you to follow DRY principle
 *
 * See also SimpleField helper class
 */
export abstract class BaseField<
  T extends unknown = unknown,
  TFormArray extends AbstractControl = any,
> implements IFormField {

  protected _config: IFieldConfig;
  protected _initialValues: T[] = [];
  protected _formArray: FormArray<TFormArray> = new FormArray([]);

  constructor(config: IFieldConfig) {
    this._config = config;
  }

  public get config(): IFieldConfig {
    return this._config;
  }

  public get section(): Section {
    return this._config.section;
  }

  public get type(): Field {
    return this._config.type;
  }

  public get initialValues(): T[] {
    return this._initialValues;
  }

  public get formArray(): FormArray {
    return this._formArray;
  }

  public get multiple(): boolean {
    return this._config.limit > 1;
  }

  protected get _nextIndex(): number {
    return this._formArray.length;
  }

  protected get _canAddEmptyFormControl(): boolean {
    return this.multiple
      && this.formArray.controls.length < this.config.limit;
  }

  public initWithValues(values: IFieldsData): void {
    this._setInitialValues(values);

    if (this._initialValues.length === 0) {
      this.addEmptyFormControl();
    }
  }

  public removeEmptyFormControls(): void {
    if (!this.multiple) {
      return;
    }

    const values: unknown[] = this._formArray.value;
    if (values.length === 1) {
      return;
    }

    for (let i = values.length - 1; i >= 0; i--) {
      const isLastIndex = i === values.length - 1;
      const currValueEmpty = this._isValueEmpty(values[i]);
      if (!isLastIndex && currValueEmpty) {
        this.formArray.removeAt(i, { emitEvent: false });
      }
    }
  }

  /**
   * Returns new form control with validators & initial value
   */
  protected _createNewFormControl<TCValue extends unknown = unknown>(
    index: number,
    value: TCValue,
  ): FormControl<TCValue> {
    const builder = this._buildFormControl(index);

    builder.withValue(value);

    return builder.build<TCValue>();
  }

  /**
   * Build for control & apply validators.
   * Method can be overridden and rules for applying validators can be changed
   */
  protected _buildFormControl(index: number): FormControlBuilder {
    const builder = new FormControlBuilder();

    if (this.config.required && index === 0) {
      builder.addRequiredValidator();
    }

    if (this.config.readonly) {
      builder.makeDisabled();
    }

    return builder;
  }

  public abstract addEmptyFormControl(): void;
  public abstract addEmptyFormControlIfNeeded(): void;
  public abstract toFormArray(): FormArray<TFormArray>;
  public abstract toJSON(): IFieldInput[];

  protected abstract _setInitialValues(values: IFieldsData): void;
  protected abstract _isValueEmpty(value: unknown): boolean;

}
