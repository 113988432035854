import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-person-section',
  templateUrl: './person-section.component.html',
  styleUrls: ['./person-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonSectionComponent {

  @Input()
  public form: FormGroup;

  private _editMode = false;

  public get firstNameControl(): FormControl {
    return (this.form.get('first_name') as FormArray)?.at(0) as FormControl;
  }

  public get lastNameControl(): FormControl {
    return (this.form.get('last_name') as FormArray)?.at(0) as FormControl;
  }

  public get editMode(): boolean {
    return this._editMode;
  }

  public enterEditMode(): void {
    this._editMode = true;
  }
}
