<ng-container [formGroup]="formGroup" [skipPath]="true">
  <div class="d-flex flex-row">
    <form-field class="flex-grow-1">
      <field-label data-cy="full-address-field-street-label">
        {{'labelTexts.street' | translate}}
      </field-label>

      <input [formControlName]="inputType.Street"
             fieldInput
             type="text"
             class="form-control c-input"
             [placeholder]="'receiver.placeholders.street' | translate"
             data-cy="full-address-field-street">
    </form-field>

    <form-field class="house-number">
      <field-label data-cy="full-address-field-house-label">
        {{'labelTexts.house' | translate}}
      </field-label>
      <input [formControlName]="inputType.HouseNumber"
             fieldInput
             type="text"
             class="form-control c-input"
             [placeholder]="'receiver.placeholders.house' | translate"
             data-cy="full-address-field-house">
    </form-field>
  </div>

  <form-field>
    <field-label data-cy="full-address-field-extra3-label">
      {{'labelTexts.extra3.landline' | translate}}
    </field-label>

    <input [formControlName]="inputType.Extra3"
           fieldInput
           type="text"
           class="form-control c-input"
           [placeholder]="'receiver.placeholders.extra3.landline' | translate"
           data-cy="full-address-field-extra3">
  </form-field>

  <div class="d-flex flex-row">
    <form-field class="postcode">
      <field-label data-cy="full-address-field-postcode-label">
        {{'labelTexts.postcode' | translate}}
      </field-label>

      <input [formControlName]="inputType.ZipCode"
             fieldInput
             type="text"
             class="form-control c-input"
             [placeholder]="'receiver.placeholders.postcode' | translate"
             data-cy="full-address-field-postcode">
    </form-field>

    <form-field class="flex-grow-1">
      <field-label data-cy="full-address-field-city-label">
        {{'labelTexts.city' | translate}}
      </field-label>

      <input [formControlName]="inputType.City"
             fieldInput
             type="text"
             class="form-control c-input"
             [placeholder]="'receiver.placeholders.city' | translate"
             data-cy="full-address-field-city">
    </form-field>
  </div>

  <form-field>
    <field-label data-cy="full-address-field-addition-label">
      {{'labelTexts.addition' | translate}}
    </field-label>

    <input [formControlName]="inputType.Addition"
           fieldInput
           type="text"
           class="form-control c-input"
           [placeholder]="'receiver.placeholders.addition' | translate"
           data-cy="full-address-field-addition">
  </form-field>

  <form-field>
    <field-label data-cy="full-address-field-country-label">{{'labelTexts.country' | translate}}</field-label>

    <nz-select [formControlName]="inputType.Country"
               fieldInput
               autocomplete="off"
               name="country"
               nzShowSearch
               class="form-control c-form-control c-select"
               nzPlaceHolder="{{'receiver.placeholders.country' | translate}}"
               data-cy="full-address-field-country">
      <nz-option *ngFor="let option of countryOptions" [nzValue]="option.value"
                 [nzLabel]="option.label"
                 [attr.data-cy]="'full-address-field-country-option-' + option.value">
      </nz-option>
    </nz-select>
  </form-field>
</ng-container>
