import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { IconDirective } from '@common/directives';

import { NgxVcardModule } from 'ngx-vcard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MarkdownModule } from 'ngx-markdown';

import { MessagesComponent } from './messages/messages.component';
import { TopHeaderComponent } from './top-header/top-header.component';
import { InfoItemComponent } from './info-item/info-item.component';
import { InfoInputComponent } from './info-input/info-input.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { MailtoComponent } from './mailto/mailto.component';
import { InfoComponent } from './info/info.component';
import { SenderMessageElementComponent } from './sender-message-element/sender-message-element.component';
import { PersonElementComponent } from './person-element/person-element.component';
import { AvatarEditComponent } from './avatar-edit/avatar-edit.component';
import { ReceiverMessageElementComponent } from './receiver-message-element/receiver-message-element.component';
import { FoldersElementComponent } from './folders-element/folders-element.component';
import { LegalElementComponent } from './legal-element/legal-element.component';
import { SenderContactsComponent } from './sender-contacts/sender-contacts.component';
import { PreferencesElementComponent } from './preferences-element/preferences-element.component';
import { StepsElementComponent } from './steps-element/steps-element.component';
import { AppTopBarComponent } from './topbar/app-topbar.component';
import { PersonInfoComponent } from './person-info/person-info.component';
import { SafeHtml } from './safe.pipe';
import { PopoverComponent } from './popover/popover.component';


const pipes = [
  SafeHtml,
];

const components = [
  MessagesComponent,
  TopHeaderComponent,
  InfoItemComponent,
  InfoInputComponent,
  AppLoaderComponent,
  MailtoComponent,
  InfoComponent,
  AvatarEditComponent,
  SenderContactsComponent,
  SenderMessageElementComponent,
  PersonElementComponent,
  ReceiverMessageElementComponent,
  FoldersElementComponent,
  LegalElementComponent,
  PreferencesElementComponent,
  StepsElementComponent,
  AppTopBarComponent,
  PersonInfoComponent,
];

const modules = [
  CommonModule,
  RouterModule,
  TranslateModule,
  FormsModule,
  NgxVcardModule,
  NgbModule,
  ImageCropperModule,
  NgxPrettyCheckboxModule,
  NzSelectModule,
  NzIconModule,
  NzAutocompleteModule,
  NzToolTipModule,
  MarkdownModule.forRoot(),
];

@NgModule({
  declarations: [
    components,
    pipes,
    PopoverComponent,
  ],
  imports: [
    modules,
    IconDirective,
  ],
  exports: [
    modules,
    components,
    pipes,
    PopoverComponent,
    IconDirective,
  ],
})
export class SharedModule { }
