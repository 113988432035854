export enum VCardItemType {
  HOME = 'HOME',
  WORK = 'WORK',
  INTERNET = 'INTERNET',
  CELL = 'CELL',
  VOICE = 'VOICE',
  FAX = 'FAX'


}
