export class VCardService {


  public static download(vCardAsString: string, fileName: string, isMobileSafari: boolean) {
    const data = isMobileSafari ? window.btoa(vCardAsString) : this.getVCardAsBlob(vCardAsString);
    const filename = `${fileName  }.vcf`;
    this.save(data, filename, isMobileSafari);
  }

  private static save(data, filename, isMobileSafari) {
    if (isMobileSafari) {
      // Trick for ios safari blob bug
      /** @type {?} */
      const a = document.createElement('a');
      ((/** @type {?} */ (a))).style.display = 'none';
      const base64url = `data:text/vcard;base64,${data}`;
      a.title = filename;
      a.target = '_blank';
      a.download = filename;
      a.href = base64url;
      a.click();
      // document.body.removeChild(a);
    } else {
      // IE 11
      if ((window.navigator as any).msSaveBlob) {
        (window.navigator as any).msSaveBlob(data, filename);
      } else {
        /** @type {?} */
        const a = document.createElement('a');
        /** @type {?} */
        const url = URL.createObjectURL(data);
        ((/** @type {?} */ (a))).style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    }
  }

  private static getVCardAsBlob(vCardAsString: string) {
    /** @type {?} */
    // const data = this.getVCardAsString(vCard);
    // return new Blob([data], {type: 'text/xml'});
    return new Blob([vCardAsString], { type: 'text/vcard' });
  }

  public static makeFileName(firstName: string, lastName: string) {
    let filename = 'vcard_';

    if (firstName && firstName.trim() !== '') {
      filename += `${firstName.replace(' ', '_')  }_`;
    }

    if (lastName && lastName.trim() !== '') {
      filename += `${lastName.replace(' ', '_')  }_`;
    }

    filename += Date.now().toString();

    return filename.toLowerCase();
  }

}
