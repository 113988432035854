import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';

import { CategoryType } from '../../common/enums/category.type';
import { PersonInterface } from '../../common/interfaces/person.interface';
import { StoreService } from '../../core/services/store.service';
import { SetupInterface } from '../../common/interfaces/setup.interface';
import { PageType } from '../../common/enums/page.type';
import { FieldType } from '../../common/enums/field.type';
import { FeatureType } from '../../common/enums/feature.type';


@Component({
  selector: 'app-person-element',
  templateUrl: './person-element.component.html',
  styleUrls: ['./person-element.component.scss'],
})
export class PersonElementComponent implements OnInit, OnDestroy {

  @Input() person: PersonInterface;
  @Input() pageType?: PageType;
  @Input() displayOnly?: boolean;
  @Input() label?: string;
  @Input() highlightMandatoryFields?: boolean;

  setup: SetupInterface;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  isNameEditMode: boolean;
  isAvatarEditMode: boolean;
  avatarPreview: string;
  categoryType = CategoryType;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  fieldType = FieldType;
  featureType = FeatureType;
  /* ----------------------------------------
   * Life-Cycles
   * -------------------------------------- */
  private onDestroySubject = new Subject();
  onDestroy$ = this.onDestroySubject.asObservable();
  /* ----------------------------------------
   * Autocompletes
   * -------------------------------------- */
  salutationTranslations: string[] = [
    '',
    'salutations.mr',
    'salutations.ms',
    'salutations.spouses',
    'salutations.notSpecified',
  ];

  titleTranslations: string[] = [
    '',
    'titles.dr',
    'titles.prof',
    'titles.profDr',
  ];

  constructor(
    public storeService: StoreService,
  ) { }

  ngOnInit(): void {
    this.setup = this.storeService.setup;
  }

  ngOnDestroy() {
    this.onDestroySubject.next(true);
    this.onDestroySubject.complete();
  }

  onEditAvatarClick() {
    this.isAvatarEditMode = true;
    this.avatarPreview = null;
  }

  onAvatarEditingIsDone(nullOrFalseOrBase64: any) {
    this.isAvatarEditMode = false;
    this.avatarPreview = null;
    if (nullOrFalseOrBase64 === false) {
      this.person.imageUrl = '';
      this.storeService.newAvatarBase64 = '';
    } else if (nullOrFalseOrBase64) {
      this.storeService.newAvatarBase64 = nullOrFalseOrBase64;
    }
  }
}
