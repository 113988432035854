import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { finalize, Observable } from 'rxjs';

import { TelService } from '../../../core/services/tel.service';
import { Phone } from '../../../common/classes/phone';
import { PageType } from '../../../common/enums/page.type';
import { StoreService } from '../../../core/services/store.service';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { FeatureType } from '../../../common/enums/feature.type';
import { Side } from '../../../common/classes/side';
import { scrollToTop } from '../../../common/functions/scroll-to-top.function';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { PersonInterface } from '../../../common/interfaces/person.interface';
import { InfoItemType, InfoItemTypeCheck } from '../../../common/enums/info-item.type';
import { InfoItem } from '../../../common/classes/Info-item';
import { FullAddress } from '../../../common/classes/full-address';
import { Email } from '../../../common/classes/email';
import { Consent } from '../../../common/classes/consent';
import { ConsentType } from '../../../common/enums/consent.type';
import { PopoverService } from '../popover.service';
import { PersistanceService } from '../../../core/services/persistance.service';
import { arrayIncludesSubset } from '../../../common/functions/array-includes';
import { LoaderService } from '../../../../libs/loader';
import { environment } from '../../../../environments/environment';
import { timeoutCallback } from '../../../../libs/common/rxjs';


@Component({
  selector: 'app-answering',
  templateUrl: './answering.component.html',
  styleUrls: ['./answering.component.scss'],
  providers: [PopoverService],
})
export class AnsweringComponent implements OnInit, AfterViewInit, OnDestroy {

  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.Answering;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  highlightMandatoryFields = false;
  /* ----------------------------------------
   * Tracking changes
   * -------------------------------------- */
  initialEmailValue: string;
  initialPreferences: Consent[];
  /* ----------------------------------------
   * Telekom Specific
   * -------------------------------------- */
  lineOne: string;
  lineTwo: string;
  lineTwoIsPhoneNumber = false;

  validationError = '';

  constructor(
    public storeService: StoreService,
    private appNavService: AppNavService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    public popoverService: PopoverService,
    private translate: TranslateService,
    private telService: TelService,
    private persistanceService: PersistanceService,
    private _loaderService: LoaderService,
  ) {
  }

  ngOnInit(): void {
    this.persistanceService.removeItem('isEmailChanged');
    this.persistanceService.removeItem('isReoConsentGiven');

    this.appNavService.currentPageType = this.pageType;
    this.appNavService.currentPageIndex = this.storeService.setup?.sequence.indexOf(this.pageType);

    // Initial values:
    this.initialEmailValue = this.getHomeEmailValueOrNull(this.storeService.receiver.homeInfoItems);
    this.initialPreferences = this.storeService.preferences;

    // Telekom specific
    if (this.telService.isTel()) {
      if (arrayIncludesSubset(this.storeService.receiver.tags, 'mobilfunk')) {
        this.lineOne = 'Mobilfunk';
      } else if (arrayIncludesSubset(this.storeService.receiver.tags, 'festnetz')) {
        this.lineOne = 'Festnetz';
      }
    }

    this.lineTwo = this.storeService.contractNumber || null;

    // prepare the salutation translation
    if (this.storeService.receiver?.person?.salutation != '') {
      this.storeService.receiver.person.salutation = `salutations.${this.storeService.receiver.person.salutation}`;
    }

    // prepare the title translation
    if (this.storeService.receiver?.person?.title != '') {
      this.storeService.receiver.person.title = `titles.${this.storeService.receiver.person.title}`;
    }

    scrollToTop();
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  getHomeEmailValueOrNull(homeItems: InfoItem[]) {
    const emailItem: Email = homeItems.find((item: InfoItem) => item.type === InfoItemType.EMAIL) as Email;

    return emailItem?.email || null;
  }

  getHomeMobileValueOrNull(homeItems: InfoItem[]) {
    const mobileItem: Phone = homeItems.find((item: InfoItem) =>
      InfoItemTypeCheck.isPhone(item.type) && (item as Phone).isMobile,
    ) as Phone;

    return mobileItem?.value || null;
  }

  ngAfterViewInit() {
    this.scrollToPreferencesStuff();

    // If the current flow requires two factor authorization and no code is present as an URL param we redirect to the 2FA page
    if (this.storeService.setup?.flow === 'flow_2fa_a_compact' && !this.activatedRoute.snapshot.queryParams.code) {
      this.appNavService.goToAuthenticate(this.activatedRoute.snapshot.queryParams);
    }

    this.popoverService.watchTargets('a', 'data-tooltip');
  }

  scrollToPreferencesStuff() {
    const scrollParam = this.activatedRoute.snapshot.queryParams.scroll;
    if (scrollParam === 'pref') {
      this.storeService.isScrollToPref = true;
      setTimeout(() => {

        const el = document.getElementById('preferences');
        if (el) {
          const topPos = el.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({
            top: topPos, // scroll so that the element is at the top of the view
            behavior: 'smooth', // smooth scroll
          });
        }
      }, 200);

    }
  }

  onSendClick() {
    this.validationError = '';

    if (this.hasError()) {
      this.translate.get('validation.mandatory_fields_missing').subscribe((translation) => {
        this.validationError = translation;
      });

      return;
    }

    const source = this.activatedRoute.snapshot.queryParams.source;
    const code = this.activatedRoute.snapshot.queryParams.code;

    this._loaderService.showLoader();

    this.saveReceiver()
      .pipe(
        timeoutCallback(() => {
          this._loaderService.showSubmitTimeout();
        }, environment.shortRequestTimeout),
        finalize(() => {
          this._loaderService.hideLoader();
        }),
      )
      .subscribe(
        (data) => {
          if (!data || data instanceof HttpErrorResponse) {
            if (data.error.validation_errors && data.error.validation_errors.length > 0) {
              this.validationError = `validation.${data.error.validation_errors[0].key}`;
            } else {
              this.validationError = data.error.message;
            }

            return;
          }
          this.apiService.getAll(this.storeService.token, { 'source': source, 'code': code }).subscribe();
          this.appNavService.goToNext(this.storeService.setup.sequence, this.pageType, this.activatedRoute.snapshot.queryParams);
        },
        (err) => {
          if (err.error && err.error.validation_errors && err.error.validation_errors.length > 0) {
            this.validationError = `validation.${err.error.validation_errors[0].key}`;
          } else {
            this.validationError = err;
          }
        },
      );
  }

  removeValidationError() {
    this.validationError = '';
  }

  checkIfItemHasErrors(item): boolean {
    if (item.hasError) {
      return true;
    }

    if (item.isRequired && !item.isReadOnly) {
      if (!item.value || item.value === '' || item.value === null) {
        return true;
      }
    }
  }

  hasError(): boolean {
    if (!this.storeService.folders[1] || !this.storeService.folders[2]) {
      return false;
    }

    // Checking required items
    const homeAllItems = this.emptyItems(this.storeService.folders[1]?.groups);
    const workAllItems = this.emptyItems(this.storeService.folders[2]?.groups);
    const homeRequiredError = homeAllItems.some(this.checkIfItemHasErrors);
    const workRequiredError = workAllItems.some(this.checkIfItemHasErrors);

    // Checking all other rules
    const homeNotEmptyItems = this.itemsForPut(this.storeService.folders[1]?.groups);
    const workNotEmptyItems = this.itemsForPut(this.storeService.folders[2]?.groups);
    const homeHasError = !homeNotEmptyItems.every((item) => !item.hasError);
    const workHasError = !workNotEmptyItems.every((item) => !item.hasError);

    return homeHasError || workHasError || homeRequiredError || workRequiredError;
  }

  saveReceiver(): Observable<any> {
    const sharedNotEmptyItems = this.storeService.folders.length >= 3 ? this.itemsForPut(this.storeService.folders[0]?.groups) : [];

    const homeNotEmptyItems = this.itemsForPut(this.storeService.folders[1]?.groups);
    const workNotEmptyItems = this.itemsForPut(this.storeService.folders[2]?.groups);

    const emailToSave = this.getHomeEmailValueOrNull(homeNotEmptyItems);
    if (this.initialEmailValue !== emailToSave) {
      this.persistanceService.setItem('isEmailChanged', 'true');
    }

    const reoConsent = this.storeService.preferences.find((consent) => consent.type === ConsentType.Reo);
    if (reoConsent && reoConsent.value) {
      this.persistanceService.setItem('isReoConsentGiven', 'true');
    }

    let mandatoryFieldsMissing = !this.storeService.receiver.person.name.firstName || !this.storeService.receiver.person.name.lastName;

    const checkIfAddressIsMissing = (infoItem: InfoItem) => {
      if (infoItem.type === InfoItemType.FULL_ADDRESS) {

        const castedInfoItem = (infoItem as FullAddress);

        if (infoItem.isRequired && (
          !castedInfoItem.country ||
          !castedInfoItem.street ||
          !castedInfoItem.city ||
          !castedInfoItem.postcode ||
          !castedInfoItem.country)
        ) {
          mandatoryFieldsMissing = true;
        }
      }
    };

    sharedNotEmptyItems.forEach(checkIfAddressIsMissing);
    homeNotEmptyItems.forEach(checkIfAddressIsMissing);
    workNotEmptyItems.forEach(checkIfAddressIsMissing);

    if (mandatoryFieldsMissing) {
      this.highlightMandatoryFields = true;

      const fieldsWithErrors = document.getElementsByClassName('mandatory-field');

      if (fieldsWithErrors.length > 0) {
        fieldsWithErrors[0].scrollIntoView({
          behavior: 'smooth',
        });
      }

      return new Observable((subscriber) => (
        this.translate.get('validation.mandatory_fields_missing').subscribe((translation) => {
          subscriber.error(translation);
        })
      ));
    }

    this.highlightMandatoryFields = false;

    let formattedBirthday = this.storeService.receiver.person.birthday;

    const homeAllItems = this.emptyItems(this.storeService.folders[1]?.groups);
    const updatedBirthdayEmptied = homeNotEmptyItems.find((item) => item.type === InfoItemType.EVENT);
    const updatedBirthdayNotEmptied = homeAllItems.find((item) => item.type === InfoItemType.EVENT);
    const updatedBirthday = updatedBirthdayNotEmptied || updatedBirthdayEmptied;
    if (updatedBirthday) {
      if (updatedBirthday.isClearedOut) {
        formattedBirthday = null;
      } else {
        const numericDay = Number(updatedBirthday.dd);
        const numericMonth = Number(updatedBirthday.mm);
        const numericYear = Number(updatedBirthday.yyyy);

        const dateOfBirth = `${numericYear}-${numericMonth < 10 ? `0${numericMonth}` : numericMonth}-${numericDay < 10 ? `0${numericDay}` : numericDay}`;
        formattedBirthday = new Date(dateOfBirth);
      }
    }

    const updatedPerson: PersonInterface = {
      salutation: this.getOptionName(this.storeService.receiver.person.salutation),
      title: this.getOptionName(this.storeService.receiver.person.title),
      position: this.storeService.receiver.person.position,
      company: this.storeService.receiver.person.company,
      website: this.storeService.receiver.person.website,
      name: {
        firstName: this.storeService.receiver.person.name.firstName,
        lastName: this.storeService.receiver.person.name.lastName,
      },
      nameSuffix: this.storeService.receiver.person.nameSuffix,
      birthday: formattedBirthday,
      imageUrl: this.storeService.receiver.person.imageUrl,
    };

    const updatedReceiver = new Side({
      id: this.storeService.receiver.id,
      handler_id: this.storeService.receiver.handler_id,
      person: updatedPerson,
      message: '',
      workInfoItems: workNotEmptyItems,
      homeInfoItems: homeNotEmptyItems,
      sharedInfoItems: sharedNotEmptyItems,
      tags: this.storeService.receiver.tags,
    });

    return this.apiService.saveAnswer(
      updatedReceiver,
      {
        'code': this.activatedRoute.snapshot.queryParams.code,
        'source': this.activatedRoute.snapshot.queryParams.source,
      },
    );
  }

  getOptionName(value: string) {
    if (value.length > 0 && value.includes('.')) {
      return value.split('.')[1];
    }

    return value;
  }

  itemsForPut(groups) {
    if (!Array.isArray(groups)) {
      return [];
    }

    return groups.reduce((result, group) => {
      group.items.filter((item) => !item.isEmpty()).forEach((item) => {
        result.push(item);
      });

      return result;
    }, []);
  }

  emptyItems(groups) {
    if (!Array.isArray(groups)) {
      return [];
    }

    return groups.reduce((result, group) => {
      group.items.filter((item) => item.isEmpty()).forEach((item) => {
        result.push(item);
      });

      return result;
    }, []);
  }
}
