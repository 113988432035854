<div *ngIf="(!storeService.setup.loaderType)||storeService.setup.loaderType===loaderType.Default"
  class="animation-container">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<div *ngIf="storeService.setup.loaderType===loaderType.Circle" class="animation-container">
  <svg class="spinner__circle" viewBox="0 0 50 50" aria-hidden="true">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="4"></circle>
  </svg>
  <svg class="spinner__circle-background" viewBox="0 0 50 50" aria-hidden="true">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="4"></circle>
  </svg>
</div>