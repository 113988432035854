import { InfoItem } from '../common/classes/Info-item';
import { CategoryType } from '../common/enums/category.type';
import { InfoItemType, InfoItemTypeCheck } from '../common/enums/info-item.type';
import { Address } from '../common/classes/address';
import { Phone } from '../common/classes/phone';
import { Email } from '../common/classes/email';
import { NamesInterface } from '../common/interfaces/names.interface';
import { Org } from '../common/classes/org';
import { Url } from '../common/classes/url';
import { FullAddress } from '../common/classes/full-address';
import { Fax } from '../common/classes/fax';

import { VCardItemType } from './vcard-item.type';
import { asString, getFormattedAddress, isPropertyWithParametersAddressValue, nl, propertyToVCardString, YYYYMMDD } from './vcard.functions';

/*
* VCard version 3
* https://www.ietf.org/rfc/rfc2426.txt
* */
export class VCardFactory {

  photoBase64: string;
  names: NamesInterface;
  infoItems: InfoItem[];
  nameSuffix: string;
  // cardData: CardDataInterface;
  cardString = '';
  card: any;

  constructor(infoItems: InfoItem[], names: NamesInterface, photoBase64?: string, nameSuffix?: string) {
    this.infoItems = infoItems;
    this.names = names;
    this.photoBase64 = photoBase64;
    this.nameSuffix = nameSuffix || '';
  }


  prepareCardString() {
    /* ----------------------------------------
     * Start
     * -------------------------------------- */
    this.addBeginning();
    this.addGeneralInfo();
    /* ----------------------------------------
     * Work
     * -------------------------------------- */
    // this.addOrg();
    this.addWorkAddresses();
    this.addWorkFullAddresses();
    this.addWorkPhone();
    this.addWorkFax();
    this.addWorkEmail();
    this.addWorkUrls();
    /* ----------------------------------------
     * Personal
     * -------------------------------------- */
    this.addNames();
    this.addAvatar();
    // this.addBirthday();
    this.addHomeAddress();
    this.addHomeFullAddress();
    this.addHomePhone();
    this.addHomeFax();
    this.addHomeEmail();
    this.addHomeUrls();
    /* ----------------------------------------
     * End
     * -------------------------------------- */
    this.addEnding();
  }

  /* ----------------------------------------
   * Private
   * -------------------------------------- */

  private addGeneralInfo() {
    this.cardString += `VERSION:3.0${nl()}`;
    this.cardString += `CHARSET:UTF-8${nl()}`;
    // this.cardString += 'IMPP;X-SERVICE-TYPE=Facebook;type=HOME;type=pref:xmpp:facebbbbbb' + nl();
  }

  private addWorkAddresses() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.WORK) && (item.type === InfoItemType.ADDRESS))
      .forEach((item: Address) => this.address(item, true));
  }

  private addWorkFullAddresses() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.WORK) && (item.type === InfoItemType.FULL_ADDRESS))
      .forEach((item: FullAddress) => this.fullAddress(item, true));
  }

  private addWorkPhone() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.WORK) && (InfoItemTypeCheck.isPhone(item.type)))
      .forEach((item: Phone) => this.phone(item, true));
  }

  private addWorkFax() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.WORK) && (item.type === InfoItemType.FAX))
      .forEach((item: Fax) => this.fax(item, true));
  }

  private addHomeAddress() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.PERSONAL) && (item.type === InfoItemType.ADDRESS))
      .forEach((item: Address) => this.address(item, false));
  }

  private addHomeFullAddress() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.PERSONAL) && (item.type === InfoItemType.FULL_ADDRESS))
      .forEach((item: FullAddress) => this.fullAddress(item, false));
  }


  private addHomePhone() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.PERSONAL) && (InfoItemTypeCheck.isPhone(item.type)))
      .forEach((item: Phone) => this.phone(item, false));
  }

  private addHomeFax() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.PERSONAL) && (item.type === InfoItemType.FAX))
      .forEach((item: Fax) => this.fax(item, false));
  }

  private addHomeEmail() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.PERSONAL) && (item.type === InfoItemType.EMAIL))
      .forEach((item: Email) => this.email(item, false));
  }

  private addWorkEmail() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.WORK) && (item.type === InfoItemType.EMAIL))
      .forEach((item: Email) => this.email(item, true));
  }

  private addBirthday() {

    const birthday = this.infoItems
      .find((item) => ((item.categoryType === CategoryType.PERSONAL) && item.type === InfoItemType.EVENT));

    if (birthday) {
      this.cardString += `BDAY:${YYYYMMDD(new Date(birthday.listValue))}${nl()}`;
    }

  }

  private addNames() {
    const nameArray = [
      this.names.firstName,
      this.names.lastName,
    ];

    this.cardString +=
      `N:${
        [
          asString(this.names.lastName),
          asString(this.names.firstName),
          asString(''),
          asString(''),
          asString(this.nameSuffix),
        ].join(';')
      }${nl()}`;

    const fullName = nameArray.filter((string) => string !== null).join(' ');
    this.cardString += `FN:${asString(fullName)}${nl()}`;
  }

  private addOrg() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.WORK) && (item.type === InfoItemType.ORG))
      .forEach((item: Org) => this.org(item, true));
  }

  private addWorkUrls() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.WORK) && (item.type === InfoItemType.URL))
      .forEach((item: Url) => this.url(item, true));
  }

  private addHomeUrls() {
    this.infoItems
      .filter((item) => (item.categoryType === CategoryType.PERSONAL) && (item.type === InfoItemType.URL))
      .forEach((item: Url) => this.url(item, false));
  }

  private addAvatar() {
    if (this.photoBase64 && this.photoBase64.trim() !== '') {
      const isImage = this.photoBase64.slice(5, 10) === 'image';
      if (isImage) {
        const format = this.photoBase64.match(/^data:image\/(.*);base64/i)[1].toUpperCase();
        this.cardString += `PHOTO;ENCODING=b;TYPE=${format}:${  this.photoBase64.replace(/data:image\/(.*);base64,/i, '')}${nl()}`;
      }
    }
  }

  private addEnding() {
    this.cardString += `END:VCARD${nl()}`;

  }

  private addBeginning() {
    this.cardString += `BEGIN:VCARD${nl()}`;
  }

  private address(address: Address, isWork) {

    const value = {
      street: address.street,
      postalCode: address.postcode,
      countryName: address.country,
      locality: address.city,
    };
    const param = isWork ? { type: [VCardItemType.WORK] } : { type: [VCardItemType.HOME] };

    if (isPropertyWithParametersAddressValue({ value: value, param: param })) {
      this.cardString +=
        `ADR${
          propertyToVCardString(((param)))
        }${getFormattedAddress(value)
        }${nl()}`;
    } else {
      this.cardString += `ADR:${getFormattedAddress(value)}${nl()}`;
    }


  }

  private fullAddress(address: FullAddress, isWork) {

    const value = {
      street: `${address.house  } ${address.street} ${address.addition}`,
      postalCode: address.postcode,
      countryName: address.country,
      locality: address.city,
    };
    const param = isWork ? { type: [VCardItemType.WORK] } : { type: [VCardItemType.HOME] };

    if (isPropertyWithParametersAddressValue({ value: value, param: param })) {
      this.cardString +=
        `ADR${
          propertyToVCardString(((param)))
        }${getFormattedAddress(value)
        }${nl()}`;
    } else {
      this.cardString += `ADR:${getFormattedAddress(value)}${nl()}`;
    }


  }

  private phone(item: Phone, isWork: boolean) {
    const param = {
      type: [
        isWork ? VCardItemType.WORK : VCardItemType.HOME,
        item.isMobile ? VCardItemType.CELL : VCardItemType.VOICE,
      ],
    };
    this.cardString += `TEL${propertyToVCardString(param)}:${asString(item.value)}${nl()}`;
  }

  private fax(item: Fax, isWork: boolean) {
    const param = {
      type: [
        isWork ? VCardItemType.WORK : VCardItemType.HOME,
        VCardItemType.FAX,
      ],
    };
    this.cardString += `TEL${propertyToVCardString(param)}:${asString(item.value)}${nl()}`;
  }

  private email(item: Email, isWork: boolean) {
    const param = isWork ? {
      type: [
        VCardItemType.WORK,
      ],
    } : {
      type: [
        VCardItemType.HOME,
      ],
    };
    this.cardString += `EMAIL${propertyToVCardString(param)}:${asString(item.email)}${nl()}`;
  }

  private org(item: Org, isWork: boolean) {
    this.cardString += `ORG:${asString(item.companyName)}${nl()}`;
    this.cardString += `TITLE:${asString(item.jobTitle)}${nl()}`;
  }

  private url(item: Url, isWork: boolean) {
    const param = isWork ? { type: [VCardItemType.WORK] } : { type: [VCardItemType.HOME] };
    this.cardString += `URL${propertyToVCardString(param)}:${asString(item.url)}${nl()}`;
  }


}
