import { Component, OnDestroy, OnInit } from '@angular/core';

import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { PageType } from '../../../common/enums/page.type';
import { FeatureType } from '../../../common/enums/feature.type';
import { StoreService } from '../../../core/services/store.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { scrollToTop } from '../../../common/functions/scroll-to-top.function';
import { PersistanceService } from '../../../core/services/persistance.service';


@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss'],
})
export class PreferenceComponent implements OnInit, OnDestroy {

  setup: SetupInterface;
  pageType = PageType.Preference;

  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  showLoader = false;

  constructor(
    private storeService: StoreService,
    private appNavService: AppNavService,
    private persistanceService: PersistanceService,
  ) {
  }

  ngOnInit(): void {
    this.appNavService.currentPageType = this.pageType;
    this.appNavService.currentPageIndex = this.storeService.setup.sequence.indexOf(this.pageType);
    this.setup = this.storeService.setup;
    scrollToTop();
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  onSendClick() {
    this.showLoader = true;
    this.appNavService.goToNext(this.setup.sequence, this.pageType);
  }

}
