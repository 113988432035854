<div class="c-app-content">
  <link rel="stylesheet" href="authentication.component.scss">
  <app-app-loader *ngIf="showLoader"></app-app-loader>
  <div class="c-header-image-container">
    <img style="height: 100px;" src="{{storeService.sender.person.imageUrl}}"
      *ngIf="storeService.sender.person.imageUrl" />
  </div>
  <div class="c-container">
    <div class="c-content-container">
      <h1 class="c-title">{{"authentication.title" | translate}}</h1>
      <p>{{"authentication.description" | translate}}</p>
      <p><b>{{tanRecipient}}</b></p>
      <p>{{"authentication.outdatedPhoneNumber" | translate}} <a class="btn-link">{{"authentication.learnMoreButton" |
          translate}}</a></p>
      <p *ngIf="!showAuthLoader">{{"authentication.instructions" | translate}}</p>
      <div *ngIf="showAuthLoader"
        style="display: flex; align-items: center; justify-content: center; margin-top: 20px; margin-bottom: 40px;">
        <div class="lds-dual-ring">
          <div class="lock-container">
            <div class="shackle"></div>
            <div class="lock">
              <div class="key-hole">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showAuthLoader" class="c-digit-container">
        <div class="c-digit-input custom-authentication-digit-input">
          <input class="c-input custom-authentication-input" #firstDigitInput [ngModel]="digits.first"
            (keyup)="onInput1KeyUp($event)" autofocus />
        </div>
        <div class="c-digit-spacer"></div>
        <div class="c-digit-input custom-authentication-digit-input">
          <input class="c-input custom-authentication-input" #secondDigitInput [ngModel]="digits.second"
            (keyup)="onInput2KeyUp($event)" />
        </div>
        <div class="c-digit-spacer"></div>
        <div class="c-digit-input custom-authentication-digit-input">
          <input class="c-input custom-authentication-input" #thirdDigitInput [ngModel]="digits.third"
            (keyup)="onInput3KeyUp($event)" />
        </div>
        <div class="c-digit-spacer-large"></div>
        <div class="c-digit-input custom-authentication-digit-input">
          <input class="c-input custom-authentication-input" #fourthDigitInput [ngModel]="digits.fourth"
            (keyup)="onInput4KeyUp($event)" />
        </div>
        <div class="c-digit-spacer"></div>
        <div class="c-digit-input custom-authentication-digit-input">
          <input class="c-input custom-authentication-input" #fifthDigitInput [ngModel]="digits.fifth"
            (keyup)="onInput5KeyUp($event)" />
        </div>
        <div class="c-digit-spacer"></div>
        <div class="c-digit-input custom-authentication-digit-input">
          <input class="c-input custom-authentication-input" #sixthDigitInput [ngModel]="digits.sixth"
            (keyup)="onInput6KeyUp($event)" />
        </div>
      </div>
      <div *ngIf="!showAuthLoader" class="c-button-container">
        <button class="btn btn-primary custom-btn-primary" (click)="onSubmit($event)" [disabled]="!enableSubmitButton">
          {{"authentication.verifyButton" | translate}}
        </button>
      </div>
      <a class="btn-link" (click)="onResendTan($event)">{{"authentication.resendButton" | translate}}</a>
      <div *ngIf="showConfirmation" class="c-confirmation-dialog">
        <b>{{"authentication.confirmationTitle" | translate}}</b>
        <p class="c-description">{{"authentication.confirmationDescription" | translate:{ tanRecipient: tanRecipient }
          }}.</p>
      </div>
      <div *ngIf="showError" class="c-error-dialog">
        <b>{{"authentication.errorTitle" | translate}}</b>
        <p class="c-description">{{"authentication.errorDescription" | translate}}</p>
      </div>
      <div *ngIf="showTimer" class="c-timer-dialog">
        <b>{{"authentication.timerTitle" | translate}}</b>
        <p class="c-description">{{"authentication.timerDescription" | translate:{ time: remainingSeconds } }}</p>
      </div>
    </div>
    <div class="c-text-container">
      <p>{{storeService?.sender?.person?.company}}</p>
      <p>{{"authentication.serviceProvider" | translate}}:</p>
      <p>
        <span>Complero © 2022 Complero GmbH</span><br>
        <span>Im Wiesengrund 49</span><br>
        <span>53539 Kelberg</span>
      </p>
    </div>
  </div>
</div>