import { AbstractControl } from '@angular/forms';

import { isDateValid } from './date/is-date-valid';


export class CValidators {

  public static birthday(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }

    if (!isDateValid(control.value)) {
      return { birthday: true };
    }


    const maxDate = new Date();
    const minDate = new Date(maxDate.getFullYear() - 150, maxDate.getMonth(), maxDate.getDate());
    if (control.value < minDate || control.value > maxDate) {
      return { birthday: true };
    }


    return null;
  }

  public static houseNumber(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }

    const houseRegex = /^[a-zA-Z0-9 \/.-]*$/;
    if (!houseRegex.test(String(control.value))) {
      return { houseNumber: true };
    }

    if (control.value.length > 35) {
      return { houseNumber: true };
    }


    return null;
  }
}
