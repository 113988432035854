import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../settings.service';
import { PageType } from '../../../common/enums/page.type';
import { StoreService } from '../../../core/services/store.service';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { FieldType } from '../../../common/enums/field.type';
import { scrollToTop } from '../../../common/functions/scroll-to-top.function';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';


@Component({
  selector: 'app-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss'],
})
export class AccountDeleteComponent implements OnInit {

  pageType = PageType.Deleting;
  setup: SetupInterface;
  isAgree: boolean;

  fields = [
    { type: FieldType.DeleteOption1, number: 1 },
    { type: FieldType.DeleteOption2, number: 2 },
    { type: FieldType.DeleteOption3, number: 3 },
    { type: FieldType.DeleteOption4, number: 4 },
    { type: FieldType.DeleteOption5, number: 5 },
    { type: FieldType.DeleteOption6, number: 6 },
    { type: FieldType.DeleteOption7, number: 7 },
    { type: FieldType.DeleteOption8, number: 8 },
    { type: FieldType.DeleteOption9, number: 9 },
    { type: FieldType.DeleteOption10, number: 10 },
  ];

  values = {};

  constructor(
    public settingsService: SettingsService,
    private storeService: StoreService,
    private appNavService: AppNavService,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    if (!this.settingsService.selectedAccounts.length) {
      this.appNavService.goToSettingsPage();
    }
    scrollToTop();
    this.setup = this.storeService.setup;
  }

  onDeleteClick() {
    const selected = Object.keys(this.values).filter((key) => this.values[key]);
    const reasons = selected.length ? selected.join('&') : null;
    this.apiService.deleteAccount(this.storeService.token, reasons).subscribe(() => {
      this.storeService.deleteAll();
      this.appNavService.goToHome();
    });
  }

}
