import { NgModule } from '@angular/core';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { IconDirective } from '@common/directives';

import { CFormModule } from '@c-form';

import { FormFieldModule } from '../form-field/form-field.module';
import { AddressFormItemComponent } from '../form-items/address-form-item';
import { SharedModule } from '../../app/shared/shared.module';
import { BirthdayFormItemComponent } from '../form-items/birthday-form-item';

import { FormItemComponent } from './components/form-item';
import { FormSectionComponent } from './components/form-section';
import { FormGroupComponent } from './components/form-group';


@NgModule({
  imports: [
    CFormModule,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    CFormModule,
    FormFieldModule,
    TranslateModule,
    IconDirective,

    // temporary
    SharedModule,

    // Form Components
    AddressFormItemComponent,
    BirthdayFormItemComponent,
  ],
  declarations: [
    FormSectionComponent,
    FormGroupComponent,
    FormItemComponent,
  ],
  exports: [
    FormSectionComponent,
    FormGroupComponent,
  ],
})
export class AnsweringFormLayoutModule {

}
