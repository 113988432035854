import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { ConsentType } from '../../common/enums/consent.type';
import { InfoItem } from '../../common/classes/Info-item';
import { InfoItemType } from '../../common/enums/info-item.type';
import { FullAddress } from '../../common/classes/full-address';
import { PersonInterface } from '../../common/interfaces/person.interface';
import { Side } from '../../common/classes/side';

import { AppNavService } from './app-nav.service';
import { DataService } from './data.service';
import { StoreService } from './store.service';


@Injectable()
export class ReceiverService {

  constructor(
    private storeService: StoreService,
    private dataService: DataService,
    private translateService: TranslateService,
  ) {
  }


}
