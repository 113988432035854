import * as countries from 'i18n-iso-countries';
import german from 'i18n-iso-countries/langs/de.json';

import { InfoItemType } from '../../common/enums/info-item.type';
import { CategoryType } from '../../common/enums/category.type';
import { Email } from '../../common/classes/email';
import { EventDate } from '../../common/classes/event-date';
import { Address } from '../../common/classes/address';
import { Phone } from '../../common/classes/phone';
import { Url } from '../../common/classes/url';
import { FullAddress } from '../../common/classes/full-address';
import { Fax } from '../../common/classes/fax';


export class InfoItemsFactory {

  public static makeInfoItem(makeNew: boolean, isReadOnly: boolean, isRequired: boolean, type: InfoItemType, categoryType: CategoryType, data: any, params: any = {}) {

    const base = {
      type: type,
      categoryType: categoryType,
      isSaved: makeNew ? false : true,
      isFilledOut: makeNew ? false : true,
      isRequired: isRequired,
      isReadOnly: isReadOnly,
      isClearedOut: false,
    };

    switch (type) {
      case InfoItemType.EMAIL:
        return new Email({
          ...base,
          iconName: 'email',
          email: makeNew ? '' : data,
          listValue: makeNew ? '' : data,
        });
      case InfoItemType.EVENT:
        if (!makeNew && data && data.length) {

          const extractedDate = data.match('([0-9]{4})-([0-9]{2})-([0-9]{2})');
          const year = extractedDate[1];
          const month = extractedDate[2];
          const dayDate = extractedDate[3];

          if (dayDate == '01' && month == '01' && year == '0001') {
            return new EventDate({
              ...base,
              iconName: 'event',
              dd: '',
              mm: '',
              yyyy: '',
              listValue: '',
              isSaved: false,
              isFilledOut: false,
            });
          }
          const [d, m, y] = [dayDate, month, year];

          return new EventDate({
            ...base,
            iconName: 'event',
            dd: d,
            mm: m,
            yyyy: y,
            listValue: `${d}.${m}.${y}`,
          });


        } else if (makeNew) {
          return new EventDate({
            ...base,
            iconName: 'event',
            dd: '',
            mm: '',
            yyyy: '',
            listValue: '',
            isSaved: false,
            isFilledOut: false,
          });
        }
        break;
      case InfoItemType.ADDRESS:
        return new Address({
          ...base,
          iconName: 'place',
          city: makeNew ? '' : (data.city || ''),
          country: makeNew ? '' : (data.country || ''),
          postcode: makeNew ? '' : (data.zip_code || ''),
          street: makeNew ? '' : (data.street || ''),
          listValue: makeNew ? '' : this.makeFormattedAddress(data.country, data.zip_code, data.city, data.street),
        });
      case InfoItemType.FULL_ADDRESS:
        return new FullAddress({
          ...base,
          iconName: 'place',
          house: makeNew ? '' : (data.house_number || ''),
          addition: makeNew ? '' : (data.addition || ''),
          city: makeNew ? '' : (data.city || ''),
          country: makeNew ? '' : (data.country || ''),
          postcode: makeNew ? '' : (data.zip_code || ''),
          street: makeNew ? '' : (data.street || ''),
          extra1: makeNew ? '' : (data.extra_1 || ''),
          extra2: makeNew ? '' : (data.extra_2 || ''),
          extra3: makeNew ? '' : (data.extra_3 || ''),
          listValue: makeNew ? '' :
            this.makeFormattedAddressFull(data.country, data.zip_code, data.city, data.street, data.house_number, data.addition),
        });
      case InfoItemType.URL:
        return new Url({
          ...base,
          iconName: 'link',
          url: '',
        });
      case InfoItemType.PHONE || InfoItemType.MOBILE:

        // const hasObject = !!data?.phonenumber;

        // const parserObject = (obj) => {
        //   const code = this.parsePhoneNumber(obj).country_code || '';
        //   const number = this.parsePhoneNumber(obj).national_number || '';
        //   return code !== '' ? '+' + code + number : number;
        // };

        // const value = hasObject ? parserObject(data) : (data || '');
        const value = data || '';

        return new Phone({
          ...base,
          iconName: !!params.isMobile ? 'mobile' : 'phone',
          // phone: phone,
          // code: code,
          value: makeNew ? '' : value,
          listValue: makeNew ? '' : value,
          isMobile: !!params.isMobile,
        });
      case InfoItemType.FAX:
        return new Fax({
          ...base,
          iconName: 'fax',
          value: makeNew ? '' : data || '',
          listValue: makeNew ? '' : data || '',
        });
      // case InfoItemType.ORG:
      //   return new Org({
      //     ...base,
      //     categoryType: CategoryType.WORK,
      //     iconName: 'org',
      //     companyName: makeNew ? '' : data.org,
      //     jobTitle: makeNew ? '' : data.title,
      //     listValue: (data.org ? (data.title + ' - ') : '') + data.jobTitle
      //   });
      default:
        return null;

    }


  }

  private static makeFormattedAddress(country, postcode, city, street): string {

    countries.registerLocale(german);

    // TODO: pass dynamic locale once localization is added back
    const allCountriesByCode = countries.getNames('de', { select: 'official' });

    const trimmedStreet = street ? street.trim() : '';
    const trimmedPostcode = postcode ? postcode.trim() : '';
    const trimmedCity = city ? city.trim() : '';
    const trimmedCountry = country ? country.trim() : '';

    return String(
      (trimmedStreet ? `${trimmedStreet  } ` : '') +
      (trimmedPostcode ? `${trimmedPostcode  } ` : '') +
      (trimmedCity ? `${trimmedCity  }, ` : '') +
      (trimmedCountry ? allCountriesByCode[trimmedCountry] : ''),
    ).trim();
  }

  private static makeFormattedAddressFull(country, postcode, city, street, house, addition): string {

    countries.registerLocale(german);

    // TODO: pass dynamic locale once localization is added back
    const allCountriesByCode = countries.getNames('de', { select: 'official' });

    const trimmedStreet = street ? street.trim() : '';
    const trimmedHouse = house ? house.trim() : '';
    const trimmedPostcode = postcode ? postcode.trim() : '';
    const trimmedCity = city ? city.trim() : '';
    // const trimmedAddition = addition ? addition.trim() : '';
    const trimmedCountry = country ? country.trim() : '';

    return String(
      (trimmedStreet ? trimmedStreet + (trimmedHouse ? ' ' : ', ') : '') +
      (trimmedHouse ? trimmedHouse + (trimmedPostcode ? ', ' : '') : '') +
      (trimmedPostcode ? trimmedPostcode + (trimmedCity ? ' ' : '') : '') +
      (trimmedCity ? trimmedCity + (trimmedCountry ? ', ' : '') : '') +
      // (trimmedAddition ? trimmedAddition + ', ' : '') +
      ((trimmedCountry && allCountriesByCode[trimmedCountry]) ? allCountriesByCode[trimmedCountry] : ''),
    ).trim();
  }

  private static parsePhoneNumber(data) {
    return data.phonenumber.trim().split(' ')
      .reduce((result, part) => {
        return { ...result, [part.split(':')[0]]: part.split(':')[1] };
      }, {});
  }

  private static numberToString(number, digits) {
    let string = String(number);
    const l = string.length;
    const dif = digits - l;
    for (let i = 0; i < dif; i++) {
      string = `0${string}`;
    }

    return string;
  }


}

