import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


import { PageType } from '../../common/enums/page.type';
import { SetupInterface } from '../../common/interfaces/setup.interface';
import { FeatureType } from '../../common/enums/feature.type';
import { StoreService } from '../../core/services/store.service';
import { Consent } from '../../common/classes/consent';
import { ConsentType } from '../../common/enums/consent.type';
import { PopoverService } from '../../pages/answering/popover.service';
import { LegalContentInterface } from '../../common/interfaces/legal-consent.interface';


@Component({
  selector: 'app-preferences-element',
  templateUrl: './preferences-element.component.html',
  styleUrls: ['./preferences-element.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
      })),
      state('closed', style({
        height: '0px',
      })),
      transition('open => closed', [
        animate('0.5s'),
      ]),
      transition('closed => open', [
        animate('0.3s'),
      ]),
    ]),
  ],
})

export class PreferencesElementComponent implements OnInit {

  @Input() pageType: PageType;
  setup: SetupInterface;
  values: [] = [];
  isActive: boolean;

  popupDiv: HTMLDivElement;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  consentType = ConsentType;

  public legalVisible$ = this._legalContent$.pipe(
    map((legal: LegalContentInterface | null) => {
      return !!legal?.subject
        || (!!legal?.description && legal?.description !== '<p><br></p>');
    }),
  );

  public legalFinePrint$: Observable<string> = this._legalContent$.pipe(
    map((legal: LegalContentInterface | null) => {
      if (!legal?.additional || legal?.additional === '<p><br></p>') {
        return '';
      }

      return legal.additional;
    }),
  );

  constructor(
    public storeService: StoreService,
    private _translateService: TranslateService,
  ) {}

  private get _legalContent$(): Observable<LegalContentInterface | null> {
    return merge(
      of(this._translateService.getDefaultLang()),
      this._translateService.onLangChange.pipe(map((event: LangChangeEvent) => event.lang)),
    ).pipe(
      map((lang: string) => {
        return this.storeService.customLegal
          ? (this.storeService.customLegal[lang] || this.storeService.customLegal.de)
          : null;
      }),
    );
  }

  ngOnInit(): void {
    this.setup = this.storeService.setup;
    this.isActive = this.setup.features[this.pageType][this.featureType.PreferenceOpen];
  }
}
