<div class="c-category">
  <div class="c-category-header custom-light-panel"
       [ngClass]="{ 'collapsable': collapsable }"
       (click)="toggleCollapse()">
    <div [appIcon]="icon" [size]="24" class="c-category-icon custom-category-icons"></div>
    <div class="c-category-title custom-category-title">
      {{ name }}
    </div>

    <ng-container *ngIf="collapsable">
      <div *ngIf="collapsed"
           class="c-category-arrow custom-category-arrows"
           [appIcon]="'arrow_down'">
      </div>

      <div *ngIf="!collapsed"
           class="c-category-arrow custom-category-arrows"
           [appIcon]="'arrow_up'">
      </div>
    </ng-container>
  </div>
  <div class="c-category-content" [@openClose]="collapsed ? 'open' : 'closed'">
    <div class="c-container custom-category-container">
      <ng-container *ngFor="let field of fields">
        <app-form-group [field]="field" [form]="form"></app-form-group>
      </ng-container>
    </div>
  </div>
</div>

