import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { StoreService } from '../services/store.service';
import { RouteString } from '../../common/enums/route.string';
import { PageType } from '../../common/enums/page.type';
import { AppNavService } from '../services/app-nav.service';
import { SetupInterface } from '../../common/interfaces/setup.interface';


@Injectable({ providedIn: 'root' })
export class FlowPageGuard  {
  constructor(
    private storeService: StoreService,
    private appNavService: AppNavService,
  ) { }

  checkSequenceAndResolveOrRedirect(setup: SetupInterface, resolve, pageType: PageType) {
    if (setup.sequence.includes(pageType)) {
      resolve(true);
    } else {
      this.redirectToNotFound();
      resolve(false);
    }
  }

  redirectToNotFound() {
    this.appNavService.goToNotFound();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve) => {
      this.storeService.setup$.pipe(take(1)).subscribe((setup) => {
        switch (route.routeConfig.path as RouteString) {
          case RouteString.SHARING:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.Sharing);
            break;
          case RouteString.ANSWERING:
          case RouteString.NEW_ANSWERING:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.Answering);
            break;
          case RouteString.PINCODE:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.Pincode);
            break;
          case RouteString.DONE:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.SimpleDone);
            break;
          case RouteString.PREFERENCE:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.Preference);
            break;
          case RouteString.DELIVERY_NOTICE:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.DeliveryNotice);
            break;
          case RouteString.CONFIRMATION:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.Confirmation);
            break;
          case RouteString.DOB_AUTH:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.DOBAuthentication);
            break;
          case RouteString.SMS_AUTH:
            this.checkSequenceAndResolveOrRedirect(setup, resolve, PageType.Authentication);
            break;
          default:
            break;
        }

      });

    });


  }
}
