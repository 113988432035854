import { IFieldConfig, IFieldsData, Sections, IFieldFactory, Field } from '@c-fields/common';

import { createFieldFromConfig } from './create-fields-from-config';


/**
 * createSection takes Requested Field Configs and creates IFormField instances for them.
 * It uses factoriesMap object and in this object looking
 * for Field Type key (i.e. Email/Address/etc.)
 *
 */
export function createSections(
  configs: IFieldConfig[],
  factoriesMap: Record<string, IFieldFactory>,
  data: IFieldsData,
): Sections {
  const sections: Sections = {};

  configs
    .forEach((fieldConfig: IFieldConfig) => {
      // create section array if its not created
      if (!Array.isArray(sections[fieldConfig.section])) {
        sections[fieldConfig.section] = [];
      }

      const section = sections[fieldConfig.section];
      const fieldFactory = factoriesMap[fieldConfig.type];

      if (!fieldFactory) { // FIXME what should we do if factory not found?
        // throw new NoFieldFactoryError(fieldConfig.type);
        return;
      }

      const field = createFieldFromConfig(
        fieldConfig,
        fieldFactory,
        data,
      );

      if (field) {
        if (field.type === Field.Birthday) {
          section.unshift(field);
        } else {
          section.push(field);
        }
      } // FIXME else
    });

  return sections;
}
