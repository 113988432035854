import {
  createFieldInput,
  FieldInputType,
  IFieldInput,
  IFieldsData,
  IFormField,
  SimpleField,
} from '@c-fields/common';


export class LastNameField extends SimpleField implements IFormField {

  public toJSON(): IFieldInput[] {
    return this._formArray.value
      .map((value: string, index: number) => {
        return createFieldInput<string>(FieldInputType.LastName, index, value);
      });
  }

  protected _setInitialValues(values: IFieldsData): void {
    this._initialValues = [values.person.name.lastName];
  }
}
