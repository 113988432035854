<div class="c-app-content">
  <app-topbar class="custom-topbar" *ngIf="storeService?.setup?.showTopbar"
    lineTwo="{{storeService?.contractNumber ? storeService.contractNumber : null}}"></app-topbar>

  <app-sender-message-element *ngIf="storeService?.setup?.includeEmployee && !showLoader" [pageType]="pageType">
  </app-sender-message-element>
  <div *ngIf="!storeService?.setup?.includeEmployee" style="height: 40px"></div>

  <div class="c-header-image-container x-verification-company-header">
    <img src="{{storeService?.sender?.person?.companyLogo}}" *ngIf="storeService?.sender?.person?.companyLogo" />
    <div class="c-title">{{"zipCode.title" | translate}}</div>
  </div>
  <div class="c-container">
    <div class="c-content-container">
      <p>{{"zipCode.description" | translate}}</p>
      <p *ngIf="!showLoader">{{"zipCode.helpText" | translate}}</p>
      <div *ngIf="showLoader" class="middle">
        <div class="lds-dual-ring">
          <div class="lock-container">
            <div class="shackle"></div>
            <div class="lock">
              <div class="key-hole">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showLoader" class="c-component-container">
        <app-postcode-verification [(ngModel)]="postcodeModel"
                        (ngModelChange)="changed()"
                        name="birthday">
        </app-postcode-verification>
      </div>

      <div *ngIf="showVerificationError" class="c-error-dialog">
        <b>{{"zipCode.verificationErrorTitle" | translate}}</b>
        <p class="c-description">{{"zipCode.verificationErrorDescription" | translate}}</p>
        <b *ngIf="phoneNumber"><a href="tel:{{phoneNumber}}">{{phoneNumber}}</a></b><br>
        <b *ngIf="link"><a [href]="link">{{anchor}}</a></b>
      </div>
      <div *ngIf="showTooManyRequestError" class="c-error-dialog">
        <b>{{ 'zipCode.tooManyRequestErrorTitle' | translate }}</b>
        <p class="c-description">
          {{ 'zipCode.tooManyRequestErrorDescription' | translate }}
          {{ storeService?.sender?.person.companyShortName || storeService?.sender?.person.company }}.
        </p>
        <ng-container *ngIf="tooManyRequestLink">
          <br>
          <b><a href="tooManyRequestLink">{{tooManyRequestLink}}</a></b>
        </ng-container>
      </div>
      <div *ngIf="showInputError" class="c-error-dialog">
        <b>{{"zipCode.inputErrorTitle" | translate}}</b>
        <p class="c-description">{{"zipCode.inputErrorDescription" | translate}}</p>
        <b *ngIf="phoneNumber"><a class="c-error-link" href="tel:{{phoneNumber}}">{{phoneNumber}}</a></b><br>
        <b *ngIf="link"><a [href]="link">{{anchor}}</a></b>
      </div>

      <div *ngIf="!showLoader" class="c-button-container">
        <button class="btn btn-primary btn-lg btn-block" (click)="onSubmit($event)" [disabled]="!enableSubmitButton"
          id="submitBirthday">
          {{"zipCode.verifyButton" | translate}}
        </button>
      </div>

    </div>

  </div>

  <app-info background></app-info>
</div>
