import { Field, IFieldFactory } from '@c-fields/common';
import { AddressFieldFactory } from '@c-fields/fields/address';
import { EmailFieldFactory } from '@c-fields/fields/email';
import { FirstNameFieldFactory } from '@c-fields/fields/first-name';
import { LastNameFieldFactory } from '@c-fields/fields/last-name';
import { BirthdayFieldFactory } from '@c-fields/fields/birthday';
import { MobileFieldFactory } from '@c-fields/fields/mobile';
import { PhoneFieldFactory } from '@c-fields/fields/phone';

/**
 * Here is a mapper between Field Type and related Abstract factory for creating new fields
 *
 * When you want to introduce new Field Type – you have to update this mapper
 */
// eslint-disable-next-line
export const FIELDS: Record<string, IFieldFactory> = {
  [Field.Address]: new AddressFieldFactory(),
  [Field.Email]: new EmailFieldFactory(),
  [Field.FirstName]: new FirstNameFieldFactory(),
  [Field.LastName]: new LastNameFieldFactory(),
  [Field.Birthday]: new BirthdayFieldFactory(),
  [Field.Phone]: new PhoneFieldFactory(),
  [Field.Mobile]: new MobileFieldFactory(),
};
