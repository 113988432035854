<app-steps-element *ngIf="setup.features[pageType][featureType.Steps]" [currentPageType]="pageType"></app-steps-element>
<app-sender-message-element *ngIf="setup.features[pageType][featureType.PreferenceMessage]" [pageType]="pageType">
</app-sender-message-element>
<div style="min-height: 40px" *ngIf="!setup.features[pageType][featureType.PreferenceMessage]"></div>

<app-preferences-element *ngIf="setup.features[pageType][featureType.Preferences]" [pageType]="pageType">
</app-preferences-element>
<!--
  <div class="c-container">
  <app-legal-element *ngIf="setup.features[pageType][featureType.LegalHtml]"></app-legal-element>
</div>
-->

<div class="c-bottom-container">
  <button (click)="onSendClick()" class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary" type="button">
    <ng-container *ngIf="!setup.features[pageType][featureType.NextTitleForSendButton]">{{'receiver.buttons.send' |
      translate}}</ng-container>
    <ng-container *ngIf="setup.features[pageType][featureType.NextTitleForSendButton]">{{'receiver.buttons.next' |
      translate}}</ng-container>
  </button>

</div>