export enum PageType {
  Sharing = 1,
  Answering = 2,
  Done = 3,
  Preference = 4,
  Deleting = 5,
  Authentication = 6,
  DeliveryNotice = 7,
  Confirmation = 8,
  Subscribed = 9,
  Pincode = 10,
  SimpleDone = 11,
  DOBAuthentication = 12,

}
