import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { PageType } from '../../common/enums/page.type';
import { SetupInterface } from '../../common/interfaces/setup.interface';
import { FeatureType } from '../../common/enums/feature.type';
import { StoreService } from '../../core/services/store.service';
import { CategoryType } from '../../common/enums/category.type';
import { Group } from '../../common/classes/group';
import { InfoItemType, InfoItemTypeCheck } from '../../common/enums/info-item.type';
import { InfoItem } from '../../common/classes/Info-item';
import { InfoItemsFactory } from '../../core/factories/info-items.factory';
import { FieldType } from '../../common/enums/field.type';
import { Phone } from '../../common/classes/phone';
import { itemTypeToFieldType } from '../../common/functions/item-type-to-field-type.function';


@Component({
  selector: 'app-folders-element',
  templateUrl: './folders-element.component.html',
  styleUrls: ['./folders-element.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
      })),
      state('closed', style({
        height: '0px',
      })),
      transition('open => closed', [
        animate('0.5s'),
      ]),
      transition('closed => open', [
        animate('0.3s'),
      ]),
    ]),
  ],
})
export class FoldersElementComponent implements OnInit {

  @Input() workInfoItems?: InfoItem[];
  @Input() homeInfoItems?: InfoItem[];
  @Input() sharedInfoItems?: InfoItem[];
  @Input() pageType: PageType;
  @Input() highlightMandatoryFields?: boolean;

  setup: SetupInterface;
  featureType = FeatureType;
  categoryType = CategoryType;

  isOnlyOneFolder: boolean;

  phonesAlreadyAdded: boolean = false;

  constructor(public storeService: StoreService) {
  }

  ngOnInit(): void {
    this.setup = this.storeService.setup;
    this.isOnlyOneFolder = this.setup.features[this.pageType][FeatureType.HomeFolder]
      !== this.setup.features[this.pageType][FeatureType.WorkFolder];
    this.initFolders();
  }

  onInputFilledOut(isFilledOut, folder, group, isLast, item: InfoItem) {
    item.isFilledOut = isFilledOut;

    /* ----------------------------------------
     * Birthday only one time
     * -------------------------------------- */
    if (item.type === InfoItemType.EVENT && (item.categoryType === CategoryType.PERSONAL || item.categoryType === CategoryType.SHARED)) {
      return;
    }

    /* ----------------------------------------
     * Org only one time
     * -------------------------------------- */
    if (item.type === InfoItemType.ORG && item.categoryType === CategoryType.WORK) {
      return;
    }

    if (isFilledOut && isLast) {
      this.removeOtherEmptyInputs(group, item);
      this.addNewEmptyItem(group.items, group.itemType, folder.categoryType);
    } else if (!isFilledOut && !isLast) {
      group.items.splice(group.items.length - 1, 1);
    } else if (isFilledOut && !isLast) {
      if (group.items[group.items.length - 1].isFilledOut) {
        this.removeOtherEmptyInputs(group, item);
        this.addNewEmptyItem(group.items, group.itemType, folder.categoryType);
      }
    } else if (!isFilledOut && isLast) {
      this.removeOtherEmptyInputs(group, item);
    }
  }


  onInputClearedOut(isClearedOut, folder, group, isFirst, item: InfoItem) {
    item.isClearedOut = isClearedOut;

    if (isClearedOut && !isFirst) {
      const index = group.items.indexOf(item);
      // check if more than one is cleared out, and remove the current one if so
      if (group.items.filter((item) => item.isClearedOut).length > 1) {
        group.items.splice(index, 1);
      }
    }
  }

  hasError(category: any) {
    return category.groups.reduce((result, current: Group) => {
      result = result + current.items.filter((item) => item.isSaved).length;

      return result;
    }, 0);
  }

  getNumberOfItems(category: any) {
    return category.groups.reduce((result, current: Group) => {
      result = result + current.items.filter((item) => item.isSaved).length;

      return result;
    }, 0);
  }

  onItemRemove(group: Group, item: InfoItem) {
    const index = group.items.indexOf(item);
    if (index !== -1) {
      group.items.splice(index, 1);

      if (!group.items.length) {
        group.items.push(InfoItemsFactory.makeInfoItem(true, false, false, item.type, item.categoryType, {}));
      } else if (InfoItemTypeCheck.isPhone(item.type) && group.items.length < 2) {
        group.items.push(InfoItemsFactory.makeInfoItem(true, false, false, item.type, item.categoryType, {}, {
          isMobile: (item as Phone).isMobile,
        }));

        group.items = group.items.sort((a, b) => {

          const castedA = (a as Phone);
          const castedB = (b as Phone);

          if (castedA.isMobile === castedB.isMobile) {
            return 0;
          } else if (castedA.isMobile && !castedB.isMobile) {
            return 1;
          }

          return -1;

        });
      }

    }
  }

  private initFolders() {

    this.storeService.folders = [
      {
        isVisible: (this.setup.flow === 'flow_a_shared_section' || this.setup.flow === 'flow_dob_shared'),
        categoryType: CategoryType.SHARED,
        iconName: 'shared',
        isActive: !!this.setup.features[this.pageType][FeatureType.HomeFolderOpen],
        groups: (this.setup.features[this.pageType][FeatureType.HomeFolder] ? this.prepareGroups(this.homeInfoItems, true) : []),
      },
      {
        isVisible: this.setup.features[this.pageType][FeatureType.HomeFolder],
        categoryType: CategoryType.PERSONAL,
        iconName: 'personal',
        isActive: !!this.setup.features[this.pageType][FeatureType.HomeFolderOpen],
        groups: this.setup.features[this.pageType][FeatureType.HomeFolder] ? this.prepareGroups(this.homeInfoItems, true) : [],
      },
      {
        isVisible: this.setup.features[this.pageType][FeatureType.WorkFolder],
        categoryType: CategoryType.WORK,
        iconName: 'work',
        isActive: !!this.setup.features[this.pageType][FeatureType.WorkFolderOpen],
        groups: this.setup.features[this.pageType][FeatureType.WorkFolder] ? this.prepareGroups(this.workInfoItems, false) : [],
      },
    ];

    this.storeService.folders.forEach((folder) => {
      folder.groups.forEach((group: Group) => {
        // Don't add empty date field for WORK and don't add empty org field for PERSONAL
        const needToSkipField = ((folder.categoryType === CategoryType.PERSONAL || folder.categoryType === CategoryType.SHARED)
          && group.itemType === InfoItemType.EVENT
          && group.items.length)
          || (folder.categoryType === CategoryType.WORK && group.itemType === InfoItemType.ORG);

        if (!needToSkipField) {
          this.addNewEmptyItem(group.items, group.itemType, folder.categoryType);
        }
      });

      /* ----------------------------------------
       * Place birthday at the top of list
       * -------------------------------------- */

      if (folder.categoryType === CategoryType.PERSONAL) {
        const eventsGroup = folder.groups.find((oneGroup) => oneGroup.itemType === InfoItemType.EVENT);
        if (eventsGroup) {
          folder.groups.splice(folder.groups.indexOf(eventsGroup), 1);
          folder.groups.unshift(eventsGroup);
        }
      }

      /* ----------------------------------------
       * Phone Handling
       * -------------------------------------- */
      if (this.setup.fields[this.pageType][FieldType.HomeMobile]) {
        if (folder.categoryType === CategoryType.PERSONAL) {
          const phoneGroup = folder.groups.find((oneGroup) => oneGroup.itemType === InfoItemType.MOBILE);
          const newHomePhones = [];
          phoneGroup.items.forEach((element) => {
            element.isRequired = this.setup.required[this.pageType][FieldType.HomeMobile];
            newHomePhones.push(element);
          });
          folder.groups[folder.groups.indexOf(phoneGroup)].items = newHomePhones;
        }
      }

      if (this.setup.fields[this.pageType][FieldType.HomeLandPhone]) {
        if (folder.categoryType === CategoryType.PERSONAL) {
          const phoneGroup = folder.groups.find((oneGroup) => oneGroup.itemType === InfoItemType.PHONE);
          const newHomePhones = [];

          phoneGroup.items.forEach((element) => {
            element.isRequired = this.setup.required[this.pageType][FieldType.HomeLandPhone];
            newHomePhones.push(element);
          });
          folder.groups[folder.groups.indexOf(phoneGroup)].items = newHomePhones;
        }
      }

      if (this.setup.fields[this.pageType][FieldType.WorkMobile]) {
        if (folder.categoryType === CategoryType.WORK) {
          const phoneGroup = folder.groups.find((oneGroup) => InfoItemTypeCheck.isPhone(oneGroup.itemType));
          const newWorkPhones = [];
          phoneGroup.items.forEach((element) => {
            element.isRequired = this.setup.required[this.pageType][FieldType.WorkMobile];
            newWorkPhones.push(element);
          });
          folder.groups[folder.groups.indexOf(phoneGroup)].items = newWorkPhones;
        }
      }

      if (this.setup.fields[this.pageType][FieldType.WorkLandPhone]) {
        if (folder.categoryType === CategoryType.WORK) {
          const phoneGroup = folder.groups.find((oneGroup) => InfoItemTypeCheck.isPhone(oneGroup.itemType));
          const newWorkPhones = [];
          phoneGroup.items.forEach((element) => {
            element.isRequired = this.setup.required[this.pageType][FieldType.WorkLandPhone];
            newWorkPhones.push(element);
          });
          folder.groups[folder.groups.indexOf(phoneGroup)].items = newWorkPhones;
        }
      }

      /* ----------------------------------------
       * Place Org at the top of list
       * -------------------------------------- */

      // if (folder.categoryType === CategoryType.WORK) {
      //   const orgGroup = folder.groups.find(group => group.itemType === InfoItemType.ORG);
      //   if (orgGroup) {
      //     folder.groups.splice(folder.groups.indexOf(orgGroup), 1);
      //     folder.groups.unshift(orgGroup);
      //   }
      // }


      /* ----------------------------------------
       * Hide
       * -------------------------------------- */

      folder.groups = folder.groups.filter((group: Group) => {
        switch (group.itemType) {
          case InfoItemType.EMAIL:
            return (this.setup.fields[this.pageType][FieldType.WorkEmail] && folder.categoryType === CategoryType.WORK)
              || (this.setup.fields[this.pageType][FieldType.HomeEmail] && folder.categoryType === CategoryType.PERSONAL);
          case InfoItemType.ADDRESS:
            return (this.setup.fields[this.pageType][FieldType.WorkAddress] && folder.categoryType === CategoryType.WORK)
              || (this.setup.fields[this.pageType][FieldType.HomeAddress] && folder.categoryType === CategoryType.PERSONAL);
          case InfoItemType.FULL_ADDRESS:
            return (this.setup.fields[this.pageType][FieldType.WorkFullAddress] && folder.categoryType === CategoryType.WORK)
              || (this.setup.fields[this.pageType][FieldType.HomeFullAddress] && folder.categoryType === CategoryType.PERSONAL)
              || folder.categoryType === CategoryType.SHARED;
          case InfoItemType.PHONE:
            return (this.setup.fields[this.pageType][FieldType.WorkPhone] && folder.categoryType === CategoryType.WORK)
              || (this.setup.fields[this.pageType][FieldType.WorkLandPhone] && folder.categoryType === CategoryType.WORK)
              || (this.setup.fields[this.pageType][FieldType.HomePhone] && folder.categoryType === CategoryType.PERSONAL)
              || (this.setup.fields[this.pageType][FieldType.HomeLandPhone] && folder.categoryType === CategoryType.PERSONAL);
          case InfoItemType.MOBILE:
            return (this.setup.fields[this.pageType][FieldType.WorkMobile] && folder.categoryType === CategoryType.WORK)
              || (this.setup.fields[this.pageType][FieldType.HomeMobile] && folder.categoryType === CategoryType.PERSONAL);
          case InfoItemType.FAX:
            return (this.setup.fields[this.pageType][FieldType.WorkFax] && folder.categoryType === CategoryType.WORK)
              || (this.setup.fields[this.pageType][FieldType.HomeFax] && folder.categoryType === CategoryType.PERSONAL);
          case InfoItemType.EVENT:
            return (this.setup.fields[this.pageType][FieldType.Birthday] && folder.categoryType === CategoryType.PERSONAL)
              || folder.categoryType === CategoryType.SHARED;
          case InfoItemType.URL:
            return (this.setup.fields[this.pageType][FieldType.WorkUrl] && folder.categoryType === CategoryType.WORK)
              || (this.setup.fields[this.pageType][FieldType.HomeUrl] && folder.categoryType === CategoryType.PERSONAL);
          case InfoItemType.ORG:
            return (this.setup.fields[this.pageType][FieldType.WorkOrg] && folder.categoryType === CategoryType.WORK);
        }
      });

    });

    this.fallbackPhones();
  }

  private addNewEmptyItem(items: InfoItem[], itemType: InfoItemType, categoryType: CategoryType) {
    let needToSkipField = false;

    let limit = Number.MAX_SAFE_INTEGER;
    let readOnly = false;
    let required = false;

    // Don't add items over limit if limit is defined
    if (this.setup.limits[this.pageType] && this.setup.limits[this.pageType][itemTypeToFieldType(itemType, categoryType)]) {
      limit = this.setup.limits[this.pageType][itemTypeToFieldType(itemType, categoryType)];
      const numberOfExistingItems = items.length;
      needToSkipField = numberOfExistingItems >= limit;
    }

    if (this.setup.readOnly[this.pageType] && this.setup.readOnly[this.pageType][itemTypeToFieldType(itemType, categoryType)]) {
      readOnly = true;
    }

    if (this.setup.required[this.pageType] && this.setup.required[this.pageType][itemTypeToFieldType(itemType, categoryType)]) {
      // if new item is not the first one – we can set required to false
      required = items.length === 0;
    }

    if (needToSkipField && !InfoItemTypeCheck.isPhone(itemType)) {
      return;
    }

    if (itemType === InfoItemType.FULL_ADDRESS) {
      if (this.setup.fields[this.pageType][FieldType.HomeFullAddress] && categoryType === CategoryType.PERSONAL) {
        // if new item is not the first one – we can set required to false
        const requiredFlag = items.length === 0
          ? this.setup.required[PageType.Answering][FieldType.HomeFullAddress]
          : false;

        items.push(InfoItemsFactory.makeInfoItem(
          true,
          this.setup.readOnly[PageType.Answering][FieldType.HomeFullAddress],
          requiredFlag,
          itemType,
          categoryType,
          {},
        ));
      }

      if (this.setup.fields[this.pageType][FieldType.WorkFullAddress] && categoryType === CategoryType.WORK) {
        // if new item is not the first one – we can set required to false
        const requiredFlag = items.length === 0
          ? this.setup.required[PageType.Answering][FieldType.WorkFullAddress]
          : false;

        items.push(InfoItemsFactory.makeInfoItem(
          true,
          this.setup.readOnly[PageType.Answering][FieldType.WorkFullAddress],
          requiredFlag,
          itemType,
          categoryType,
          {},
        ));
      }
    }

    if (itemType == InfoItemType.ADDRESS) {
      if (this.setup.fields[this.pageType][FieldType.HomeAddress] && categoryType === CategoryType.PERSONAL) {
        // if new item is not the first one – we can set required to false
        const requiredFlag = items.length === 0
          ? this.setup.required[PageType.Answering][FieldType.HomeAddress]
          : false;

        items.push(InfoItemsFactory.makeInfoItem(
          true,
          this.setup.readOnly[PageType.Answering][FieldType.HomeAddress],
          requiredFlag,
          itemType,
          categoryType,
          {},
        ));
      }
      if (this.setup.fields[this.pageType][FieldType.WorkAddress] && categoryType === CategoryType.WORK) {
        // if new item is not the first one – we can set required to false
        const requiredFlag = items.length === 0
          ? this.setup.required[PageType.Answering][FieldType.WorkAddress]
          : false;

        items.push(InfoItemsFactory.makeInfoItem(
          true,
          this.setup.readOnly[PageType.Answering][FieldType.WorkAddress],
          requiredFlag,
          itemType,
          categoryType,
          {},
        ));
      }
    }

    if (itemType === InfoItemType.EMAIL) {
      items.push(InfoItemsFactory.makeInfoItem(true, readOnly, required, itemType, categoryType, {}));
    }

    if (itemType == InfoItemType.EVENT) {
      if (this.setup.fields[this.pageType][FieldType.Birthday] && categoryType === CategoryType.PERSONAL) {
        items.push(InfoItemsFactory.makeInfoItem(
          true,
          this.setup.readOnly[PageType.Answering][FieldType.Birthday],
          this.setup.required[PageType.Answering][FieldType.Birthday],
          itemType,
          categoryType,
          {},
        ));
      }
    }

    let mobileFound = false;
    let landphoneFound = false;
    if (InfoItemTypeCheck.isPhone(itemType) && !this.phonesAlreadyAdded) {
      this.phonesAlreadyAdded = true;

      items.forEach((item) => {
        if (item as Phone) {
          if ((item as Phone).isMobile) {
            mobileFound = true;
          } else {
            landphoneFound = true;
          }
        }
      });

      if (!mobileFound) {
        if (this.setup.fields[PageType.Answering][FieldType.HomeMobile]) {
          items.push(InfoItemsFactory.makeInfoItem(
            true,
            this.setup.readOnly[PageType.Answering][FieldType.HomeMobile],
            this.setup.required[PageType.Answering][FieldType.HomeMobile],
            InfoItemType.PHONE,
            CategoryType.PERSONAL,
            null,
            { isMobile: true },
          ));
        }
        if (this.setup.fields[PageType.Answering][FieldType.WorkMobile]) {
          items.push(InfoItemsFactory.makeInfoItem(
            true,
            this.setup.readOnly[PageType.Answering][FieldType.WorkMobile],
            this.setup.required[PageType.Answering][FieldType.WorkMobile],
            InfoItemType.PHONE,
            CategoryType.WORK,
            null,
            { isMobile: true },
          ));
        }
      }

      if (!landphoneFound) {
        if (this.setup.fields[PageType.Answering][FieldType.HomeLandPhone]) {
          items.push(InfoItemsFactory.makeInfoItem(
            true,
            this.setup.readOnly[PageType.Answering][FieldType.HomeLandPhone],
            this.setup.required[PageType.Answering][FieldType.HomeLandPhone],
            InfoItemType.PHONE,
            CategoryType.PERSONAL,
            null,
            { isMobile: false },
          ));
        }
        if (this.setup.fields[PageType.Answering][FieldType.WorkLandPhone]) {
          items.push(InfoItemsFactory.makeInfoItem(
            true,
            this.setup.readOnly[PageType.Answering][FieldType.WorkLandPhone],
            this.setup.required[PageType.Answering][FieldType.WorkLandPhone],
            InfoItemType.PHONE,
            CategoryType.WORK,
            null,
            { isMobile: false },
          ));
        }
      }
    }

    /*
    if (itemType !== InfoItemType.PHONE) {
      if (itemType === InfoItemType.EMAIL) {
        items.push(InfoItemsFactory.makeInfoItem(true, readOnly, required, itemType, categoryType, {}));
      }
    } else if (isInitially && !needOnlyOnePhoneInitially) {
      items.push(InfoItemsFactory.makeInfoItem(true, readOnly, required, itemType, categoryType, null, { isMobile: false }));
      items.push(InfoItemsFactory.makeInfoItem(true, readOnly, required, itemType, categoryType, null, { isMobile: true }));
    } else if (isMobile) {
      if (!items.find(item => (item as Phone).isMobile !== isMobile)) {
        // if there is only one item, first make sure that there is at least one of the other kind present
        items.push(InfoItemsFactory.makeInfoItem(true, readOnly, required, itemType, categoryType, null, { isMobile: !isMobile }));
      } else {
        items.push(InfoItemsFactory.makeInfoItem(true, readOnly, required, itemType, categoryType, null, { isMobile: true }));
      }
    } else {

      const addEmptyMobileField =
        items.every(item =>
          (item as Phone).isMobile) ? false : items.every(item => !(item as Phone).isMobile) ? true : !(items.length < limit - 1);

      items.push(InfoItemsFactory.makeInfoItem(true, readOnly, required, itemType, categoryType, null, {
        isMobile: addEmptyMobileField
      }));
    }
    */


    /*
    if (itemType === InfoItemType.PHONE) {
      items.sort((a, b) => {

        const castedA = (a as Phone);
        const castedB = (b as Phone);

        if (castedA.isMobile === castedB.isMobile) {
          return 0;
        } else if (castedA.isMobile && !castedB.isMobile) {
          return 1;
        } else {
          return -1;
        }
      });
    }
    */
  }

  private prepareGroups(items: InfoItem[], isPersonal?): Group[] {
    const types = [
      InfoItemType.PHONE,
      InfoItemType.MOBILE,
      InfoItemType.FAX,
      InfoItemType.EMAIL,
      InfoItemType.ADDRESS,
      InfoItemType.FULL_ADDRESS,
      InfoItemType.URL,
    ];

    if (isPersonal) {
      types.push(InfoItemType.EVENT);
    }

    const commonGroups = types.map((type) => new Group({
      itemType: type,
      items: [],
    }));

    return items.reduce(
      (result: Group[], item: InfoItem): Group[] => {
        const found = result.find((group) => this.findGroup(group, item));
        if (found) {
          found.items.push(item);
        } else {
          result.push(new Group({
            itemType: this.addTypeToGroup(item.type),
            items: [item],
          }));
        }

        return result;
      },
      commonGroups,
    );
  }

  findGroup(group: Group, item: InfoItem): boolean {
    if (group.itemType === InfoItemType.PHONE) {
      return item.type === InfoItemType.PHONE && !item.isMobile;
    }

    if (group.itemType === InfoItemType.MOBILE) {
      return item.type === InfoItemType.MOBILE || (item.type === InfoItemType.PHONE && item.isMobile);
    }

    return group.itemType === item.type;
  }

  addTypeToGroup(itemType: InfoItemType): InfoItemType {
    if (itemType == InfoItemType.PHONE || itemType == InfoItemType.MOBILE) {
      return InfoItemType.PHONE;
    }

    return itemType;
  }

  private removeOtherEmptyInputs(group, item) {
    let emptyInputsToRemove = group.items.filter((oneItem) => (!oneItem.isFilledOut && oneItem !== item));

    if (InfoItemTypeCheck.isPhone(item.type)) {
      emptyInputsToRemove = [...emptyInputsToRemove].filter((oneItem) => !!(oneItem.isMobile) === !!(item.isMobile));
    }

    emptyInputsToRemove.forEach((oneItem) => {
      const index = group.items.indexOf(oneItem);
      if (index !== -1) {
        group.items.splice(index, 1);
      }
    });
  }

  isItLast(isLast: boolean, items, item: InfoItem) {
    if (InfoItemTypeCheck.isPhone(item.type)) {
      return isLast;
    }
    const sameTypeArray = items.filter((phoneItem: Phone) => phoneItem.isMobile === (item as Phone).isMobile);

    return sameTypeArray.indexOf(item) === sameTypeArray.length - 1;

  }

  fallbackPhones() {
    if (
      this.setup.fields[this.pageType][FieldType.HomeMobile]
      || this.setup.fields[this.pageType][FieldType.HomeLandPhone]
      || this.setup.fields[this.pageType][FieldType.WorkMobile]
      || this.setup.fields[this.pageType][FieldType.WorkLandPhone]
    ) {
      this.storeService.folders.forEach((folder) => {

        if (folder.categoryType === CategoryType.PERSONAL) {

          folder.groups
            .filter((group) => group.itemType === InfoItemType.PHONE || group.itemType === InfoItemType.MOBILE)
            .filter((group) => group.items.length === 0)
            .forEach((group) => {
              if (group.itemType === InfoItemType.PHONE) {
                if (this.setup.fields[PageType.Answering][FieldType.HomeLandPhone]) {
                  group.items.push(InfoItemsFactory.makeInfoItem(
                    true,
                    this.setup.readOnly[PageType.Answering][FieldType.HomeLandPhone],
                    this.setup.required[PageType.Answering][FieldType.HomeLandPhone],
                    InfoItemType.PHONE,
                    CategoryType.PERSONAL,
                    null,
                    { isMobile: false },
                  ));
                }
              }

              if (group.itemType === InfoItemType.MOBILE) {
                if (this.setup.fields[PageType.Answering][FieldType.HomeMobile]) {
                  group.items.push(InfoItemsFactory.makeInfoItem(
                    true,
                    this.setup.readOnly[PageType.Answering][FieldType.HomeMobile],
                    this.setup.required[PageType.Answering][FieldType.HomeMobile],
                    InfoItemType.PHONE,
                    CategoryType.PERSONAL,
                    null,
                    { isMobile: true },
                  ));
                }
              }
            });
        }

        if (folder.categoryType === CategoryType.WORK) {

          let readOnly = false;
          let required = false;

          if (this.setup.readOnly[this.pageType]
            && this.setup.readOnly[this.pageType][itemTypeToFieldType(InfoItemType.PHONE, CategoryType.WORK)]) {
            readOnly = true;
          }

          if (this.setup.required[this.pageType]
            && this.setup.required[this.pageType][itemTypeToFieldType(InfoItemType.PHONE, CategoryType.WORK)]) {
            required = true;
          }

          folder.groups.forEach((group) => {
            if (group.items.length !== 0) {
              return;
            }

            switch (group.itemType) {
              case InfoItemType.PHONE: {
                if (this.setup.fields[this.pageType][FieldType.WorkLandPhone]) {
                  const item = InfoItemsFactory
                    .makeInfoItem(
                      true,
                      readOnly,
                      required,
                      InfoItemType.PHONE,
                      CategoryType.WORK,
                      null,
                      { isMobile: false },
                    );

                  group.items.push(item);
                }
              } break;

              case InfoItemType.MOBILE: {
                if (this.setup.fields[this.pageType][FieldType.WorkMobile]) {
                  const item = InfoItemsFactory
                    .makeInfoItem(
                      true,
                      readOnly,
                      required,
                      InfoItemType.PHONE,
                      CategoryType.WORK,
                      null,
                      { isMobile: true },
                    );

                  group.items.push(item);
                }
              } break;
            }
          });
        }
      });
    }
  }
}
