import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InfoItem } from '../../common/classes/Info-item';
import { InfoItemType } from '../../common/enums/info-item.type';
import { Group } from '../../common/classes/group';
import { CategoryType } from '../../common/enums/category.type';
import { ApiService } from '../../core/services/api.service';
import { StoreService } from '../../core/services/store.service';
import { PageType } from '../../common/enums/page.type';


@Component({
  selector: 'app-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss'],
})
export class InfoItemComponent implements OnInit {

  @Input() item: InfoItem;
  @Input() pageType?: PageType;
  @Input() displayCategoryLabel?: boolean;
  @Input() showMenu: boolean;

  @Output() remove = new EventEmitter<true>();

  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  infoItemType = InfoItemType;
  categoryType = CategoryType;
  pageTypeDict = PageType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  isWork: boolean;

  constructor(
    private apiService: ApiService,
    public storeService: StoreService,
  ) { }

  ngOnInit() {
    if (this.item.categoryType === CategoryType.WORK) {
      this.isWork = true;
    }
  }


  onEditClick(item: InfoItem) {
    item.isSaved = false;
  }

  onRemoveClick() {
    this.remove.emit(true);
  }

  onPhoneClick() {
    this.apiService.trackPhoneClick(this.storeService.token).subscribe(() => { });
  }

  onEmailClick() {
    this.apiService.trackEmailClick(this.storeService.token).subscribe(() => { });
  }

}
