import {
  createFieldInput,
  FieldInputType,
  IFieldInput,
  IFieldsData,
  IFormField,
  SimpleField,
} from '@c-fields/common';
import { isoStringToZonelessDate, isDateValid } from '@common/helpers/date';

import { FormControlBuilder } from '../../common/builders/form-control.builder';


export class Birthday extends SimpleField<Date> implements IFormField<Date> {

  public toJSON(): IFieldInput[] {
    return this._formArray.value
      .map((value: Date, index: number) => {
        const date = new Date(value);

        if (isDateValid(date)) {
          date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

          return createFieldInput<string>(FieldInputType.Birthday, index, date.toISOString());
        }

        return createFieldInput<string>(FieldInputType.Birthday, index, null);

      });
  }

  public addEmptyFormControlIfNeeded(): void {
    return;
  }

  protected _buildFormControl(index: number): FormControlBuilder {

    const builder = super._buildFormControl(index);
    builder.addBirthdayValidator();

    return builder;
  }


  protected _setInitialValues(values: IFieldsData): void {
    const bdayStr: string = values?.person?.birthday;

    if (bdayStr) {
      const parsed: Date = isoStringToZonelessDate(bdayStr);

      // TODO assertions later when dateIsNotValid
      if (isDateValid(parsed)) {
        this._initialValues = [parsed];
      }
    }
  }

}
