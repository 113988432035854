import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { PageType } from '../../common/enums/page.type';
import { SetupInterface } from '../../common/interfaces/setup.interface';
import { FeatureType } from '../../common/enums/feature.type';
import { StoreService } from '../../core/services/store.service';
import { AppNavService } from '../../core/services/app-nav.service';


@Component({
  selector: 'app-steps-element',
  templateUrl: './steps-element.component.html',
  styleUrls: ['./steps-element.component.scss'],
})
export class StepsElementComponent implements OnInit {

  @Input() currentPageType: PageType;
  currentPageIndex: number;

  setup: SetupInterface;
  featureType = FeatureType;
  pageType = PageType;

  @HostBinding('class.c-sharing-page') get t() {
    return this.currentPageType === PageType.Sharing;
  }

  constructor(
    private storeService: StoreService,
    public appNavService: AppNavService,
  ) {

  }

  ngOnInit(): void {
    this.setup = this.storeService.setup;
    this.currentPageIndex = this.storeService.setup.sequence.indexOf(this.currentPageType);
  }

  onStepClick(i: number) {
    this.appNavService.goToPageType(this.storeService.setup.sequence[i]);
  }

}
