import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { ShellComponent } from './shell/components/shell/shell.component';
import { RouteString } from './common/enums/route.string';
import { TermsComponent } from './shell/components/terms/terms.component';
import { HomeComponent } from './components/home/home.component';
import { SetupResolver } from './core/resolvers/setup.resolver';
import { SettingsComponent } from './modules/settings/settings/settings.component';
import { AnsweringComponent } from './pages/answering/answering/answering.component';
import {
  AnsweringComponent as NewAnsweringComponent,
} from './pages/new-answering/components/answering';
import { SharingComponent } from './pages/sharing/sharing/sharing.component';
import { PreferenceComponent } from './pages/preference/preference/preference.component';
import { AccountDeleteComponent } from './modules/settings/account-delete/account-delete.component';
import { AccountDownloadDataComponent } from './modules/settings/account-download-data/account-download-data.component';
import { AccountManagementComponent } from './modules/settings/account-management/account-management.component';
import { AuthenticationComponent } from './pages/authentication/authentication/authentication.component';
import { ErrorComponent } from './pages/error/error/error.component';
import { BirthdayComponent } from './pages/birthday/birthday/birthday.component';
import { DeliveryNoticeComponent } from './pages/delivery-notice/delivery-notice/delivery-notice.component';
import { DoneComponent } from './pages/done/done/done.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation/confirmation.component';
import { PincodeComponent } from './pages/pincode/pincode/pincode.component';
import { FlowPageGuard } from './core/resolvers/flow-page.guard';
import { RedirectToFirstInSequenceComponent } from './components/redirect-to-first-in-sequence/redirect-to-first-in-sequence.component';
import { SmsAuthGuardGuard } from './core/guards/sms-auth-guard.guard';
import { DobAuthGuardGuard } from './core/guards/dob-auth-guard.guard';
import { NotAuthenticatedGuard } from './core/guards/not-authenticated.guard';
import { PostcodeAuthComponent } from './pages/postcode-auth';


const routes: Routes = [
  { path: `${RouteString.NOT_FOUND}`, component: NotFoundComponent },
  { path: '', component: HomeComponent },
  { path: `${RouteString.ERROR}/:expired`, component: ErrorComponent },
  { path: `${RouteString.ERROR}`, component: ErrorComponent },
  {
    path: 'r/:token', component: ShellComponent, resolve: { setup: SetupResolver }, children: [
      { path: `${RouteString.DELIVERY_NOTICE}`, component: DeliveryNoticeComponent, resolve: { isInSequence: FlowPageGuard, dob: DobAuthGuardGuard } },
      { path: `${RouteString.CONFIRMATION}`, component: ConfirmationComponent, resolve: { isInSequence: FlowPageGuard, dob: DobAuthGuardGuard } },
      { path: `${RouteString.DONE}`, component: DoneComponent, resolve: { isInSequence: FlowPageGuard, dob: DobAuthGuardGuard } },
      { path: `${RouteString.ANSWERING}`, component: AnsweringComponent, resolve: { isInSequence: FlowPageGuard, sms: SmsAuthGuardGuard, dob: DobAuthGuardGuard } },
      {
        path: `${RouteString.NEW_ANSWERING}`,
        component: NewAnsweringComponent,
        resolve: { isInSequence: FlowPageGuard, sms: SmsAuthGuardGuard, dob: DobAuthGuardGuard },
      },
      { path: `${RouteString.PINCODE}`, component: PincodeComponent, resolve: { isInSequence: FlowPageGuard } },
      { path: `${RouteString.SHARING}`, component: SharingComponent, resolve: { isInSequence: FlowPageGuard, sms: SmsAuthGuardGuard, dob: DobAuthGuardGuard } },
      { path: `${RouteString.PREFERENCE}`, component: PreferenceComponent, resolve: { isInSequence: FlowPageGuard } },
      { path: `${RouteString.SMS_AUTH}`, component: AuthenticationComponent, canActivate: [NotAuthenticatedGuard] },
      { path: `${RouteString.DOB_AUTH}`, component: BirthdayComponent, canActivate: [NotAuthenticatedGuard] },
      { path: `${RouteString.POSTCODE_AUTH}`, component: PostcodeAuthComponent, canActivate: [NotAuthenticatedGuard] },
      { path: `${RouteString.TERMS}`, component: TermsComponent },
      {
        path: `${RouteString.SETTINGS}`, component: SettingsComponent, children: [
          { path: '', pathMatch: 'full', component: AccountManagementComponent },
          { path: `${RouteString.DELETE}`, component: AccountDeleteComponent },
          { path: `${RouteString.DOWNLOAD}`, component: AccountDownloadDataComponent },
          { path: '**', redirectTo: '' },
        ],
      },
      { path: '', pathMatch: 'full', component: RedirectToFirstInSequenceComponent },
      { path: '**', component: RedirectToFirstInSequenceComponent },
    ],
  },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
