<div id="validationError" *ngIf="validationError">
  <div class="alert alert-danger alert-dismissible fade show" role="alert">
    {{ validationError | translate }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeValidationError()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="c-app-content">
  <app-topbar class="custom-topbar" *ngIf="storeService?.setup?.showTopbar"
              [lineOne]="lineOne"
              [lineTwo]="lineTwo">
  </app-topbar>

  <app-steps-element *ngIf="storeService?.setup?.features[pageType][featureType.Steps]" [currentPageType]="pageType">
  </app-steps-element>

  <app-sender-message-element *ngIf="storeService?.setup?.includeEmployee" [pageType]="pageType">
  </app-sender-message-element>
  <div *ngIf="!storeService?.setup?.includeEmployee" style="height: 40px"></div>

  <app-person-element *ngIf="storeService?.setup?.features[pageType][featureType.Person]" [pageType]="pageType"
    [person]="storeService?.receiver.person" [highlightMandatoryFields]="highlightMandatoryFields"></app-person-element>

  <app-receiver-message-element *ngIf="storeService?.setup?.features[pageType][featureType.ReceiverMessage]"
    [pageType]="pageType"></app-receiver-message-element>
  <div *ngIf="!storeService?.setup?.features[pageType][featureType.ReceiverMessage]" style="height: 29px"></div>

  <app-folders-element *ngIf="storeService?.setup?.features[pageType][featureType.WorkFolder]
                      ||storeService?.setup?.features[pageType][featureType.HomeFolder]" [pageType]="pageType"
    [homeInfoItems]="storeService?.receiver.homeInfoItems" [sharedInfoItems]="storeService?.receiver.sharedInfoItems"
    [workInfoItems]="storeService?.receiver.workInfoItems" [highlightMandatoryFields]="highlightMandatoryFields">
  </app-folders-element>

  <app-preferences-element *ngIf="storeService?.setup?.features[pageType][featureType.Preferences]"
    [pageType]="pageType">
  </app-preferences-element>

  <div class="c-bottom-container">

    <!-- <app-legal-element *ngIf="storeService?.setup.features[pageType][featureType.LegalHtml]"></app-legal-element> -->

    <button (click)="onSendClick()" class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary custom-send-button"
      data-cy="answering-submit"
      type="button">
      <ng-container *ngIf="!storeService?.setup?.features[pageType][featureType.NextTitleForSendButton]">
        {{'receiver.buttons.send' | translate}}</ng-container>
      <ng-container *ngIf="storeService?.setup?.features[pageType][featureType.NextTitleForSendButton]">
        {{'receiver.buttons.next' | translate}}</ng-container>
    </button>
  </div>

  <app-info *ngIf="storeService?.setup?.features[pageType][featureType.AppInfo]" background></app-info>

  <app-popover></app-popover>

</div>
