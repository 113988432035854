import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { Side } from '../../../common/classes/side';
import { PageType } from '../../../common/enums/page.type';
import { FeatureType } from '../../../common/enums/feature.type';
import { StoreService } from '../../../core/services/store.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { PersistanceService } from '../../../core/services/persistance.service';


@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss'],
})
export class DoneComponent implements OnInit, OnDestroy {

  /* ----------------------------------------
* Data
* -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.SimpleDone;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  showLoader = false;

  constructor(
    public storeService: StoreService,
    private persistanceService: PersistanceService,
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

}
