import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { SharingComponent } from './sharing/sharing.component';


@NgModule({
  declarations: [SharingComponent],
  exports: [
    SharingComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class SharingModule { }
