import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { DeliveryNoticeComponent } from './delivery-notice/delivery-notice.component';


@NgModule({
  declarations: [DeliveryNoticeComponent],
  exports: [DeliveryNoticeComponent],
  imports: [
    SharedModule,
  ],
})
export class DeliveryNoticeModule { }
