import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { SettingsTabsType } from '../settings-tabs.type';
import { SettingsService } from '../settings.service';
import { StoreService } from '../../../core/services/store.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { scrollToTop } from '../../../common/functions/scroll-to-top.function';


@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss'],
})
export class AccountManagementComponent implements OnInit {

  @Output() changeTab = new EventEmitter<SettingsTabsType>();

  constructor(
    public settingsService: SettingsService,
    private storeService: StoreService,
    private appNavService: AppNavService,
  ) { }

  ngOnInit(): void {
    scrollToTop();
    this.settingsService.accounts = [this.storeService.account.current.name];
  }

  isNotSelected(value: string): boolean {
    return this.settingsService.selectedAccounts.indexOf(value) === -1;
  }

  onDeleteClick() {
    if (this.settingsService.selectedAccounts.length) {
      this.appNavService.goToDeletePage();
    }
  }

  onDownloadClick() {
    if (this.settingsService.selectedAccounts.length) {
      this.appNavService.goToDownloadPage();
    }
  }
}
