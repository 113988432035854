import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { of, Subject, combineLatest } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';

import { PersonInterface } from '../../../common/interfaces/person.interface';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { Side } from '../../../common/classes/side';
import { PageType } from '../../../common/enums/page.type';
import { FeatureType } from '../../../common/enums/feature.type';
import { StoreService } from '../../../core/services/store.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { Consent } from '../../../common/classes/consent';
import { ConsentType } from '../../../common/enums/consent.type';
import { VCardFactory } from '../../../vcard/vcard.factory';
import { VCardService } from '../../../vcard/vcard.service';
import { TelService } from '../../../core/services/tel.service';
import { PersistanceService } from '../../../core/services/persistance.service';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {

  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.Confirmation;
  reoConsent: Consent;

  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  showLoader = false;
  /* ----------------------------------------
   * Variables
   * -------------------------------------- */
  isEmailChanged: boolean;
  isReoConsentGiven: boolean;
  afterDeliveryNotice: boolean;

  validationError = '';
  person: PersonInterface;

  /* ----------------------------------------
  * Data
  * -------------------------------------- */
  showvCard = false;
  sender: Side;
  customMessage: string;
  senderVCardString: string;

  /* ----------------------------------------
   * Life-Cycles
   * -------------------------------------- */
  private onDestroySubject = new Subject();
  onDestroy$ = this.onDestroySubject.asObservable();

  constructor(
    public storeService: StoreService,
    private appNavService: AppNavService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private deviceService: DeviceDetectorService,
    private telekomService: TelService,
    private persistanceService: PersistanceService,
  ) {
    this.setup = this.storeService.setup;
    this.person = this.storeService?.sender?.person;
  }

  get isTelekomFlow(): boolean {
    return this.telekomService.isTel();
  }

  ngOnInit(): void {
    const previous = this.persistanceService.getItem('latestPageType');
    this.isEmailChanged = this.persistanceService.getItemAsBoolean('isEmailChanged');
    this.afterDeliveryNotice = previous && Number(previous) === PageType.DeliveryNotice;

    this.initialize();
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  onSendClick() {
    this.persistanceService.removeItem('isEmailChanged');
    this.showLoader = true;

    const source = this.activatedRoute.snapshot.queryParams.source;
    const code = this.activatedRoute.snapshot.queryParams.code;

    this.saveReceiver()
      .pipe(
        mergeMap(() => {
          return this.apiService.getAll(
            this.storeService.token,
            { 'source': source, 'code': code },
          );
        }),
        catchError((data) => {
          if (!data || data instanceof HttpErrorResponse) {
            this.showLoader = false;

            if (data.error.validation_errors && data.error.validation_errors.length > 0) {
              this.validationError = `validation.${data.error.validation_errors[0].key}`;
            } else {
              this.validationError = data.error.message;
            }
          }

          return of(data);
        }),
        tap(() => {
          this.initialize();
        }),
        tap(() => {
          this.showLoader = false;
        }),
        tap(() => {
          if (this.appNavService.canGoNext(this.storeService.setup.sequence, this.pageType)) {
            this.appNavService.goToNext(
              this.storeService.setup.sequence,
              this.pageType,
              this.activatedRoute.snapshot.queryParams,
            );
          }
        }),
      )
      .subscribe();

  }

  removeValidationError() {
    this.validationError = '';
  }

  saveReceiver() {

    this.storeService.preferences.find((item) => item.id === this.reoConsent.id);

    const updatedReceiver = new Side({
      id: this.storeService.receiver.id,
      handler_id: this.storeService.receiver.handler_id,
      person: this.storeService.receiver.person,
      message: '',
      workInfoItems: this.storeService.receiver.workInfoItems,
      homeInfoItems: this.storeService.receiver.homeInfoItems,
      sharedInfoItems: this.storeService.folders.length >= 3 ? this.storeService.receiver.sharedInfoItems : [],
      tags: this.storeService.receiver.tags,
    });

    return this.apiService.saveAnswer(updatedReceiver, { 'code': this.activatedRoute.snapshot.queryParams.code });
  }

  onGoToAnsweringClick() {
    this.appNavService.goToAnsweringPage();
  }

  updateValue(event) {
    this.reoConsent.value = event.checked;
    const consent = this.storeService.preferences.find((item) => item.id === this.reoConsent.id);
    consent.value = event.checked;

  }

  downloadContact() {
    const filename = VCardService.makeFileName(this.sender.person.name.firstName, this.sender.person.name.lastName);
    const deviceInfo = this.deviceService.getDeviceInfo();
    const isMobileSafari = deviceInfo.os === 'iOS' && deviceInfo.browser === 'Safari';
    VCardService.download(this.senderVCardString, filename, isMobileSafari);
    this.apiService.trackVcard(this.storeService.token).subscribe(() => { });
  }

  private initialize() {
    const reoConsentGiven = this.persistanceService.getItemAsBoolean('isReoConsentGiven');

    this.reoConsent = this.storeService.preferences.find((consent) => consent.type === ConsentType.Reo);
    this.isReoConsentGiven = reoConsentGiven || !!this.reoConsent?.value;
    this.sender = { ...this.storeService.sender };
    if (this.storeService?.setup?.features[PageType.Sharing][FeatureType.SharingVcard]) {
      this.makeSenderVcard();
    }
  }

  private makeSenderVcard() {
    this.senderVCardString = null;
    combineLatest([
      this.storeService.sender$,
      this.storeService.senderPhoto$,
    ]).pipe(takeUntil(this.onDestroy$))
      .subscribe(([sender, photo]) => {
        const items = [
          ...this.sender.homeInfoItems,
          ...this.sender.workInfoItems,
        ];
        const factory = new VCardFactory(items, {
          firstName: sender.person.name.firstName,
          lastName: sender.person.name.lastName,
        }, photo, this.person.nameSuffix);
        factory.prepareCardString();
        this.senderVCardString = factory.cardString;

        this.showvCard = true;
      });
  }
}
