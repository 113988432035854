<div [appIcon]="item?.iconName || ''" [size]="20" class="c-icon custom-icon" data-cy="field-icon"
  [class.c-alight-top]="item?.type===infoItemType.FULL_ADDRESS && storeService?.setup?.features[pageType][featureType.AddressInputTopIcon]">
</div>
<div class="c-entry custom-entry">
  <ng-container *ngIf="item?.type===infoItemType.EMAIL">
    <div class=" form-group c-form-group">
      <label data-cy="email-field-label">
        {{'labelTexts.email'|translate}} <span *ngIf="isRequired()" class="required-star">*</span>
      </label>
      <div style="display: flex; align-items: center">
        <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)"
          (ngModelChange)="onInputChange([$event])" [(ngModel)]="item.email"
          [disabled]="item?.isReadOnly ? true : null"
          class="form-control c-form-control {{error ? 'input-with-error' : ''}}"
          placeholder="{{'receiver.placeholders.email'|translate}}" type="email"
          data-cy="email-field">
        <div class="tooltip-icon">
          <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="email-field-readonly"
               *ngIf="item?.isReadOnly ? true : null"
               [appIcon]="'info'"
               class="c-info-icon">
          </div>

          <div [nzTooltipTitle]="tooltipText(email)"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="email-field-required"
               *ngIf="!item?.isReadOnly && item?.isRequired ? true : null"
               [appIcon]="'info'"
               class="ml-2 c-edit-icon">
          </div>
        </div>
      </div>
    </div>
    <p class="error-label" *ngIf="error">{{error}}</p>
  </ng-container>
  <ng-container *ngIf="item?.type===infoItemType.EVENT">
    <div class=" form-group c-form-group">
      <label data-cy="birthday-field-label-">{{'labelTexts.birthday'|translate}} <span *ngIf="isRequired()" class="required-star">*</span></label>
      <div class="c-date-group">
        <input inputmode="decimal" maxlength="2" pattern="[0-9]*" class="form-control c-form-control" #dayInput
          [(ngModel)]="dateComponents.day"
          [disabled]="item?.isReadOnly ? true : null"
          (keyup)="onDayInputKeyUp($event)" id="dayInput"
          (change)="onDayInputChange($event)" (blur)="onDayInputBlur($event)"
          placeholder="{{'birthday.dayFormat' | translate}}"
          data-cy="birthday-field-day"
          autofocus />
        <input inputmode="decimal" maxlength="2" pattern="[0-9]*" class="form-control c-form-control" #monthInput
          [(ngModel)]="dateComponents.month"
          [disabled]="item?.isReadOnly ? true : null"
          (keyup)="onMonthInputKeyUp($event)" id="monthInput"
          (change)="onMonthInputChange($event)" (blur)="onMonthInputBlur($event)"
          placeholder="{{'birthday.monthFormat' | translate}}"
          data-cy="birthday-field-month" />
        <input inputmode="decimal" maxlength="4" pattern="[0-9]*" class="form-control c-form-control"
          style="min-width: 80px" #yearInput [(ngModel)]="dateComponents.year"
          [disabled]="item?.isReadOnly ? true : null"
          (keyup)="onYearInputKeyUp($event)"
          id="yearInput" (change)="onYearInputChange($event)" placeholder="{{'birthday.yearFormat' | translate}}"
          data-cy="birthday-field-year" />
        <div class="tooltip-icon">
          <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="birthday-field-readonly"
               *ngIf="item?.isReadOnly ? true : null"
               [appIcon]="'info'"
               class="c-info-icon">
          </div>

          <div [nzTooltipTitle]="tooltipText(birthday)"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="birthday-field-required"
               *ngIf="!item?.isReadOnly && item?.isRequired ? true : null"
               [appIcon]="'info'"
               class="ml-2 c-edit-icon">
          </div>
        </div>
      </div>
      <p class="error-label" *ngIf="error">{{error}}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="item?.type===infoItemType.URL">
    <div class=" form-group c-form-group">
      <label data-cy="url-field-label">{{'labelTexts.link'|translate}}</label>
      <div style="display: flex; align-items: center;">
        <input (keyup.enter)="onEnter($event)" (ngModelChange)="onInputChange([item?.url])" [(ngModel)]="item.url"
          [disabled]="item?.isReadOnly ? true : null" class="form-control c-form-control"
          placeholder="{{'receiver.placeholders.link'|translate}}" type="text" data-cy="url-field">
        <div class="tooltip-icon">
          <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="url-field-readonly"
               *ngIf="item?.isReadOnly ? true : null"
               [appIcon]="'info'"
               class="c-info-icon">

          </div>
          <div [nzTooltipTitle]="tooltipText(link)"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="url-field-required"
               *ngIf="!item?.isReadOnly && item?.isRequired ? true : null"
               [appIcon]="'info'"
               class="ml-2 c-edit-icon">
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="item?.type===infoItemType.PHONE || item?.type===infoItemType.MOBILE">
    <div class=" form-group c-form-group">
      <label [attr.data-cy]="(item?.isMobile ? 'mobile' : 'phone') + '-field-label'">
        {{(item?.isMobile ? 'labelTexts.mobile' : 'labelTexts.phone')|translate}} <span *ngIf="isRequired()"
          class="required-star">*</span>
      </label>
      <div style="display: flex; align-items: center;">
        <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)"
          (ngModelChange)="onInputChange([item?.value])" [(ngModel)]="item.value"
          [disabled]="item?.isReadOnly ? true : null"
          class="form-control c-form-control {{error ? 'input-with-error' : ''}}"
          placeholder="{{(item?.isMobile?'receiver.placeholders.mobile':'receiver.placeholders.phone')|translate}}"
          type="tel" [required]="isRequired()"
          [attr.data-cy]="(item?.isMobile ? 'mobile' : 'phone') + '-field'">
        <div class="tooltip-icon">
          <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}"
               nzTooltipPlacement="left"
               nz-tooltip
               [attr.data-cy]="(item?.isMobile ? 'mobile' : 'phone') + '-field-readonly'"
               *ngIf="item?.isReadOnly ? true : null"
               [appIcon]="'info'"
               class="c-info-icon">
          </div>

          <div [nzTooltipTitle]="tooltipText(phone)"
               nzTooltipPlacement="left"
               nz-tooltip
               [attr.data-cy]="(item?.isMobile ? 'mobile' : 'phone') + '-field-required'"
               *ngIf="!item?.isReadOnly && item?.isRequired ? true : null"
               [appIcon]="'info'"
               class="ml-2 c-edit-icon">
          </div>
        </div>
      </div>
    </div>
    <p class="error-label" *ngIf="error">{{error}}</p>
  </ng-container>
  <ng-container *ngIf="item?.type===infoItemType.FAX">
    <div class=" form-group c-form-group">
      <label data-cy="fax-field-label">{{'labelTexts.fax'|translate}}</label>
      <div style="display: flex; align-items: center;">
        <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)"
          (ngModelChange)="onInputChange([item?.value])" [(ngModel)]="item.value"
          [disabled]="item?.isReadOnly ? true : null"
          class="form-control c-form-control {{error ? 'input-with-error' : ''}}"
          placeholder="{{'receiver.placeholders.fax'|translate}}"
          data-cy="fax-field"
          type="tel">
        <div class="tooltip-icon">
          <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="fax-field-readonly"
               *ngIf="item?.isReadOnly ? true : null"
               [appIcon]="'info'"
               class="c-info-icon">
          </div>

          <div [nzTooltipTitle]="tooltipText(fax)"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="fax-field-required"
               *ngIf="!item?.isReadOnly && item?.isRequired ? true : null"
               [appIcon]="'info'"
               class="ml-2 c-edit-icon">
          </div>
        </div>
      </div>
    </div>
    <p class="error-label" *ngIf="error">{{error}}</p>
  </ng-container>
  <!--<ng-container *ngIf="item?.type===infoItemType.ADDRESS">-->
  <!--<div class=" form-group c-form-group">-->
  <!--<label>{{'labelTexts.address'|translate}}</label>-->
  <!--<input type="tel"-->
  <!--class="form-control c-form-control"-->
  <!--placeholder="{{'receiver.placeholders.address'|translate}}"-->
  <!--[(ngModel)]="item?.value"-->
  <!--(ngModelChange)="onInputChange([item?.value])"-->
  <!--(keyup.enter)="onEnter($event)">-->
  <!--</div>-->
  <!--</ng-container>-->
  <div *ngIf="item?.type===infoItemType.ADDRESS" class="c-group custom-light-panel custom-address-panel">
    <div class=" form-group c-form-group">
      <label data-cy="address-field-city-label">{{'labelTexts.city'|translate}}</label>
      <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)"
        (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street])"
        [(ngModel)]="item.city" [disabled]="item?.isReadOnly ? true : null"
        data-cy="address-field-city"
        class="form-control c-form-control c-group-mb {{error ? 'input-with-error' : ''}}"
        placeholder="{{'receiver.placeholders.city'|translate}}" type="text" autocomplete="address-level2">
    </div>
    <div class=" form-group c-form-group">
      <label data-cy="address-field-street-label">{{'labelTexts.street'|translate}}</label>
      <input (keyup.enter)="onEnter($event)"
        (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street])"
        data-cy="address-field-street"
        [(ngModel)]="item.street" [disabled]="item?.isReadOnly ? true : null"
        class="form-control c-form-control c-group-mb {{errors.street ? 'input-with-error' : ''}}"
        placeholder="{{'receiver.placeholders.street'|translate}}" type="text" min="3" autocomplete="street-address">
      <p class="error-label" data-cy="address-field-street-error" style="margin-top: -15px; margin-bottom: 30px;" *ngIf="errors.street">{{errors.street}}</p>
    </div>
    <div class=" form-group c-form-group">
      <label data-cy="address-field-postcode-label">{{'labelTexts.postcode'|translate}}</label>
      <input (keyup.enter)="onEnter($event)" (blur)="validateInput($event)"
        (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street])"
        data-cy="address-field-postcode"
        [(ngModel)]="item.postcode" [disabled]="item?.isReadOnly ? true : null"
        class="form-control c-form-control c-group-mb {{errors.postcode ? 'input-with-error' : ''}}"
        placeholder="{{'receiver.placeholders.postcode'|translate}}" type="text" autocomplete="postal-code">
      <p class="error-label" data-cy="address-field-postcode-error" style="margin-top: -15px; margin-bottom: 30px;" *ngIf="errors.postcode">{{errors.postcode}}
      </p>
    </div>
    <div class=" form-group c-form-group">
      <label data-cy="address-field-country-label">{{'labelTexts.country'|translate}}</label>
      <nz-select autocomplete="off" name="country" nzShowSearch (keyup.enter)="onEnter($event)"
        (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street])"
        [(ngModel)]="item.country" [disabled]="item?.isReadOnly ? true : null"
        data-cy="address-field-country"
        class="form-control c-form-control c-select" nzPlaceHolder="{{'receiver.placeholders.country'|translate}}">
        <nz-option *ngFor="let option of countryOptions"
                   [nzValue]="option.value"
                   [nzLabel]="option.label"
                   [attr.data-cy]="'address-field-country-option-' + option.value">
        </nz-option>
      </nz-select>
    </div>

  </div>

  <ng-container *ngIf="item?.type===infoItemType.FULL_ADDRESS">
    <!--<div *ngIf="storeService?.setup?.features[pageType][featureType.ShowAddressTitle]" class="c-address-title">Adresse
    </div>-->

    <div style="display: flex; align-items: center;">
      <div class="form-group c-form-group address-group">
        <div style="display: flex;">
          <div class="form-group c-form-group" style="flex-grow: 1">
            <label data-cy="full-address-field-street-label">{{'labelTexts.street'|translate}}
              <span *ngIf="isRequired()" class="required-star">*</span></label>
            <input (keyup.enter)="onEnter($event)" (focusout)="prepareAddress($event)"
              (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street, item?.house, item?.addition, item?.extra1, item?.extra2, item?.extra3])"
              [(ngModel)]="item.street" [disabled]="item?.isReadOnly ? true : null"
              class="form-control c-form-control c-group-mb {{(highlightMandatoryFields || errors.street) ? 'input-with-error' : ''}}"
              placeholder="{{'receiver.placeholders.street'|translate}}" type="text" id="street-input"
              data-cy="full-address-field-street"
              autocomplete="street-address">
            <p class="error-label" data-cy="full-address-field-street-error" style="margin-top: -15px; margin-bottom: 30px;" *ngIf="errors.street">
              {{errors.street}}
            </p>
          </div>
          <div class="form-group c-form-group" style="flex-basis: 100px;min-width: 100px;margin-left: 10px;">
            <label data-cy="full-address-field-house-lable">{{'labelTexts.house'|translate}}</label>
            <input (keyup.enter)="onEnter($event)" (focusout)="prepareAddress($event)"
              (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street, item?.house, item?.addition, item?.extra1, item?.extra2, item?.extra3])"
              [(ngModel)]="item.house" [disabled]="item?.isReadOnly ? true : null" id="house-input"
              class="form-control c-form-control c-group-mb {{(highlightMandatoryFields || errors.house) ? 'input-with-error' : ''}}"
              placeholder="{{'receiver.placeholders.house'|translate}}"
              data-cy="full-address-field-house"
              autocomplete="house-number">
            <p class="error-label" data-cy="full-address-field-house-error" style="margin-top: -15px; margin-bottom: 30px;" *ngIf="errors.house">{{errors.house}}
            </p>
          </div>
        </div>
        <!--    Extra_3-->
        <div *ngIf="this.storeService.setup.features[pageType][featureType.ShowAddressExtra3]"
          class=" form-group c-form-group">
          <label data-cy="full-address-field-extra3-label">{{extra3LabelTranslationKey|translate}}</label>
          <input (keyup.enter)="onEnter($event)" (focusout)="prepareAddress($event)"
            (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street, item?.house, item?.addition, item?.extra1, item?.extra2, item?.extra3])"
            [(ngModel)]="item.extra3" [disabled]="item?.isReadOnly ? true : null"
            class="form-control c-form-control c-group-mb" placeholder="{{extra3PlaceholderTranslationKey|translate}}"
            data-cy="full-address-field-extra3"
            type="text" autocomplete="address-extra3">
        </div>
        <div class="c-inline-postcode-city">
          <div class=" form-group c-form-group c-form-group-postcode">
            <label data-cy="full-address-field-postcode-label">{{'labelTexts.postcode'|translate}}
              <span *ngIf="isRequired()" class="required-star">*</span></label>
            <input (keyup.enter)="onEnter($event)" (focusout)="prepareAddress($event)"
              (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street, item?.house, item?.addition, item?.extra1, item?.extra2, item?.extra3])"
              [(ngModel)]="item.postcode" [disabled]="item?.isReadOnly ? true : null" id="postalcode-input"
              class="form-control c-form-control c-group-mb {{(highlightMandatoryFields || errors.postcode) ? 'input-with-error' : ''}}"
              placeholder="{{'receiver.placeholders.postcode'|translate}}" type="text" min="5" max="5"
              data-cy="full-address-field-postcode"
              autocomplete="postal-code">
            <p class="error-label" data-cy="full-address-field-postcode-error" style="margin-top: -15px; margin-bottom: 30px;" *ngIf="errors.postcode">
              {{errors.postcode}}</p>
          </div>
          <div class="form-group c-form-group c-form-group-city">
            <label data-cy="full-address-field-city-label">{{'labelTexts.city'|translate}}
              <span *ngIf="isRequired()" class="required-star">*</span></label>
            <input (keyup.enter)="onEnter($event)" (focusout)="prepareAddress($event)"
              (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street, item?.house, item?.addition, item?.extra1, item?.extra2, item?.extra3])"
              [(ngModel)]="item.city" [disabled]="item?.isReadOnly ? true : null"
              class="form-control c-form-control c-group-mb {{(highlightMandatoryFields || errors.city) ? 'input-with-error' : ''}}"
              placeholder="{{'receiver.placeholders.city'|translate}}" type="text"
              data-cy="full-address-field-city-label"
              autocomplete="address-level2">
            <p class="error-label" data-cy="full-address-field-city-error" style="margin-top: -15px; margin-bottom: 30px;" *ngIf="errors.city">{{errors.city}}
            </p>
          </div>
        </div>
        <div class=" form-group c-form-group" style="margin-bottom: 35px !important;">
          <label data-cy="full-address-field-addition-label">{{'labelTexts.addition'|translate}}</label>
          <input (keyup.enter)="onEnter($event)" (focusout)="prepareAddress($event)"
            (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street, item?.house, item?.addition, item?.extra1, item?.extra2, item?.extra3])"
            [(ngModel)]="item.addition" [disabled]="item?.isReadOnly ? true : null"
            class="form-control c-form-control c-group-mb {{errors.addition ? 'input-with-error' : ''}}"
            placeholder="{{'receiver.placeholders.addition'|translate}}" type="text"
            data-cy="full-address-field-addition"
            autocomplete="address-level3">
        </div>
        <div class=" form-group c-form-group">
          <label data-cy="full-address-field-country-label">{{'labelTexts.country'|translate}}
            <span *ngIf="isRequired()" class="required-star">*</span></label>
          <nz-select autocomplete="off" name="country" nzShowSearch (keyup.enter)="onEnter($event)"
            (ngModelChange)="onInputChange([item?.country, item?.city, item?.postcode, item?.street])"
            [(ngModel)]="item.country" [disabled]="item?.isReadOnly ? true : null"
            class="form-control c-form-control c-select {{(highlightMandatoryFields && !item?.country) ? 'input-with-error' : ''}}"
            nzPlaceHolder="{{'receiver.placeholders.country'|translate}}"
            data-cy="full-address-field-country"
            (focusout)="prepareAddress($event)">
            <nz-option *ngFor="let option of countryOptions" [nzValue]="option.value"
                       [nzLabel]="option.label"
                       [attr.data-cy]="'full-address-field-country-option-' + option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>
      <div class="tooltip-icon">
        <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}" nzTooltipPlacement="left" nz-tooltip
        *ngIf="item?.isReadOnly ? true : null" [appIcon]="'info'" class="c-info-icon">
      </div>
      <div [nzTooltipTitle]="tooltipText(phone)" nzTooltipPlacement="left" nz-tooltip
        *ngIf="!item?.isReadOnly && item?.isRequired ? true : null" [appIcon]="'info'" class="ml-2 c-edit-icon">
      </div>
      </div>
    </div>

    <p class="error-label"
       data-cy="full-address-field-mandatory-fields-error"
      *ngIf="highlightMandatoryFields && (!item?.street || !item?.house || !item?.city || !item?.postcode || !item?.country)">
      {{'validation.notEmptyValidationMultiple'|translate}}</p>
  </ng-container>


  <div *ngIf="item?.type===infoItemType.ORG" class="c-group custom-light-panel">
    <div class=" form-group c-form-group">
      <label data-cy="company-field-label">{{'labelTexts.companyName'|translate}}</label>
      <div style="display: flex; align-items: center;">
        <input (keyup.enter)="onEnter($event)" (ngModelChange)="onInputChange([item?.companyName, item?.jobTitle])"
          [(ngModel)]="item.companyName" [disabled]="item?.isReadOnly ? true : null"
          class="form-control c-form-control c-group-mb" placeholder="{{'receiver.placeholders.companyName'|translate}}"
          data-cy="company-field"
          type="text">
        <div class="tooltip-icon">
          <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="company-field-readonly"
               *ngIf="item?.isReadOnly ? true : null"
               [appIcon]="'info'"
               class="c-info-icon">
          </div>

          <div [nzTooltipTitle]="tooltipText(companyName)"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="company-field-required"
               *ngIf="!item?.isReadOnly && item?.isRequired ? true : null"
               [appIcon]="'info'"
               class="ml-2 c-edit-icon">
          </div>
        </div>
      </div>
    </div>
    <div class=" form-group c-form-group">
      <label data-cy="job-title-field-label">{{'labelTexts.jobTitle'|translate}}</label>
      <div style="display: flex; align-items: center;">
        <input (keyup.enter)="onEnter($event)" (ngModelChange)="onInputChange([item?.companyName, item?.jobTitle])"
          [(ngModel)]="item.jobTitle" [disabled]="item?.isReadOnly ? true : null" class="form-control c-form-control"
          placeholder="{{'receiver.placeholders.jobTitle'|translate}}"
          data-cy="job-title-field"
          type="text">
        <div class="tooltip-icon">
          <div nzTooltipTitle="{{'labelTexts.readOnly' | translate}}"
               nzTooltipPlacement="left"
               nz-tooltip
               data-cy="job-title-field-readonly"
               *ngIf="item?.isReadOnly ? true : null"
               [appIcon]="'info'"
               class="c-info-icon">
          </div>
          <div [nzTooltipTitle]="tooltipText(jobTitle)" nzTooltipPlacement="left" nz-tooltip
            data-cy="job-title-field-required"
            *ngIf="!item?.isReadOnly && item?.isRequired ? true : null" [appIcon]="'info'" class="ml-2 c-edit-icon">
          </div>
        </div>
    </div>
  </div>
</div>
