import { Directive, inject } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IExternalValidationError } from '../interfaces/external-validation-error.interface';
import { IRemoteValidationError } from '../interfaces/remote-validation-error.interface';
import { processRemoteValidationErrors } from '../helpers/process-remote-validation-errors';


@Directive({
  // eslint-disable-next-line
  selector: '[cForm]',
})
export class FormDirective {

  private readonly _ngForm: NgForm = inject(NgForm);

  private _externalErrors$ = new BehaviorSubject<IExternalValidationError[]>([]);

  constructor() {
    this._listenSubmit();
  }

  public get errors$(): Observable<IExternalValidationError[]> {
    return this._externalErrors$.asObservable();
  }

  public get errors(): IExternalValidationError[] {
    return this._externalErrors$.value;
  }

  public setRemoteErrors(errors: IRemoteValidationError[]): void {
    this._externalErrors$.next(processRemoteValidationErrors(errors));
  }

  private _listenSubmit(): void {
    this._ngForm
      .ngSubmit
      .pipe(
        tap((event) => {
          event?.preventDefault();
        }),
      )
      .subscribe();
  }
}
