import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';


export enum InfoLoaderState {
  Idle = 'idle',
  Load = 'load',
}

@Injectable()
export class InfoFileLoaderService {

  protected _state$ = new BehaviorSubject<InfoLoaderState>(InfoLoaderState.Idle);
  protected _data$: Observable<string>;

  protected _cache = new Map<string, string>();
  protected _file$ = new ReplaySubject<string>(1);

  constructor(
    private _http: HttpClient,
  ) {
    this._initLoader();
  }

  public get data$(): Observable<string> {
    return this._data$;
  }

  public get state$(): Observable<InfoLoaderState> {
    return this._state$.asObservable();
  }


  public load(path: string): void {
    this._file$.next(path);
  }

  protected _setIdleState(): void {
    this._state$.next(InfoLoaderState.Idle);
  }

  protected _setLoadState(): void {
    this._state$.next(InfoLoaderState.Load);
  }

  protected _initLoader(): void {
    this._data$ = this._file$
      .pipe(
        tap(() => {
          this._setLoadState();
        }),
        switchMap((path: string) => {
          if (this._cache.has(path)) {
            return of(this._cache.get(path));
          }

          return this._http
            .get(path, { responseType: 'text' })
            .pipe(
              tap((data: string) => {
                this._cache.set(path, data);
              }),
            );
        }),
        tap(() => {
          this._setIdleState();
        }),
        shareReplay(1),
      );
  }

}
