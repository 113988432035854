import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RouteString } from '../../common/enums/route.string';
import { PageType } from '../../common/enums/page.type';

import { StoreService } from './store.service';


@Injectable()
export class AppNavService {

  currentPageType: PageType;
  currentPageIndex = 0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storeService: StoreService,
  ) {

  }

  goToHome() {
    this.router.navigate(['/']);
  }

  goToNotFound() {
    this.router.navigate([
      RouteString.NOT_FOUND,
    ]);
  }

  goToAuthenticate(queryParams) {
    this.router.navigate([
      'r',
      this.storeService.token,
      RouteString.SMS_AUTH,
    ], { queryParams: queryParams });
  }

  goToBirthday(queryParams) {
    this.router.navigate([
      'r',
      this.storeService.token,
      RouteString.DOB_AUTH,
    ], { queryParams: queryParams });
  }

  goToPostcode(queryParams) {
    this.router.navigate([
      'r',
      this.storeService.token,
      RouteString.POSTCODE_AUTH,
    ], { queryParams: queryParams });
  }

  goToEntryPage() {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
      ]);
    }
  }

  goToSettingsPage() {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.SETTINGS,
      ]);
    }
  }

  goToDeliveryNoticePage(queryParams?: { [key: string]: string | number }) {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.DELIVERY_NOTICE,
      ], { queryParams: queryParams });
    }
  }

  goToDonePage(queryParams?: { [key: string]: string | number }) {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.DONE,
      ], { queryParams: queryParams });
    }
  }

  goToConfirmationPage(queryParams?: { [key: string]: string | number }) {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.CONFIRMATION,
      ], { queryParams: queryParams });
    }
  }

  goToPincodePage(queryParams?: { [key: string]: string | number }) {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.PINCODE,
      ], { queryParams: queryParams });
    }
  }

  goToAnsweringPage(queryParams?: { [key: string]: string | number }) {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.ANSWERING,
      ], { queryParams: queryParams });
    }
  }

  goToSharingPage(queryParams?: { [key: string]: string | number }) {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.SHARING,
      ], { queryParams: queryParams });
    }
  }

  goToPreferencePage(queryParams?: { [key: string]: string | number }) {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.PREFERENCE,
      ], { queryParams: queryParams });
    }
  }

  goToDeletePage() {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.SETTINGS,
        RouteString.DELETE,
      ]);
    }
  }

  goToDownloadPage() {
    if (this.storeService.token) {
      this.router.navigate([
        'r',
        this.storeService.token,
        RouteString.SETTINGS,
        RouteString.DOWNLOAD,
      ]);
    }
  }

  goToInvalidTokenPage() {
    this.router.navigate([RouteString.ERROR, 'invalid']);
  }

  goToErrorPage() {
    this.router.navigate([RouteString.ERROR]);
  }

  canGoNext(sequence: PageType[], pageType: PageType): boolean {
    return !!this.getNextPageType(sequence, pageType);
  }

  getNextPageType(sequence: PageType[], pageType: PageType): PageType | undefined {
    const nextIndex = sequence.indexOf(pageType) + 1;

    return sequence[nextIndex];
  }

  goToNext(sequence: PageType[], pageType: PageType, queryParams?: { [key: string]: string | number }) {
    const nextPageType = this.getNextPageType(sequence, pageType);

    if (nextPageType) {
      // this.currentStepIndex = stepIndex;
      // this.currentPageType = this.setup.sequence[stepIndex];
      this.goToPageType(nextPageType, queryParams);
    }
  }

  goToPageType(pageType: PageType, queryParams?: { [key: string]: string | number }) {
    switch (pageType) {
      case PageType.Confirmation:
        this.goToConfirmationPage(queryParams);
        break;
      case PageType.Pincode:
        this.goToPincodePage(queryParams);
        break;
      case PageType.Authentication:
        this.goToAuthenticate(queryParams);
        break;
      case PageType.DOBAuthentication:
        this.goToBirthday(queryParams);
        break;
      case PageType.DeliveryNotice:
        this.goToDeliveryNoticePage(queryParams);
        break;
      case PageType.SimpleDone:
        this.goToDonePage(queryParams);
        break;
      case PageType.Answering:
        this.goToAnsweringPage(queryParams);
        break;
      case PageType.Sharing:
        this.goToSharingPage(queryParams);
        break;
      case PageType.Preference:
        this.goToPreferencePage(queryParams);
        break;
    }
  }

  setFirstPageOfSequence(pageType: PageType) {
    this.currentPageType = pageType;
  }

  goToFirstPageInSequence() {
    this.goToPageType(this.storeService.setup.sequence[0]);
  }

  getPageTypeByURL(path: string): PageType {
    switch (path as RouteString) {
      case RouteString.SHARING:
        return PageType.Sharing;
      case RouteString.ANSWERING:
      case RouteString.NEW_ANSWERING:
        return PageType.Answering;
      case RouteString.PINCODE:
        return PageType.Pincode;
      case RouteString.DONE:
        return PageType.SimpleDone;
      case RouteString.PREFERENCE:
        return PageType.Preference;
      case RouteString.DELIVERY_NOTICE:
        return PageType.DeliveryNotice;
      case RouteString.CONFIRMATION:
        return PageType.Confirmation;
      case RouteString.DOB_AUTH:
        return PageType.DOBAuthentication;
      case RouteString.SMS_AUTH:
        return PageType.Authentication;
      default:
        break;
    }

  }

}
