import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as countries from 'i18n-iso-countries';
import { HttpErrorResponse } from '@angular/common/http';

import { StoreService } from '../../../core/services/store.service';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { SetupInterface } from '../../../common/interfaces/setup.interface';
import { Side } from '../../../common/classes/side';
import { PageType } from '../../../common/enums/page.type';
import { FeatureType } from '../../../common/enums/feature.type';
import { InfoItemType } from '../../../common/enums/info-item.type';
import { FullAddress } from '../../../common/classes/full-address';
import { InfoItem } from '../../../common/classes/Info-item';
import { PersistanceService } from '../../../core/services/persistance.service';
import { arrayIncludesSubset } from '../../../common/functions/array-includes';
import { TelService } from '../../../core/services/tel.service';


@Component({
  selector: 'app-delivery-notice',
  templateUrl: './delivery-notice.component.html',
  styleUrls: ['./delivery-notice.component.scss'],
})
export class DeliveryNoticeComponent implements OnInit, OnDestroy {

  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.DeliveryNotice;
  foundAddress: FullAddress;
  address: FullAddress = {} as FullAddress;
  editingAddress: FullAddress;
  deliveryNote: string = '';
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  countryName: string | null;
  showLoader = false;
  isEditingAddressMode = false;
  textareaMaxLength = 30;
  lineTwo = '';
  validationError = '';

  constructor(
    public storeService: StoreService,
    private appNavService: AppNavService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private telService: TelService,
    private persistanceService: PersistanceService,
    @Inject(DOCUMENT)
    private document: Document,
  ) {
    this.foundAddress = (this.storeService.receiver as Side).homeInfoItems
      .find((item: InfoItem) => item.type === InfoItemType.FULL_ADDRESS) as FullAddress;
    if (this.foundAddress) {
      this.address = this.foundAddress;
    }
    if (this.address.extra2) {
      this.deliveryNote = this.address.extra2;
    }
    const allCountriesByCode = countries.getNames('de', { select: 'official' });
    let trimmedCountry = '';
    if (this.address.country) {
      trimmedCountry = this.address.country.trim();
      this.countryName = allCountriesByCode[trimmedCountry];
      this.editingAddress = JSON.parse(JSON.stringify(this.address));
    }
  }

  ngOnInit(): void {
    this.persistanceService.removeItem('isEmailChanged');
    this.persistanceService.removeItem('isReoConsentGiven');
    this.lineTwo = this.storeService.contractNumber || null;
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  onSendClick() {
    this.showLoader = true;

    const source = this.activatedRoute.snapshot.queryParams.source;
    const code = this.activatedRoute.snapshot.queryParams.code;


    this.saveReceiver()
      .pipe(catchError((err) => of(err)))
      .subscribe((data) => {
        if (!data || data instanceof HttpErrorResponse) {
          this.showLoader = false;
          if (data.error.validation_errors && data.error.validation_errors.length > 0) {
            this.validationError = `validation.${data.error.validation_errors[0].key}`;

            // Scroll top because we need to show for user validation message
            this.document?.defaultView?.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            this.validationError = data.error.message;
          }

          return;
        }
        this.apiService.getAll(this.storeService.token, { 'source': source, 'code': code }).subscribe();
        this.appNavService.goToNext(this.storeService.setup.sequence, this.pageType, this.activatedRoute.snapshot.queryParams);
      });

  }

  removeValidationError() {
    this.validationError = '';
  }

  saveReceiver() {

    const index = this.storeService.receiver.homeInfoItems.findIndex((item) => item.type === InfoItemType.FULL_ADDRESS);
    if (index !== -1) {
      this.storeService.receiver.homeInfoItems.splice(index, 1);
    }
    if (this.deliveryNote) {
      this.editingAddress.extra2 = this.deliveryNote;
    }
    this.storeService.receiver.homeInfoItems.push(this.editingAddress);

    const updatedReceiver = new Side({
      id: this.storeService.receiver.id,
      handler_id: this.storeService.receiver.handler_id,
      person: this.storeService.receiver.person,
      message: '',
      workInfoItems: this.storeService.receiver.workInfoItems,
      homeInfoItems: this.storeService.receiver.homeInfoItems,
      sharedInfoItems: this.storeService.folders.length >= 3 ? this.storeService.receiver.sharedInfoItems : [],
      tags: this.storeService.receiver.tags,
    });

    return this.apiService.saveAnswer(updatedReceiver, { 'code': this.activatedRoute.snapshot.queryParams.code });
  }

  onEditClick() {
    this.isEditingAddressMode = true;
  }

}
