<ng-container *ngFor="let folder of storeService.folders; let i = index">
  <div *ngIf="folder.isVisible" class="c-category" [attr.data-cy]="'category-' + folder.categoryType">
    <div (click)="folder.isActive=isOnlyOneFolder||!folder.isActive" class="c-category-header custom-light-panel">
      <div [appIcon]="folder.iconName" [size]="24" class="c-category-icon custom-category-icons"></div>
      <div class="c-category-title custom-category-title">
        {{(folder.categoryType === categoryType.WORK ? 'receiver.categories.work' :
        (folder.categoryType === categoryType.PERSONAL ? 'receiver.categories.home' : 'receiver.categories.shared'))
        | translate}}
        <!--<span [innerText]="getNumberOfItems(folder)"
          class="badge badge-pill badge-primary c-badge custom-category-badge"></span>-->
      </div>
      <div [appIcon]="'arrow_down'" [hidden]="folder.isActive||isOnlyOneFolder"
        class="c-category-arrow custom-category-arrows"></div>
      <div [appIcon]="'arrow_up'" [hidden]="!folder.isActive||isOnlyOneFolder"
        class="c-category-arrow custom-category-arrows"></div>
    </div>
    <div [@openClose]="folder.isActive ? 'open' : 'closed'" class="c-category-content">
      <div class="c-container custom-category-container">
        <ng-container *ngFor="let group of folder.groups">
          <div *ngIf="group.items && group.items.length" class="c-pack custom-item-pack">
            <ng-container *ngFor="let item of group.items; let isFirst = first; let isLast = last">
              <app-info-item (remove)="onItemRemove(group, item)"
                *ngIf="item.isSaved&&!storeService?.setup?.features[pageType][featureType.ShowInfoInputsByDefault]"
                [displayCategoryLabel]="false" [group]="group" [item]="item" [pageType]="pageType" [showMenu]="true">
              </app-info-item>
              <app-info-input
                (isFilledOut)="onInputFilledOut($event, folder, group, isItLast(isLast, group.items, item), item)"
                (isClearedOut)="onInputClearedOut($event, folder, group, isFirst, item)"
                *ngIf="!item.isSaved||storeService?.setup?.features[pageType][featureType.ShowInfoInputsByDefault]"
                [item]="item" [isFirst]="isFirst" [pageType]="pageType"
                [highlightMandatoryFields]="highlightMandatoryFields"></app-info-input>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
