import { Inject, Injectable } from '@angular/core';

import { LOCAL_STORAGE } from '../tokens/localstorage.token';
import { coerceBoolean } from '../../common/coercion/boolean';


@Injectable({
  providedIn: 'root',
})
export class PersistanceService {

  // This is a fallback for cases when window.localStorage does not exist.
  // We had a lot of reports in glitchtip about that
  // This can happen when user has set restrictions for the browser
  // like incognito mode or even manually set something specific
  private _internalStorage = new Map<string, string>();

  constructor(
    @Inject(LOCAL_STORAGE)
    private _storage,
  ) {}

  public setItem(key: string, value: unknown): void {
    this._setItem(key, value);
  }

  public getItem(key: string): string {
    return this._getItem(key);
  }

  public getItemAsBoolean(key: string): boolean {
    const value = this.getItem(key);

    return coerceBoolean(value);
  }

  public removeItem(key: string): void {
    this._removeItem(key);
  }

  protected _setItem(key: string, value: unknown) {
    const stringValue = String(value);

    if (this._storage) {
      this._storage.setItem(key, stringValue);
    } else {
      this._internalStorage.set(key, stringValue);
    }
  }

  protected _removeItem(key: string) {
    if (this._storage) {
      this._storage.removeItem(key);
    } else {
      this._internalStorage.delete(key);
    }
  }

  protected _getItem(key: string): string {
    return this._storage
      ? this._storage.getItem(key)
      : this._internalStorage.get(key);
  }

}
