<div class="c-app-content">
  <app-app-loader *ngIf="!person||showLoader"></app-app-loader>

  <app-steps-element *ngIf="setup.features[pageType][featureType.Steps]" [currentPageType]="pageType">
  </app-steps-element>

  <ng-container *ngIf="person">

    <div class="c-profile">
      <!--
      <div class="c-name">{{person.name.firstName}} {{person.name.lastName}}</div>
      -->
      <div class="c-name">{{ "" | translate}}</div>
      <app-messages *ngIf="setup.features[pageType][featureType.SharingMessage]" [avatarUrl]="person.imageUrl">
        <div *ngIf="!customMessage" class="custom-message-bubble">
          <ng-container *ngIf="setup.features[pageType][featureType.SharingMessageCasual]">
            {{'sender.defaultMessage' | translate}}
          </ng-container>
          <ng-container *ngIf="!setup.features[pageType][featureType.SharingMessageCasual]">
            {{'sender.defaultMessage-alt' | translate}}
          </ng-container>
        </div>
        <div *ngIf="customMessage" class="custom-message-bubble">
          {{customMessage}}
        </div>
      </app-messages>
    </div>
    <app-sender-contacts [pageType]="pageType"></app-sender-contacts>

    <div *ngIf="setup.features[pageType][featureType.SharingVcardLater]" class="g-info" style="text-align: center">
      {{'sender.vcardInfo' | translate}}</div>

    <div class="c-container">
      <button (click)="onReplyClick()" *ngIf="setup.features[pageType][featureType.SharingReplyButton]"
        class="btn btn-primary c-btn-reply g-btn-bold custom-btn-primary" type="button">
        {{'sender.buttons.reply'|translate}}
      </button>
      <button (click)="onDownloadSenderVCardClick()" *ngIf="setup.features[pageType][featureType.SharingVcard]"
        [disabled]="!senderVCardString" [translateParams]="{
              firstName:
              sender?.person.name.firstName + (sender?.person.name.lastName ? (' ' + sender?.person.name.lastName) : '')
              }" [translate]="'complete.buttons.vcard'"
        class="btn btn-primary c-btn-vcard g-btn-bold g-btn-primary-outline custom-btn-primary-outline"></button>
      <div class="c-loading-container">
        <i *ngIf="!senderVCardString && setup.features[pageType][featureType.SharingVcard]" [nzType]="'loading'"
          nz-icon></i>
      </div>
    </div>

  </ng-container>
</div>