import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { ConfirmationComponent } from './confirmation/confirmation.component';


@NgModule({
  declarations: [ConfirmationComponent],
  imports: [
    SharedModule,
  ],
})
export class ConfirmationModule {}
