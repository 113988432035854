import { BaseStylesInterface } from '../../common/interfaces/base-styles.interface';


export class BaseStyleFactory {

  public static addOpacity(color: string, opacity: number): string {
    if (opacity <= 0 || opacity >= 100) {
      throw new Error('Opacity must be between 0 and 100');
    }
    if (color.includes(';')) {
      return color.replace(';', opacity.toString());
    }

    return color + opacity;
  }

  public static adaptBasicStyles(data: any): BaseStylesInterface {
    return {
      default: data?.font_default,
      light: data?.font_light,
      primary: data?.primary_color,
      secondary: data?.secondary_color,
      background: data?.background_color,
    };
  }

  public static applyBaseStyles(base: BaseStylesInterface): void {
    if (base.default) {
      document.body.style.setProperty('--default', base.default);
    }
    if (base.light) {
      document.body.style.setProperty('--light', base.light);
    }
    if (base.primary) {
      document.body.style.setProperty('--primary', base.primary);
      document.body.style.setProperty('--primary-light', this.addOpacity(base.primary, 90));
    }
    if (base.secondary) {
      document.body.style.setProperty('--secondary', base.secondary);
    }
    if (base.background) {
      document.body.style.setProperty('--background', base.background);
    }
  }
}
