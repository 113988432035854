import { IFieldRemoteConfig } from '../../interfaces/field-remote-config.interface';
import { Section } from '../../enums/section.enum';

import { RemoteFieldConfigBuilder } from './remote-config-builder';


// eslint-disable-next-line
const SECTION_REGEX = /^(?<section>private|work)_/;
// eslint-disable-next-line
const MODIFICATOR_REGEX// eslint-disable-next-line
  = /(_(?<modifiers>ro|required|limited_1|limited_2|required_limited_1|limited_1_ro|limited_1_required|limited_2_required|limited_2_ro)*)$/;


export function stringToRemoteConfig(str: string): IFieldRemoteConfig {
  const configBuilder = new RemoteFieldConfigBuilder();

  configBuilder.setSection(extractSection(str));
  configBuilder.setType(extractFieldType(str));
  applyModificators(configBuilder, extractModificators(str));
  applyLimits(configBuilder, str);

  return configBuilder.build();
}

export function applyModificators(builder: RemoteFieldConfigBuilder, modificators: string): void {
  switch (modificators) {
    case 'ro': {
      builder.makeReadonly();
    } break;

    case 'required': {
      builder.makeRequired();
    } break;

    case 'limited_1': {
      // nothing to do here
    } break;

    case 'limited_2': {
      builder.setLimit(2);
    } break;

    case 'required_limited_1':
    case 'limited_1_required': {
      builder.makeRequired();
    } break;

    case 'limited_2_required': {
      builder.makeRequired();
      builder.setLimit(2);
    } break;

    case 'limited_1_ro': {
      builder.makeReadonly();
    } break;

    case 'limited_2_ro': {
      builder.makeReadonly();
      builder.setLimit(2);
    } break;
  }
}

export function extractSection(str: string): string | null {
  const section =  str.match(SECTION_REGEX)?.groups.section || null;

  switch (section) {
    case 'private': {
      return Section.Private;
    }

    case 'work': {
      return Section.Work;
    }

    case null: {
      return Section.General;
    }

    default: {
      throw new Error('can not recognize config section');
    }
  }
}

export function extractModificators(str: string): string | null {
  return str.match(MODIFICATOR_REGEX)?.groups.modifiers  || null;
}

export function extractFieldType(str: string): string {
  str = removeSection(str);
  str = removeModificators(str);

  if (str === '') {
    throw new Error('can not recognize field type');
  }

  // TODO fix
  switch (str) {
    case 'emails': {
      return 'email';
    }

    case 'addresses_full': {
      return 'full_address';
    }
  }

  return str;
}

export function applyLimits(builder: RemoteFieldConfigBuilder, str: string): void {
  str = removeSection(str);
  str = removeModificators(str);

  if (str === '') {
    throw new Error('can not recognize field type');
  }

  // TODO fix
  switch (str) {
    case 'emails': {
      builder.setLimit(3);
    } break;

    case 'addresses_full': {
      builder.setLimit(3);
    } break;
  }
}

export function removeSection(str: string): string {
  return str.replace(SECTION_REGEX, '');
}

export function removeModificators(str: string): string {
  return str.replace(MODIFICATOR_REGEX, '');
}
