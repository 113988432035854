import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { SettingsComponent } from './settings/settings.component';
import { AccountManagementComponent } from './account-management/account-management.component';
import { AccountDeleteComponent } from './account-delete/account-delete.component';
import { AccountDownloadDataComponent } from './account-download-data/account-download-data.component';
import { SettingsService } from './settings.service';


@NgModule({
  declarations: [
    SettingsComponent,
    AccountManagementComponent,
    AccountDeleteComponent,
    AccountDownloadDataComponent,
  ],
  imports: [
    SharedModule,
  ],
  providers: [SettingsService],
})
export class SettingsModule { }
